import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { debounce, pull, isEqual, capitalize, find } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import Spinner from '../../../common/Spinner';
import Modal from '../../../common/Modal';
import ReactSelect from '../../../common/ReactSelect';
import { components } from 'react-select'
import ClickOutsideHolder from '../../../../helpers/ClickOutsideHolder'
import store from '../../../../helpers/store'

import { searchActions } from '../../../../data/actions/search';
import { playlistsActions } from '../../../../data/actions/playlists';
import TileState from 'ol/TileState';

var imprintsSvg = require('!svg-inline-loader!../../../../../public/img/icons/imprints.svg');
var artistsSvg = require('!svg-inline-loader!../../../../../public/img/icons/artists.svg');
var productsSvg = require('!svg-inline-loader!../../../../../public/img/icons/products.svg');
var tracksSvg = require('!svg-inline-loader!../../../../../public/img/icons/tracks.svg');
var allSvg = require('!svg-inline-loader!../../../../../public/img/icons/all.svg');
var addSvg = require('!svg-inline-loader!../../../../../public/img/add-circle.svg');
var removeSvg = require('!svg-inline-loader!../../../../../public/img/remove-circle.svg');
var searchSvg = require('!svg-inline-loader!../../../../../public/img/search.svg');

const { Option } = components;
const CustomSelectOption = (props) => {
  return <Option {...props} className={`${props.isSelected ? 'selected' : ''}`}>
	<div className={`search-entity-logo ${props.data.label.toLowerCase()}`} dangerouslySetInnerHTML={{ __html: props.data.icon }}></div>
	{props.data.label}
  </Option>
};

const CustomSelectValue = (props) => (
  <div className="search-select-option">
	<div className={`search-entity-logo ${props.data.label.toLowerCase()}`} dangerouslySetInnerHTML={{ __html: props.data.icon }}></div>
	{props.data.label}
  </div>
);

const inputRef = React.createRef();
const dropdownRef =  React.createRef()

class Search extends React.Component {
  allSearchEntities = ["playlists"];
  allSearchEntitiesObjects = [
	{ type: "imprints", icon: imprintsSvg },
	{ type: "products", icon: productsSvg },    
	{ type: "artists", icon: artistsSvg },
	{ type: "tracks", icon: tracksSvg },
  ];
  resultsLength = 10;

  constructor(props) {
	super(props);
	const entities = [props.entities ? props.entities : this.allSearchEntities];
	const cookies = new Cookies();
	this.state = {
	  search: "",
	  entities: entities,
	  autocomplete: [],
	  showAutocomplete: false,
	  expanded: true,
	  modalId: "autocompleteSearch",
	  showModal: false,
	  selectedOption: { value: null, label: "All", icon: allSvg },
	  showSearchResult: false,
	  showDropdown: undefined,
	  resultsExpanded: {imprints: true, artists: true, products: true, tracks: true},
	  cookies
	};
	this.oFilter = this.addToFilter.bind(this);
	this.setSearch = this.setSearch.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
	this.setFilterSearch = debounce(this.setFilterSearch.bind(this), 1000);
	this.setSearchEntity = this.setSearchEntity.bind(this);
	this.parentExpand = this.parentExpand.bind(this);
	this.resultExpand = this.resultExpand.bind(this);
	this.clickOutsideEventTarget = this.clickOutsideEventTarget.bind(this);
	this.addAllResults = this.addAllResults.bind(this);
	this.closeAll = this.closeAll.bind(this);

  }

  parentExpand(modalExpanded) {
	if (this.props.onExpand !== undefined) this.props.onExpand(modalExpanded);
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
	const search = 'playlists';

	if (
	  nextProps[search] &&
	  nextProps[search].autocomplete_items &&
	  !isEqual(
		nextProps[search].autocomplete_items,
		this.props[search].autocomplete_items
	  )
	) {
		let autocomplete = nextProps[search].autocomplete_items;
	  this.setState(
		{
		  autocomplete
		},
		() => {
		  if (this.state.autocomplete.length) {
			this.setState({
			  showModal: true,
			  showSearchResult: true,
			});
			this.parentExpand(true);
		  } else {
			this.setState({
			  showSearchResult: Boolean(this.state.search),
			});
		  }
		}
	  );
	}
  }

  componentWillUnmount() {
	//document.addEventListener('click', this.clickOutsideEventTarget);
  }

  setSearch(e) {
	const value = e.target.value;
	this.setState({
	  autocomplete: [],
	  search: value,
	});
	this.setFilterSearch(value);
  }

  setFilterSearch(value) {
	return this.props.getAutocomplete(
	  value,
	  this.resultsLength,
	  this.state.entities
	);
  }
  
  addToFilter(e, item, include) {
	e.preventDefault();
	this.setState(
	  {
		showAutocomplete: false,
		// search: "",
	  },
	  () => {
		this.setState({
		  showModal: false,
		  showSearchResult: false,
		}, ()=>{
		});
		this.parentExpand(false);
		return this.props.searchResult(item, include);
	  }
	);
  }
  
  onFocus = (e) => {
	const { showSearchResult, autocomplete = [] } = this.state;
	if (autocomplete.length > 0 && !showSearchResult) {
	  this.setState({
		  showSearchResult: true,
		  showDropdown: true
	  });
	}
  }

  clickOutsideEventTarget(event) {
	let isInputTarget = false;
	let isDropdownTarget = false;

	if (inputRef.current && event.target !== inputRef.current && !inputRef.current.contains(event.target)) {
	  isInputTarget = false
	} else {
	  isInputTarget = true
	}

	if (dropdownRef.current) {
	  if (event.target !== dropdownRef.current && !dropdownRef.current.contains(event.target)) {
		isDropdownTarget = false
	  } else {
		isDropdownTarget = true
	  }
	}

	if (isInputTarget || isDropdownTarget) {
	  this.setState({
		showDropdown: true
	  }, ()=>{return})
	} else {
	  this.setState({
		showDropdown: undefined
	  }, ()=>{return })
	}
  };

  onSubmit(e, fromSearchButton = false) {
	if (e.key === "Enter" || fromSearchButton) {
	  e.preventDefault();
	  return this.props.getAutocomplete(
		this.state.search,
		this.resultsLength,
		this.state.entities
	  );
	}
  }

  setSearchEntity(entities) {
	this.setState({ entities }, () => {
	  this.props.getAutocomplete(
		this.state.search,
		this.resultsLength,
		this.state.entities
	  );
	});
  }

  entitySelectChange = (selectedOption) => {
	this.setState({ selectedOption: selectedOption });

	this.setSearchEntity(selectedOption.value);
  };

  renderSearchEntities = () => {
	if(this.props.entities)
		return null;
	  
	const { selectedOption } = this.state;

	let searchEntities = [
	  {
		label: "All",
		value: this.allSearchEntities,
		icon: allSvg,
	  },
	];
	for (let { type, icon } of this.allSearchEntitiesObjects) {
	  searchEntities.push({
		label: capitalize(type).replace(/s$/, ''),
		value: [type],
		icon,
	  });
	}


	const options = searchEntities.map((entity) => ({
	  label: entity.label,
	  value: entity.value,
	  icon: entity.icon,
	}));
	//const defaultValue = options.filter((option) => option.label === "All");
	const selectedOptionObject = find(options, (option)=>option.label == selectedOption.label);
	return (
	  <ReactSelect
		options={options}
		placeholder="Choose Filter"
		onChange={(selectedOption) => this.entitySelectChange(selectedOption)}
		value={selectedOptionObject}
		className="select-wrapper"
//        defaultValue={defaultValue}
		components={{
		  Option: CustomSelectOption,
		  SingleValue: CustomSelectValue,
		}}
	  />
	);

	// return searchEntities.map(entity=>{
	//     return <div className="radio" key={entity.label}>
	//         <input onClick={()=>this.setSearchEntity(entity.value)} type="radio" name="search-entity" className="" checked={isEqual(entity.value, this.state.entities)} id={`search-entity-${entity.label}`} />
	//         <label htmlFor={`search-entity-${entity.label}`} className="radio-inline">{entity.label.replace(/s$/, '')}</label>
	//     </div>
	// });
  };

  resultExpand(entity, expanded) {
	  this.setState({
		  ...this.state,
		  resultsExpanded: {
			  ...this.state.resultsExpanded,
			  [entity]: expanded
		  }
	  })
  }
  
  renderSearchResultItem(item, mode='single') {
	  return <a href="#" onClick={(e) => this.addToFilter(e, item)} className="logo-container">
		  <span className={`vendor-widget vendor-widget-${item.vendor} vendor-widget--playlist`}></span>
		  <span>{item.name}</span>
	  </a>

  }
  
 addAllResults(includeExclude) {
	  const { addAllResults: parentAddAllResults } = this.props;
	  const { autocomplete } = this.state;
	  this.setState({
		  showModal: false,
		  showSearchResult: false,
	  }, ()=>{
	  });
	  parentAddAllResults(autocomplete, includeExclude);
 }
 
 closeAll(){
	 this.setState({showSearchResult: false}, ()=>{
	 })
 }
 
  
 
  render() {
	// console.log("test", this.state.showDropdown)
	const search = 'playlists';

	const { searchItemMode = 'single', currentlySelectedItems = [], addAllResults, searchDropdownMode } = this.props;
	const showAllResults = addAllResults !== undefined;
	const showGroups = this.props.entities == undefined;

	return (
	  <div className={`page-filter ${this.props.mode} ${searchDropdownMode}`}>
		<div className="search-widget">
		  <form role="form" className="form-inline">
			<div className="form-group search">
			  <input
				ref={inputRef}
				className={`form-control main-search-input ${this.state.showSearchResult ? 'form-control-expanded' : ''}`}
				placeholder={
				  this.props.placeholder 
				}
				type="text"
				value={this.state.search}
				onChange={this.setSearch}
				onKeyPress={this.onSubmit}
				onFocus={(this.onFocus)}
				/>
			  {searchDropdownMode === "single" && <div className="search-icon-holder" onClick={(e)=>this.onSubmit(e, true)} >
				<span className="search-icon-image" dangerouslySetInnerHTML={{__html: searchSvg}}></span>
			  </div> }
			  <Spinner enabled={this.props[search].autocomplete_loading} />
			  {searchDropdownMode !== "single" && 
				<div className="form-group entity-search-list">
				  {this.renderSearchEntities()}
				</div>
			  }
			</div>
		  </form>

			{this.state.showSearchResult && (
			  <ClickOutsideHolder show={this.state.showSearchResult} extraRefs={[inputRef]}  onClickOutside={() => {this.closeAll()}}>
				<div className={`search-result-list ${!this.props[search].autocomplete_loading ? 'search-result-list-data' : ''}`} ref={dropdownRef}>
				  {this.state.autocomplete.length === 0 &&
				  !this.props[search].autocomplete_loading ? (
					<div className="no-results-holder">
					  <div className="no-results-title">No results found</div>
					  <div className="no-results-content">
						<span className="no-results-list-title">Suggestions:</span>
						<ul className="no-results-list">
						  <li>Make sure all words are spelled correctly.</li>
						  <li>Try a different search scope.</li>
						  <li>Try different search terms.</li>
						  <li>Try more general search terms.</li>
						  <li>Try fewer search terms.</li>
						</ul>
					  </div>
					</div>
				  ) : <React.Fragment>
				  <div>
					<ul className="autocomplete">
					  {this.state.autocomplete.map((item) => {
						item.isCurrentlySelected = Boolean(find(currentlySelectedItems, selectedItem=>(item.id == selectedItem.entity_id && item.entity == selectedItem.entity_type)));
						return (
						  <li key={item.id} className="autocomplete-item">
						  {this.renderSearchResultItem(item, searchItemMode)}
						  </li>
						);
					  })}
					</ul>
				</div>
			  </React.Fragment>
			  }
			</div>
		  </ClickOutsideHolder>
		)}
	  </div></div>);
  }
}

function mapStateToProps(state, ownProps) {
	return {
		playlists: state.playlists
	}
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		getAutocomplete: (value) => {
			return dispatch(playlistsActions.getPlaylistsAutocomplete(value))
		},
		addRecent: (item) => {
			return dispatch(searchActions.addRecentItem(item))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Search)
