import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { uniq, map, find } from 'lodash';
import alpha from 'color-alpha';

import ReactSelect from '../../components/common/ReactSelect';
import Spinner from '../../components/common/Spinner';
import AvatarUpload from '../../components/pages/user/AvatarUpload';
import { usersActions } from '../../data/actions/users';
import { userActions } from '../../data/actions/user';
// import { colors } from '../../widgets/charts/ChartDefaults';

class UserProfile extends Component {
    constructor(props) {
        super(props);

        const { selected = [] } = props,
            allSelected = (selected.length == 1 && selected[0]=='*');
        
        this.state = {
            currentLetter: null,
            currentSilo: ''
            // expanded: true
        }
        
        this.setLetter = this.setLetter.bind(this);
        this.setHomePage = this.setHomePage.bind(this);
        this.setSilo = this.setSilo.bind(this);
        this.renderSilo = this.renderSilo.bind(this);
        // this.toggleExpand = this.toggleExpand.bind(this)
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(usersActions.getImprints());
    }  
    
    setLetter(currentLetter) {
        this.setState({currentLetter})
    }
    
    setHomePage(page) {
        this.props.dispatch(userActions.setHomePage(page.value));
    }
    
    renderHomePageOptions(user) {
        const options = [
             {label: 'Home', value:'main'},
             {label: 'Overview', value:'overview'}
        ];
        
        const selectedOption = find(options, option=>option.value == user.landing)
        
        return <ReactSelect options={options} value={selectedOption} onChange={this.setHomePage} />
    }
    
    setSilo(currentSilo) {
        this.setState({currentSilo, currentLetter: null})
    }
    
    renderSilo(allImprints, selected, user, silo) {
        let letters = [],
            selectedLetters = [],
            itemIDs = [],
            { currentLetter } = this.state;
            
        let imprints = allImprints.filter(imprint=>imprint.silo_codes == silo);
        
        const totalItems = (imprints && imprints.length ) ? imprints.length : 0;
            
        if(imprints && imprints.length) {    
            itemIDs = imprints.map(imprint=>String(imprint.id));
        }
        
        if(selected && selected.length) {
            selected = selected.filter(selectedItem=>itemIDs.includes(selectedItem));
        }
        
        if(imprints && imprints.length) {
            for(let item of imprints) {
                letters.push(item.letter);
                item.isSelected = selected.includes(String(item.id));
                if(item.isSelected)
                    selectedLetters.push(item.letter);
            }
            if(!currentLetter) 
                currentLetter = letters[0];

            imprints = imprints.filter(item=>item.letter == currentLetter);
        }
        else
            imprints = [];
        
        
        
        
        const selectedItems = imprints.map(item=>{
            return <li>{item.name}</li>
        });
        
        letters = uniq(letters).sort();
        selectedLetters = uniq(selectedLetters);
        const showLetters = letters.length > 1;
        const siloData = find(user.users_silos, userSilo=>userSilo.silo == silo) || {};
        
        return <div>
            <h3>Current Silo: {silo}</h3>
            <p>Can Grant Access: {siloData.can_grant_access ? 'Yes' : 'No'}</p>
            <p className="bold">Available Imprints</p>            
            <div className={`alphabetical-list-wrapper`}>
              <span className="show-user-title">{totalItems} total</span>
              {showLetters && (
                <ul className="alphabetical-list">
                  {letters.map((letter) => {
                    let classNames = [];
                    if (currentLetter == letter) classNames.push("active");
                    if (selectedLetters.includes(letter))
                      classNames.push("selected");
                    return (
                      <li
                        key={letter}
                        onClick={() => this.setLetter(letter)}
                        className={classNames.join(" ")}
                      >
                        {letter}
                      </li>
                    );
                  })}
                </ul>
              )}
              <ul className="alphabetical-list-items">{selectedItems}</ul>
              {!imprints.length && (
                <p>No available imprints</p>
              )}
            </div>
        </div>
        
    }
    
    render() {
        const { user } = this.props.user,
            { imprintsLoading } = this.props.users;
        let { selected = [] } =  this.props, 
            { imprints = [] } = this.props.users,
            { currentSilo } = this.state;
        
        const silos = uniq(map(imprints, 'silo_codes'));
        const silo = currentSilo ? currentSilo : silos[0];
        
        return (
          <div className="inner-page users-management ">
            <h2>User Profile</h2>
            <div className="m-t">
              <div className="user-prefs-holder">
                <div className="user-prefs-holder-item">
                  <AvatarUpload user={user} />
                </div>
                <div className="user-prefs-holder-item">
                  <p className="bold">Landing Page:</p>
                  {this.renderHomePageOptions(user)}
                </div>
              </div>
            </div>
            {imprintsLoading && <Spinner enabled={true} />}
            {silos.length > 1 && <React.Fragment>
                <h3>Catalog Silos:</h3>
                <ul className="catalog-silos-list">    
                    {silos.map((silo, i)=>{
                      let classNames = []; 
                      if (currentSilo == '' && i == 0) classNames.push("active")
                      if (currentSilo == silo) classNames.push("active");
                      return (
                        <li className={classNames.join(" ")}><a onClick={()=>this.setSilo(silo)}>{silo}</a></li>
                      )
                    })
                    }
                </ul>
            </React.Fragment>}
            {silo && this.renderSilo(imprints, selected, user, silo)}
            <Helmet>
              <title>User Profile</title>
            </Helmet>
          </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
    } 
}

export default connect(mapStateToProps)(UserProfile)