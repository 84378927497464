import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { maxBy, isEqual, map, find } from 'lodash';
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import Modal from '../../components/common/Modal';
import ReactSelect from '../../components/common/ReactSelect';
import CSV from '../../helpers/CSVExport';
import { milestonesActions } from '../../data/actions/milestones';
import { milestonesFormatter } from '../../data/formatters/milestones';
import { milestonesSettings } from '../../components/pages/milestones/Settings'
import AddMilestoneForm from '../../components/forms/addMilestone'
import AddChannelToCampaignForm from '../../components/forms/addChannelToCampaign'
import ScrollButtons from '../../components/common/ScrollButtons';
import CSVImportModal from '../../components/common/CSVImportModal';
import CSVSampleDownload from '../../components/common/CSVSampleDownload';
import ConfirmClick from '../../components/common/ConfirmClick';
import FieldMapper from '../../components/widgets/entityFilter/FieldMapper';

import Box from '../../components/common/Box';
var barSvg = require('!svg-inline-loader!../../../public/img/chart-bar.svg');
var tableSvg = require('!svg-inline-loader!../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

const SelectTable = selectTableHOC(ReactTable);

class MilestonesList extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            type: null,
            showNewMilestoneModal: false,
            showEditMilestoneModal: false,
            showBulkResultsModal: false,
            showAddChannelModal: false,
            mode: 'table',
            milestoneType: undefined,
            currentMilestone: undefined,
            resizeObserver: undefined,
            tableContainer: undefined,
            hasHorizontalScroll: false,
            selectedRows: [],
            selectedEntity: null,
            selectedEntityID: null,
			bulkSearchResults: []
        }
        
        this.showModal = this.showModal.bind(this);
        this.addMilestone = this.addMilestone.bind(this);
        this.bulkAddMilestones = this.bulkAddMilestones.bind(this);
		this.saveBulkAddMilestones = this.saveBulkAddMilestones.bind(this);
        this.copyMilestone = this.copyMilestone.bind(this);
        this.editMilestone = this.editMilestone.bind(this);
        this.deleteMilestone = this.deleteMilestone.bind(this);
        this.bulkDeleteMilestones = this.bulkDeleteMilestones.bind(this);
        this.shareMilestone = this.shareMilestone.bind(this);
        this.shareAllMilestones = this.shareAllMilestones.bind(this);
        this.refresh = this.refresh.bind(this);
        this.setCurrentMilestone = this.setCurrentMilestone.bind(this);
        this.setChartMode = this.setChartMode.bind(this);
        this.setMilestoneType = this.setMilestoneType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.resizeObserverFunction = this.resizeObserverFunction.bind(this);
        this.allSelected = this.allSelected.bind(this);
        this.getMilestoneTypeBySlug = this.getMilestoneTypeBySlug.bind(this); 
    }
      
    resizeObserverFunction() {
        const cellHeaderTable = ReactDOM.findDOMNode(this.refs.CellHeaderTable);
        const tableContainer = cellHeaderTable.getElementsByClassName('rt-table')[0];
        
        this.setState({
            tableContainer
        });

        if (this.props.hasResizeAdjustment && window.ResizeObserver) {
            const resizeObserver = new ResizeObserver((entries) => {
                this.setState({
                    hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
                });
            });
            this.setState({
                resizeObserver
            });
        
            resizeObserver.observe(tableContainer);
        } else {
            console.log("Resize observer not supported!");
            this.setState({
                hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
            });
        }
    }

    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }
    
    setChartMode(mode){
        this.setState({mode});
    }
    
    setMilestoneType(milestoneType){
        this.setState({milestoneType});
    }
    
    bulkAddMilestones(milestones) {
        this.showModal('showNewMilestoneModal', false);
        const { dispatch, milestones: {types} } = this.props;
        for(let milestone of milestones) {
            milestone.milestone_type_id = this.state.type;
        }
        dispatch(milestonesActions.searchMilestones(milestones)).then(search=>{
			console.log(search);
			this.setState({
				bulkSearchResults: search,
				bulkSearchMilestones: milestones,
				showBulkResultsModal: true
			})
		})
        //.then(milestones=>dispatch(milestonesActions.bulkAddMilestones(milestones)))
        //.then(() => dispatch(milestonesActions.getMilestonesByType(this.state.type)));
    }
	
	saveBulkAddMilestones(results) {
		this.showModal('showBulkResultsModal', false);
		if(results && results.length) {
			let {bulkSearchMilestones: milestones} = this.state;
			const { dispatch } = this.props;
			const mappedMilestones = milestonesFormatter.formatSearchMappperResults(milestones, results);
			console.log(mappedMilestones);
			dispatch(milestones=>dispatch(milestonesActions.bulkAddMilestones(mappedMilestones)))
			.then(() => dispatch(milestonesActions.getMilestonesByType(this.state.type)));
		}
	}

    addMilestone(formData) {
        let { entity, id } = this.props;
        if(formData.entity) {
            entity = formData.entity.entity;
            id = formData.entity.id;
        }
        this.props.dispatch(milestonesActions.addMilestone(null, formData))
        .then(({milestone}) => this.props.dispatch(milestonesActions.linkAudienceMilestone(entity, id, milestone.id)))
        .then(() => this.props.dispatch(milestonesActions.getMilestonesByType(this.state.type)));
        this.showModal('showNewMilestoneModal', false);
    }
    
    copyMilestone(milestone) {
        const formData = {
            milestone_type_id: milestone.milestone_type_id,
            reference: `${milestone.reference}(copy)`,
            notes: milestone.notes,
            occurs_at: milestone.occurs_at,
            ends_at: milestone.ends_at,
        };
        return this.addMilestone(formData);
    }
    
    editMilestone(formData) {
        this.props.dispatch(milestonesActions.editMilestone(null, formData, this.state.currentMilestone.id))
        .then(() => this.props.dispatch(milestonesActions.getMilestonesByType(this.state.type)));
        this.showModal('showEditMilestoneModal', false);
    }
    
    shareMilestone(shared, id) {
        const formData = {'public': !Boolean(shared)};
        this.props.dispatch(milestonesActions.editMilestone(null, formData, id, false))
        .then(() => this.props.dispatch(milestonesActions.getMilestonesByType(this.state.type)));
    }
    
    shareAllMilestones(allShared) {
        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones || !milestones.tableItems)
            return;
        //.filter(item => !item.public)
        const ids = map(milestones.tableItems.filter(milestone=>milestone.owner), 'id');
        const sharePromises = map(ids, (id)=>this.props.dispatch(milestonesActions.editMilestone(null, {'public': allShared}, id, false)));
        Promise.all(sharePromises)
        .then(() => this.props.dispatch(milestonesActions.getMilestonesByType(this.state.type)));
        this.showModal('showEditMilestoneModal', false);
    }


    deleteMilestone(milestoneID) {
        this.props.dispatch(milestonesActions.deleteMilestone(milestoneID))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered)));
    }
    
    bulkDeleteMilestones() {
        const ids = this.state.selectedRows;
        this.props.dispatch(milestonesActions.bulkDeleteMilestones(ids))
        .then(()=>this.props.dispatch(milestonesActions.getMilestonesByType(this.state.type)))
        .then(()=>this.setState({selectedRows: []}));
    }
    
    
    refresh(){
        this.props.dispatch(milestonesActions.getMilestonesByType(this.state.type))
        this.showModal('showAddChannelModal', false);
    }
    
    exportToCsv(){
        const milestones = this.props.milestones.list[this.state.type],
            filename = CSV.CSVHeader('milestones', '', this.props.filter.global);

        return CSV.CSVExport(milestones, {filename}, 'entity_milestones');
    }

    setCurrentMilestone(currentMilestone, showModal) {
        this.setState({currentMilestone}, ()=>this.showModal(showModal, true));
    }
    
    getMilestoneTypeBySlug(slug) {
        const { types } = this.props.milestones;
        const milestone = find(types, type=>type.slug == slug);
        if(milestone)
            return milestone.value;
        else
            return 0;
    }
    
    componentDidMount() {
        const { match, dispatch } = this.props;
        const { type: slug } = match.params;
        dispatch(milestonesActions.getMilestoneTypes())
        .then(types=>{
            const type = this.getMilestoneTypeBySlug(slug);
            this.setState({type}, ()=>{
                dispatch(milestonesActions.getMilestonesByType(type))    
            });
        })
        
        //this.props.
    }

    componentWillReceiveProps(nextProps){
        const { dispatch } = this.props;
        if(this.state.type && nextProps.match) {
            const { type: slug } = nextProps.match.params;
            const type = this.getMilestoneTypeBySlug(slug);
            if(type!=this.state.type) {
                this.setState({type}, ()=>{
                    dispatch(milestonesActions.getMilestonesByType(type))    
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.milestones !== prevProps.milestones) {
            //this.resizeObserverFunction();
        }
    }

    componentWillUnmount() {
        const cellHeaderTable = ReactDOM.findDOMNode(this.refs.CellHeaderTable);
        const tableContainer = cellHeaderTable.getElementsByClassName('rt-table')[0];

        this.state.resizeObserver && this.state.resizeObserver.unobserve(tableContainer)
    }
    
    filterMilestoneTypes(items){
        const { milestoneType } = this.state;
        if(milestoneType && milestoneType.value) {
            items = items.filter(item=>item.milestone_type_id == milestoneType.value)
        }
        return items;
    }
    
    toggleSelection(id){
        let selectedRows = Object.assign([], this.state.selectedRows);
        if(selectedRows.includes(id))
            selectedRows = selectedRows.filter(item=>item!=id);
        else
            selectedRows.push(id);
        this.setState({selectedRows});
    }
    
    toggleAll(rows){
        let selectedRows = map(rows, row=>row.id);
        if(this.allSelected(rows))
            selectedRows = []; 
        this.setState({selectedRows});
    }
    
    allSelected(rows){
        if(!rows)
            return false;
        const currentlySelectedRows = this.state.selectedRows;
        return (currentlySelectedRows.length > 0 && currentlySelectedRows.length == rows.length)
    }
    
    
    
    renderShareAll() {
        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones || !milestones.tableItems) 
            return null;
        
        
        let allShared = true;
        for(let milestone of milestones.tableItems) {
            if(!milestone.public) {
                allShared = false;
                break;
            }        
        }
        
        let buttonLabel,
            buttonClass;
        
        if(allShared) {
            buttonLabel = 'Stop Sharing All Milestones';
            buttonClass = 'shared';
        }
        else {
            buttonLabel = 'Share All Milestones';
            buttonClass = '';
        }
            
        
        return <div className="small-title-holder small-title-holder--right">
            <h3 className="title share-label">{buttonLabel}</h3>
            <button title={buttonLabel} onClick={()=>this.shareAllMilestones(!allShared)} className={`btn share-all-milestones ${buttonClass}`}></button>
        </div>
    }

    renderToolbar(types){
        let toolbar = [];

        return toolbar;
    }    

    renderExportToCsv() {
        return (
            <a key="download" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    getEntities() {
        const { mode = 'single', stats } = this.props;
        
        if(mode == 'mixed') {
            const { multiMetadata } = stats;
            if(Array.isArray(multiMetadata)) {
                return multiMetadata.map(item=>({
                    label: item.name,
                    value: { id: item.id, entity: item.entity } 
                }));
            }
        }
        else {
            return [];
        }
            
        
    }
    
    renderImportMilestones(){
        let entityTypes = [{label: 'Imprints', value: 'imprint'}, {label:'Artists', value: 'artist'}, {label:'Projects', value: 'project'}, {label:'Products', value: 'product'}, {label:'Tracks', value: 'track'}];
        
        let milestoneCSVFields = [ /*{key: 'milestone_type_id', required: true, label: 'Milestone Type', options: milestoneTypes},*/                                     
            {key: 'reference', required: true, label: 'Title', placeholder: 'Milestone title (Remove me)'},
            {key: 'notes', required: false, label: 'Notes', placeholder: 'Example notes on the milestone'},
            {key: 'occurs_at', required: true, label: 'Occurs At', placeholder: '2022-01-01'},
            {key: 'ends_at', required: false, label: 'Ends At', placeholder: '2022-01-02(optional)'}];
        
        milestoneCSVFields.unshift({key: 'entity_type', required: true, label: 'Entity Type', options: entityTypes, placeholder: '(imprints|artists|products|tracks)'});
        milestoneCSVFields.unshift({key: 'entity_id', required: true, label: 'Entity ID', placeholder: 'ISRC00000001 (Remove me)'});
            

        return <React.Fragment>
            <CSVSampleDownload fields={milestoneCSVFields} linkClass="button-no-style download-scv" linkTitle="Download Template CSV" />
            <CSVImportModal fields={milestoneCSVFields} buttonClass="default-btn default-btn--small milestone-import-btn default-btn--light" modalTitle="Import Milestones" buttonTitle="Choose File" onSubmit={this.bulkAddMilestones} />
        </React.Fragment>
        
    }

    render (){
        const { selectedRows, bulkSearchResults, showBulkResultsModal } = this.state;
        
        const renderTable = (items) => {
            return items ? <div>
                <SelectTable
                    ref="CellHeaderTable"
                    data={items}
                    className='milestone-table simple-table'
                    columns={milestonesSettings.settingsForTable(this.setCurrentMilestone, this.deleteMilestone, this.shareMilestone, this.copyMilestone)}
                    showPagination={false}
                    pageSize={(items && items.length ) ? items.length : 10}
                    selectType="checkbox"
                    keyField="id"
                    toggleSelection={(key, shift, row)=>{this.toggleSelection(row.id)}}
                    toggleAll={()=>this.toggleAll(items)}
                    isSelected={(key)=>{return selectedRows.includes(key)}}
                    selectAll={this.allSelected(items)}
                    defaultSorted={[{id:'occurs_at', desc: true}]}
                    // SubComponent={row => <LinkedEntities row={row} /> }
                />
            </div> : null;
        }

        const { list, listLoading, types } = this.props.milestones;
        const { type } = this.state;
            
        return <Box title="Milestones" toolbar={this.renderToolbar()} spinnerEnabled={listLoading} exportToCsv={this.renderExportToCsv()} className="milestone-holder">
            {types && <div>
                <div className="milestone-action-btns milestone-action-btns-compact">
                        {this.renderImportMilestones()}
                        {selectedRows.length > 0 && <ConfirmClick confirmAction={this.bulkDeleteMilestones} confirmClass="default-btn default-btn--light default-btn--small delete-selected" confirmPrompt="Delete selected milestones?" confirmLabel="Delete Selected" extraClass="dialog-modal"></ConfirmClick>}
                    {this.renderShareAll()}
                </div>
                <FieldMapper results={bulkSearchResults} show={showBulkResultsModal} mapResults={this.saveBulkAddMilestones}/>
                <AddMilestoneForm show={this.state.showNewMilestoneModal} title="Add Milestone" onSubmit={this.addMilestone} onBulkAdd={this.bulkAddMilestones} onClose={(e)=>this.showModal('showNewMilestoneModal',false)} milestoneTypes={types} mode="new" />
                <AddMilestoneForm show={this.state.showEditMilestoneModal} title="Edit Milestone" onSubmit={this.editMilestone} onClose={(e)=>this.showModal('showEditMilestoneModal',false)} milestoneTypes={types} values={this.state.currentMilestone} mode="edit" />
                <div>
                    {this.state.hasHorizontalScroll && <ScrollButtons el={this.state.tableContainer} />}
                </div>
            </div>}
            {this.state.mode=='table' && renderTable(list[type])}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        milestones: state.milestones,
        filter: state.filter,
        stats: state.stats,
		search: state.search
    } 
}

export default connect(mapStateToProps)(MilestonesList);