import { map, uniq, capitalize, find, concat, kebabCase } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';

export const milestonesFormatter = {
    formatMilestones,
    formatMilestoneTypes,
    linkEntitiesToMilestones,
    formatNewMilestone,
    formatLinkMilestone,
    formatLinkAudienceMilestone,
    formatBulkMilestones,
    formatMilestonesByType,
    formatMilestonesByTypeMetadata,
    formatSearch,
    formatSearchResults,
	formatSearchMappperResults,
    setWithinRange
};

function extractMilestones(data, linked, types) {
    let milestones = [];
    
    if(linked) {
        for(let item of data.milestones) {
            const milestoneType = find(types, type=>item.milestone_type_id == type.id);
            if(milestoneType)
                item.milestone_type = milestoneType.name; 
            milestones.push(item);
        }        
    }
    else {
        for(let category of data.milestones) {
            for(let item of category.data) {
                item.milestone_type = category.name;
                milestones.push(item);
            }
        }
    }
    
    return milestones;
}

function addMetadata(row, entityType, metaEntity) {
    if(!metaEntity) {
        metaEntity = {
            image: null,
            name: 'N/A',
            code: null,
            primary_artist: {
                name: 'Unlisted Artist'
            }
        }
    }

    switch(entityType) {
        case 'track':    
        case 'tracks':
            row.logo = metaEntity.image;
            row.linked = `${metaEntity.name} (${metaEntity.code}) by ${metaEntity.primary_artist.name}`;
            row.artist = metaEntity.primary_artist ? metaEntity.primary_artist.name : 'N/A';
        break;
        case 'product':        
        case 'products':
            row.logo = metaEntity.image;
            row.linked = `${metaEntity.name} (${metaEntity.code}) by ${metaEntity.primary_artist.name}`;
            row.artist = metaEntity.primary_artist ? metaEntity.primary_artist.name : 'N/A';
        break;
        case 'project':        
        case 'projects':
            row.logo = metaEntity.image;
            row.linked = `${metaEntity.name} (${metaEntity.code}) by ${metaEntity.primary_artist}`;
            row.artist = metaEntity.primary_artist ? metaEntity.primary_artist: 'N/A';
        break;        
        case 'artist':        
        case 'artists':
            row.logo = metaEntity.image;
            row.linked = metaEntity.name;
        break;
        case 'imprint':        
        case 'imprints':
            row.logo = metaEntity.image;
            row.linked = metaEntity.name;
        break;
        
    }
    return row;

}

function fixDate(date) {
    if(date && typeof date == 'string')
        date = moment(date).format('x');
    return date;
}

function setWithinRange(milestones, globalFilter) {
    let dateStartM = moment(globalFilter.dateStart),
    dateStart = dateStartM.format('x'),
    dateEndM = moment(globalFilter.dateEnd),
    dateEnd = dateEndM.format('x');
    
    for(let milestone of milestones) {
        const occurs_at = fixDate(milestone.occurs_at);
        const ends_at = fixDate(milestone.ends_at);
        let withinRange = false;
        
        if(ends_at) { // range 
            if(!(ends_at < dateStart || occurs_at > dateEnd))
                withinRange = true;
        }
        else { // single event
            if(!(occurs_at > dateEnd || occurs_at < dateStart))
                withinRange = true;
        }
        milestone.withinRange = withinRange;
    }
    return milestones;
}


function formatMilestones(data, metadata, globalFilter, linked, types, dateRangeOnly, currentUser){
    let dateStartM = moment(globalFilter.dateStart),
        dateStart = dateStartM.format('x'),
        dateEndM = moment(globalFilter.dateEnd),
        dateEnd = dateEndM.format('x'),
        table = [],
        dates = [],
        datasets = [];
    
     let milestones = extractMilestones(data, linked, types);
     milestones = setWithinRange(milestones, globalFilter);
     for(let item of milestones) {   
        // preserve timestaps for comparison
        const { occurs_at, ends_at } = item;
        item.occurs_at = moment(item.occurs_at).format('YYYY-MM-DD');
        item.ends_at = item.ends_at ? moment(item.ends_at).format('YYYY-MM-DD') : '';
        table.push(item);
        
        if(item.withinRange) {
            if(ends_at) { // range 
                dates.push({range:[Math.max(occurs_at, dateStart), Math.min(ends_at, dateEnd)], item});
            }
            else { // single event
                dates.push({range:[occurs_at, occurs_at], item});
            }
        }
    }
    
    for(let itemIndex in dates) {
        let {item, range} = dates[itemIndex];
        let milestone = {
            milestone_id: item.id,
            milestone_type_id: item.milestone_type_id,
            backgroundColor: colors[item.milestone_type_id],
            borderColor: colors[item.milestone_type_id],
            label: `${item.reference}`,
            pointBorderColor: colors[item.milestone_type_id],
            pointBackgroundColor: colors[item.milestone_type_id],
            pointHoverRadius: 0,
            data: [{
                x: Math.floor(moment.duration(moment(range[0]).diff(dateStartM)).as('days')), //moment().format('DD MMM'),
                y: itemIndex,
                
            },{
                x: Math.floor(moment.duration(moment(range[1]).diff(dateStartM)).as('days')), //moment(item.range[1]).format('DD MMM'),
                y: itemIndex
            }]
        };
        
        if(item.ends_at && item.occurs_at != item.ends_at) {
            milestone.borderWidth = 0;
            milestone.pointRadius = 4;
            // milestone.pointBorderWidth = 0;
            milestone.pointHoverRadius = 4;
            milestone.fill = false;
        }
        else {
            milestone.borderWidth = 0;
            milestone.pointRadius = 5;
            milestone.fill = true;
        }
        
        datasets.push(milestone);
    }
    
    const { entities } = data;
    for(let row of table) {
        const linked = row.milestone_entity_links[0];
        if(!linked)
            continue;
        
        row.public = linked.public !== undefined ? linked.public : false;
        row.owner = linked.user_id == currentUser.id;
        const {entity_id, entity_type} = linked;
        const entity_type_search = `${entity_type}s`;
        let metaEntity = find(metadata, (meta => (meta.entity == entity_type_search && meta.id == entity_id)));
        row = addMetadata(row, entity_type_search, metaEntity);
    }
    
    return {table, datasets};
}

function linkEntitiesToMilestones({table, datasets}, entity, ids) {
    for(let milestone of table) {
        for(let link of milestone.milestone_entity_links) {
            if(ids.includes(link.entity_id)) {
                let chartItem = find(datasets, {milestone_id: milestone.id});
//                if(chartItem)
//                    chartItem.label += ` +(${link.entity_details.label})`
            }
        }
    }
    return {table, datasets};
}


function formatMilestoneTypes(data){
    return data.map(item=>({
        label: item.name,
        value: item.id,
        slug: kebabCase(item.name)
    }))
}

function formatNewMilestone(campaign, params) {
    params.object_type = 'ei_promotion';
    params.source = 'ei';
    
    if(campaign!==null)
        params.object_id = campaign;
    
    if(!params.ends_at)
        delete params.ends_at;

    if(!params.notes)
        params.notes = '';
    
    return {milestone: params};
}

function formatLinkMilestone(params, milestone, user, mode) {
    let entity_type,
        entity_source;
    if(mode == 'sales') {
        entity_type = params.channel_type;
        entity_source = '';
    }
    else {
        entity_type = 'social';
        entity_source = params.channel_type;
    }
        
    return {milestone_link: 
        {
            milestone_id: milestone.id,
            entity_id: params.value,
            entity_type,
            entity_source,
            entity_details: JSON.stringify({label: params.label}), 
            //data_silo_codes: user.data_silo_codes,
            data_silo_codes: [user.primary_silo_code],
            user_id: user.id,
            public: false
        }
    }
}

function formatLinkAudienceMilestone(entity, entityID, milestoneID, user) {
    return {
        milestone_link: {
            milestone_id: milestoneID,
            entity_id: entityID,
            entity_type: entity.replace(/.$/, ''),
            entity_source: null,
            entity_details: {}, 
            //data_silo_codes: user.data_silo_codes.split(','),
            data_silo_codes: [user.primary_silo_code],
            user_id: user.id,
            public: false
        }
    }
}

function formatBulkMilestones(milestones, user) {
    for(let milestone of milestones) {
        milestone.occurs_at = moment(milestone.occurs_at).format('YYYY-MM-DD');
        milestone.ends_at = milestone.ends_at ? moment(milestone.ends_at).format('YYYY-MM-DD') : '';
    }
    
    const userData = {id: user.id, silo_codes: [user.data_silo_codes]};
    return { milestones, user: userData };
}

function formatMilestonesByType(milestones, currentUser, ownOrShared) {
    for(let milestone of milestones) {
        let linkedEntity = (Array.isArray(milestone.milestone_entity_links) && milestone.milestone_entity_links.length > 0) ? milestone.milestone_entity_links[0] : {};
        milestone.entity_id = linkedEntity.entity_id;
        milestone.entity_type = linkedEntity.entity_type+'s';
        const { occurs_at, ends_at } = milestone;
        milestone.occurs_at = moment(milestone.occurs_at).format('YYYY-MM-DD');
        milestone.ends_at = milestone.ends_at ? moment(milestone.ends_at).format('YYYY-MM-DD') : '';
        milestone.owner = linkedEntity.user_id == currentUser.id;
        milestone.ownOrShared = (milestone.owner || linkedEntity.public);
        milestone.public = linkedEntity.public !== undefined ? linkedEntity.public : false;
    }
    if(ownOrShared) {
        milestones = milestones.filter(milestone => milestone.ownOrShared);
    }
    return milestones;
}
function formatMilestonesByTypeMetadata(milestones, metadata) {
    for(let milestone of milestones) {
        let metaEntity = find(metadata, (meta => (meta.entity == milestone.entity_type && meta.id == milestone.entity_id)));
        milestone = addMetadata(milestone, milestone.entity_type, metaEntity);
    }
    return milestones;
}

function formatSearch(milestones) {
    let entities = uniq(map(milestones, milestone=>milestone.entity_type+'s'));
    let search = map(milestones, 'entity_id').join('\n');
    return {search, entities};
}

function formatSearchResults(milestones, metadata) {
    let linkedMilestones = [];
    for(let milestone of milestones) {
        const metaEntity = find(metadata, entity=>entity.query == milestone.entity_id);
        if(metaEntity && metaEntity.exactMatch == true) {
            linkedMilestones.push({
                ...milestone,
                entity_id: metaEntity.result[0].id,
                entity_type: milestone.entity_type.replace(/s$/, '')
                
            })
        }
    }
    return linkedMilestones;
}

function formatSearchMappperResults(milestones, metadata) {
	let linkedMilestones = [];
	console.log(milestones, metadata);
	for(let milestone of milestones) {
		const metaEntity = find(metadata, entity=>entity.query == milestone.entity_id);
		if(metaEntity) {
			linkedMilestones.push({
				...milestone,
				entity_id: metaEntity.id,
				entity_type: milestone.entity_type.replace(/s$/, '')
				
			})
		}
	}
	return linkedMilestones;
}