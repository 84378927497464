import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {Helmet} from 'react-helmet';
import parser from 'url-parse';

import LoginForm from '../components/forms/login'
import { userActions } from '../data/actions/user'
import Spinner from '../components/common/Spinner'

class SharedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    checkLoggedIn(props) {


    }
    
    componentDidMount() {
        const { match, dispatch, user, history } = this.props;
        const slug = match.params.slug;
        const qs = parser(this.props.location.search, '', true);
        dispatch(userActions.shareLogin(slug, qs))
        .then(sharedPage=>{
            let path, 
                { module, entity_id, entity, params } = sharedPage;
            switch(module) {
                case 'home':
                    path = 'overview/';
                    break;
                case 'audience_analysis':
                    path = `audience/${entity}/${entity_id}`;
                    break;
                case 'track_comparison':
                    path = `comparison/${entity}/${entity_id}/${params}`;
                    break;
                case 'filter':
                    path = `filter/${params}`;
                    break;
                case 'reporting':
                    path = `reporting/${entity}/${entity_id}/${params}`;
                    break;
                    
                default:
                    path = `${entity}/${entity_id}`;
            }
            history.push(`/share/${path}`);
        })
        .catch(error=>{
            console.log(error);
            this.setState({error});
        })
    }
    
    componentWillReceiveProps(props) {
        
    }

    render() {
        const { user } = this.props;
        return (
            <div className="middle-box text-center">
                {this.state.error && <p>{this.state.error}</p>}
                <Helmet>
                    <title>Shared</title>
                </Helmet>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}


export default connect(mapStateToProps)(withRouter(SharedPage));