import React from 'react';
import { connect } from 'react-redux'; 
import { isEqual, cloneDeep, uniq, map, merge } from 'lodash';
import { LineChart, BarChart, StackedBarChart } from '../../widgets/charts/types'
import MilestoneLegendItem from '../audience/MilestoneLegendItem';
import { addComparisonMilestoneDataset } from '../../../helpers/MilestoneFormatter';
import { filterActions } from '../../../data/actions/filter';

class SharedChartWithAnnotations extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        period: this.props.period,
        dateGroup: this.props.dateGroup,
        xCoord: 0,
        direction: 'left',
    };

    this.ref = React.createRef();

    this.toggleMilestone = this.toggleMilestone.bind(this);
    this.showMilestone = this.showMilestone.bind(this);
    this.getDateGroup = this.getDateGroup.bind(this);
  }

  componentDidMount() {
    // this.props.getStats();
}

  componentWillReceiveProps(nextProps){
      if(nextProps.filter && nextProps.filter.global){
          if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
              this.props.getStats(false);
          }                
      }
  }
    
  toggleMilestone(currentMilestone, period, forceOff = false) {
    let previousMilestone = Object.assign([], this.state.currentMilestone);

    if (isEqual(currentMilestone, this.state.previousMilestone) || forceOff) {
        previousMilestone = null; //Object.assign([], this.state.previousMilestone);
        currentMilestone = null;
    }
    else if (isEqual(currentMilestone, this.state.currentMilestone)) {
      currentMilestone = null;
      previousMilestone = null;
    } 
    else if (isEqual(previousMilestone, this.state.currentMilestone)) {
      previousMilestone = null;
    }

    const length = this.props.data.labels.length;
    var i = 0;

    this.props.data.labels.forEach(function(el, index) {
      if (el == period) {
        i = index
      }
    })

    const chartWidth = this.ref && this.ref.current.clientWidth;
    const innerChartWidth = parseInt(chartWidth - 110);

    var coord = 0;
    var dir = 'left';

    if (i === 0) {
      coord = 0;
    } else if (i === (length - 1)) {
      coord = 0;
      return dir = 'right';
    } else {
      coord = parseInt(innerChartWidth/(length - 1)*i)
    }

    this.setState({
      currentMilestone, 
      previousMilestone, 
      period,
      xCoord: coord,
      direction: dir
    });

  }

  showMilestone(currentMilestone, period) {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      let previousMilestone = Object.assign([], this.state.currentMilestone);
  
      if (isEqual(previousMilestone, this.state.currentMilestone) && !isEqual(period, this.state.period)) {
        previousMilestone = null;
        currentMilestone
      }

      console.log("period", period)
  
      const length = this.props.data.labels.length;
      var i = 0;
  
      this.props.data.labels.forEach(function(el, index) {
          if (el == period) {
            i = index
          }
      })
  
      const chartWidth = this.ref && this.ref.current.clientWidth;
      const innerChartWidth = parseInt(chartWidth - 110);

      var coord = 0;
      var dir = 'left';

      if (i === 0) {
        coord = 0;
      } else if (i === (length - 1)) {
        coord = 0;
        dir = 'right'
      } else {
        coord = parseInt(innerChartWidth/(length - 1)*i)
      }
      
      this.setState({
        currentMilestone, 
        previousMilestone, 
        period,
        xCoord: coord,
        direction: dir
      });

    }
  }

  getDateGroup() {
    let { dateGroup } = this.state;
    switch(dateGroup) {
      case 'chwk':
        return 'Chart Week'
      case 'clwk':
        return 'Calendar Week'
      case 'mth':
        return 'Month';
      default: 
        return 'Day';
    }
  }

  render() {
    let { milestones, labels, datasets, annotations, data, shadowChartProps, chartType, metadata, options } = this.props,
    legendPosition = 'bottom';

    if(!data)
      return null;
    
    let ChartComponent;
    switch(chartType){
        case 'line':
            ChartComponent = LineChart;
            break;
        case 'bar': 
            ChartComponent = BarChart;
            break;
        case 'stacked':
            ChartComponent = StackedBarChart;
            break;
    }

    
    var dataCopy = [];
    var metadataCopy = [];

    if (data && data !== undefined && data.labels && data.datasets) {
      dataCopy = cloneDeep(data)
      metadataCopy = cloneDeep(metadata)
      labels = dataCopy.labels;
      datasets = dataCopy.datasets;
      annotations = addComparisonMilestoneDataset(labels, metadataCopy, milestones, this.toggleMilestone, this.showMilestone);
    }
    
    const annotationOptions = {
      plugins: {
        legend: {
          display: true, 
          position: legendPosition,
        },
        annotation: {
          clip: false,
          annotations
        }
      },
    };
    
    const chartOptions = merge({}, annotationOptions, options);
      
    return <React.Fragment><div className="chart-with-annotation-container" ref={this.ref}>
      <MilestoneLegendItem milestone={this.state.currentMilestone} period={this.state.period} clickOutside={()=>this.toggleMilestone(this.state.currentMilestone)} show={()=>this.showMilestone(this.state.currentMilestone)} xCoordinate={this.state.xCoord} direction={this.state.direction} />
      <ChartComponent data={data} options={chartOptions} {...shadowChartProps} />
      </div>
    </React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
      filter: state.filter
  } 
}

function mapDispatchToProps(dispatch, ownProps){
  return {
      getStats: ()=>dispatch(filterActions.getDataLogs())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedChartWithAnnotations)