import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'

class TicketForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: ''
        };
    }

    render() {
        const required = value => (value ? undefined : 'Required')
        return (
            <Form onSubmit={this.props.onSubmit} initialValues={{}}
                validate={values => {
                    const errors = {}
                    if (!values.title) {
                      errors.title = 'Required'
                    }
                    if (!values.description) {
                      errors.description = 'Required'
                    }
                    return errors
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form className="m-t chat-modal-form" onSubmit={event =>{
                        setTimeout(()=>{
                            form.reset();
                        }, 2000);
                        return handleSubmit(event);  
                    }}>
                        <div className="form-group">
                            <Field name="title">
                                {({ input, meta }) => (
                                  <div>
                                    <input {...input} type="text" placeholder="Short title of the issue" className="form-control form-field" />
                                    {meta.error && meta.touched && <span className="error-field">{meta.error}</span>}
                                  </div>
                                )}
                            </Field>
                        </div>
                        <div className="form-group">
                            <Field name="description">
                                {({ input, meta }) => (
                                  <div>
                                    <textarea {...input} type="text" placeholder="Description of the issue" className="form-control form-field" />
                                    {meta.error && meta.touched && <span className="error-field">{meta.error}</span>}
                                  </div>
                                )}
                            </Field>
                        </div>

                        <button type="submit" disabled={submitting || pristine} className="chat-submit">Submit</button>
                    </form>
                )}
            />
        );
    }
}

export default TicketForm