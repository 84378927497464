import React from 'react';
import { find } from 'lodash';
import ReactSelect from '../../../common/ReactSelect';
import minimumStreamOptions from './MinimumStreams';
import { convertToThousands } from '../../../../helpers/NumberFormatter';

class HeartbeatsReport extends React.Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
        }
        this.setValue = this.setValue.bind(this);
    }
    
    componentDidMount() {
        const { settings } = this.props;
        this.setState({
            ...this.state,
            ...settings
        })
    }
    
    setValue(e, key) {
        const { value } = e.target;
        const { onValueChanged } = this.props;
        this.setState({
            ...this.state,
            [key]: value
        }, ()=>{
            onValueChanged(this.state)
        })
    }
    
    setSelectValue({value}, key) {
        let e = {target: {value}};
        this.setValue(e, key);
    }
    
    renderMinimumStreamOptions(value) {
        let options = minimumStreamOptions.map(option=>({
            label: convertToThousands(option),
            value: option
        }));
        value = find(options, option=>option.value == value);
        return <ReactSelect options={options} className="form-control" value={value} onChange={(option)=>this.setSelectValue(option, 'minimum_streams')} />
    }
    
    render (){
        const { threshold, minimum_streams } = this.state;
        const { metrics = 'increase' } = this.props;
        return <div>
			<label for="">
				% {metrics}
            	<input type="number" className="form-control" value={threshold} onChange={(e)=>this.setValue(e, 'threshold')} /> 
			</label>
			<br />
            {/*<input type="number" value={minimum_streams} onChange={(e)=>this.setValue(e, 'minimum_streams')} />*/}
			<label for="">
				Week units
            	{this.renderMinimumStreamOptions(minimum_streams)}
			</label>
        </div>
    }
}
export default HeartbeatsReport;