import React from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { find, maxBy, isEqual } from 'lodash';

import config from '../../../config/config';
import CSV from '../../../helpers/CSVExport';
import Box from '../Box';
import TrendDirection from '../../common/TrendDirection';
import ModeSelect from '../ModeSelect';
import { playlistsActions } from '../../../data/actions/playlists';
import { statsActions } from '../../../data/actions/stats';
import PieChart from '../../widgets/charts/types/PieChart';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';
import FillLineChart from '../../widgets/charts/types/FillLineChart';
import { sourcesSettings } from './Sources/Settings';
import VendorLogo from '../VendorLogo';
import Spinner from "../../common/Spinner";
import { dspLogos } from "../../common/Stats/utils";
import Tooltip from '../../common/Tooltip';

var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var playlistSvg = require('!svg-inline-loader!../../../../public/img/playlist.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');

class SourcesStats extends React.Component {
    
    constructor(props) {
        super(props);
        let mode = props.defaultMode;
        //if(!['pie', 'table'].includes(mode))
        if(!mode)
            mode = 'table';
        this.state = {
          mode: mode,
          logoDataFiltered: [],
          defaultSorted: [{ id: "curr_units", desc: false }],
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.getDataKey = this.getDataKey.bind(this);
    }
    
    getDataKey() {
        const { discovery = false } = this.props;
        const { mode } = this.state;
        let dataKey = '';
        switch(mode) {
            case 'line':
            case 'bar':
                dataKey = 'Timeseries';
                break;
            case 'playlist':
                dataKey = 'typesSources';
                break;

            default:
                dataKey = 'Stats';
        }
        let discoveryKey = discovery ? 'Discovery' : '';
        return discoveryKey+dataKey;
    }    

    setChartMode(mode){
        this.setState({mode});
        
        const { modeChange } = this.props;
        if(typeof modeChange == 'function')
            modeChange(mode);
        

        if (mode == "playlist")
            this.getPlaylistStats();

        if (mode == "line" || mode == "bar")
            this.getTimeseries(false);
    }
    
    componentDidMount() {
        const { chartType, discovery = false } = this.props;
        const { mode } = this.state;
        if(discovery)
            this.props.dispatch(statsActions.getSourcesDiscoveryStats(this.props.entity, this.props.ids, this.props.filtered))
        else
            this.props.dispatch(statsActions.getSourcesStats(this.props.entity, this.props.ids, this.props.filtered));

        if (mode == "line" || mode == "bar")
            this.getTimeseries(false);

        if (mode == "playlist")
            this.getPlaylistStats(false);
        
        if(this.props.filter.global){
            if(this.props.filter.global!==undefined) {
                this.setState({
                    logoDataFiltered: chartType
                        ? dspLogos[chartType].data.filter((l) =>
                            this.props.filter.global.vendors.includes(l)
                        )
                        : [],
                });
            }                
        }
    }
    
    exportToCsv(){
        const dataKey = 'sources' + this.getDataKey();
        const filename = CSV.CSVHeader('details_sources', 'curr_units', this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.props.stats[dataKey].table, {filename});
    }
    
    componentWillReceiveProps(nextProps) {
        const globalFilter = nextProps.filter.global;
        const { chartType, discovery = false } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                if(discovery)
                    this.props.dispatch(statsActions.getSourcesDiscoveryStats(this.props.entity, this.props.ids, this.props.filtered))
                else
                    this.props.dispatch(statsActions.getSourcesStats(this.props.entity, this.props.ids, this.props.filtered));
                const { mode } = this.state;
                if (mode == "line" || mode == "bar")
                    this.getTimeseries(false);
                if (mode == "playlist")
                    this.getPlaylistStats(false);
                                    
                this.setState(prevState => {
                    return {
                    ...prevState,
                    mode: 'pie',
                    logoDataFiltered: chartType
                        ? dspLogos[chartType].data.filter((l) =>
                            globalFilter.vendors.includes(l)
                        )
                        : [],
                    };
                });
            }                
        }
    }
    
    getTimeseries(cache=true) {
        if(this.props.discovery)
            this.props.dispatch(statsActions.getSourcesDiscoveryTimeseries(this.props.entity, this.props.ids, cache, this.props.filtered));
        else
            this.props.dispatch(statsActions.getSourcesTimeseries(this.props.entity, this.props.ids, cache, this.props.filtered));
    }
    
    getPlaylistStats(cache=true) {
        if(this.props.discovery)
            this.props.dispatch(playlistsActions.getPlaylistTypesSourcesDiscovery(this.props.entity, this.props.ids, cache, this.props.filtered));
        else
            this.props.dispatch(playlistsActions.getPlaylistTypesSources(this.props.entity, this.props.ids, cache, this.props.filtered));
    }
    
    
    renderToolbar(modes){
        let toolbar = [];

        let options = [
            {icon: pieSvg, value: 'pie', label: "Donut Chart"},
            {icon: playlistSvg, value: 'playlist', label: "Playlist and Radio Chart"},
            {icon: tableSvg, value: 'table', label: "Table"},
            {icon: lineSvg, value: 'line', label: "Line Chart"},
            {icon: barSvg, value: 'bar', label: "Bar Chart"}
        ];

        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
        <div className="ibox-icon-holder mode-select">
            <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
        </div>
        </div>)
        return toolbar;
    }   
    
    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    renderTrends(data, single) {
        if(!data || !data.length)
            return null;
        
        const top = maxBy(data, 'share'),
            trending = maxBy(data, 'growth');
        
        return <div>
            <h2 className="content-title capitalize">{top.source}</h2>
            <h4 className="content-subtitle"> has been your top source for plays.</h4>
            <TrendDirection direction="up">
                Plays through <strong>{trending.source}</strong> has seen the most growth with <span className="num">{trending.growth}%</span>
            </TrendDirection>
        </div>;                        
    }

    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} modificator="small" />
            ))}
        </div>
    }
    
    renderLegend(dataset) {
        let items = [];
        for(let index in dataset.labels) {
            const color = dataset.datasets[0].backgroundColor[index],
                label = dataset.labels[index];
            items.push(<li className="sources-legend-item" key={label}><i className="fas fa-circle sources-color" style={{color}} />{label}</li>);
        }
        return items;
    }
    
    renderPlaylistStats() {
        const { playlists, discovery } = this.props;
        const dataKey = discovery ? 'typesSourcesDiscovery' : 'typesSources';
        
        if(playlists[`${dataKey}Loading`])
            return <Spinner enabled={true} />
        
        if(!playlists[dataKey])
            return null;

        const chartOptions = {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return context.label;
                        }
                    }
                }           
            }
        }
        return  <div className="row sources-list">
            <div className="col-xs-12 col-sm-6 sources-list-item">
                <h4 className="sources-item-title">Playlist</h4>
                <div className="chart-block">
                    <DoughnutChart data={playlists[dataKey].playlist} options={chartOptions} />
                </div>
                <ul className="sources-legend">
                    {this.renderLegend(playlists[dataKey].playlist)}
                </ul>
            </div>
            {!discovery && <div className="col-xs-12 col-sm-6 sources-list-item">
                <h4 className="sources-item-title">Radio</h4>
                <div className="chart-block">
                    <DoughnutChart data={playlists[dataKey].radio} options={chartOptions} />
                </div>
                <ul className="sources-legend">
                {this.renderLegend(playlists[dataKey].radio)}
                </ul>                    
            </div>}                
        </div>;
    }
    
    render (){
        const { stats, playlists, discovery = false } = this.props,
            trends = config.showTrends;

        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }
        console.log(this.state.mode);
        const dataKey = 'sources' + this.getDataKey();
        const title = discovery ? 'Sources of Discovery' : 'Sources of Streams';
        const rows = (stats[dataKey] && stats[dataKey].table) ? stats[dataKey].table.length : 0;
        return <Box title={title} toolbar={this.renderToolbar()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={stats[`${dataKey}Loading`]} exportToCsv={this.renderExportToCsv()} className="sources-charts">
            {(stats[dataKey] || (this.state.mode == 'playlist')) && <div className="row">
                {trends && <div className="col-sm-5 col-xs-12">
                    {this.renderTrends(stats[dataKey].table)}
                </div>}
                <div className={`col-xs-12 col-sm-${trends?7:12}`}>
                    {this.state.mode=='pie' && <div className="chart-block">
                        <DoughnutChart data={stats[dataKey].chart} {...shadowChartProps} />                          
                    </div>}
                    {this.state.mode=='table' && <div className="sources-table territory-table--single custom-scroll">
                        <ReactTable
                            className="source-table simple-table"
                            data={stats[dataKey].table}
                            columns={sourcesSettings.settingsForTable('details')}
                            defaultPageSize={(stats[dataKey].table && stats[dataKey].table.length ) ? Math.min(stats[dataKey].table.length, 10) : 10}
                            showPagination={rows > 10}
                            defaultSorted={this.state.defaultSorted}
                            getTrProps={sourcesSettings.settingsForRow}
                    /></div>}
                    {this.state.mode=='line' && <div className="chart-block">
                        <FillLineChart data={stats[dataKey]} {...shadowChartProps}  />                          
                    </div>}
                    {this.state.mode=='bar' && <div className="chart-block">
                        <StackedBarChart data={stats[dataKey]} {...shadowChartProps}  />                          
                    </div>}
                    {this.state.mode=='playlist' && <div>
                        <div className="table-header-tooltip sources">
                            <Tooltip 
                                position="right-bottom" 
                                message={`The granularity on Playlist and Radio sources is shown only when possible, provided the streaming platform gave us enough depth of data.`} 
                                tooltipClass="toolbar-title-tooltip sources-big-tooltip" 
                            />
                        </div>
                        {this.renderPlaylistStats()}
                    </div>}                    
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        playlists: state.playlists,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(SourcesStats);