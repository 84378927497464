import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import ReactTable from "react-table";
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import Tooltip from "../../common/Tooltip";
import { statsActions } from '../../../data/actions/stats';
import { locationsSettings } from './Locations/Settings'
const CellHeaderTable = cellHeaderHOC(ReactTable);

import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';
import EntityTimeline from '../../pages/home/EntityTimeline';
import CSV from '../../../helpers/CSVExport';
import ModeSelect from '../../common/ModeSelect';

var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');


class Location extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            view: 'table'
        };
        this.exportToCsv = this.exportToCsv.bind(this);
    }
    
    componentDidMount(){
        const { territory, filtered, mode } = this.props;
        console.log(filtered);
        this.props.dispatch(statsActions.getLocationStats(territory, filtered, null, null, mode));
    }
    
    exportToCsv(){
        const { territory, stats } = this.props,
            data = stats.locations[territory] || [],
            filename = CSV.CSVHeader('location', territory, this.props.filter.global);
        return CSV.CSVExport(data, {filename});
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>)
        return toolbar;
    }

    renderTooltip() {
        let tooltip = [];
        tooltip.push(<React.Fragment key="tooltip">
                <div className="table-header-tooltip">
                    {/* <span data-tip data-for="top-product-tip" className="notificationReportInfoIcon"> 
                        <i className="fas fa-question-circle" /> 
                    </span> */}
                    <Tooltip 
                        position="bottom" 
                        message={`Locations reported by Amazon, Pandora, Shazam, SoundCloud & Spotify.`} 
                        tooltipClass="toolbar-title-tooltip"
                    />
                </div>
            </React.Fragment>
        );
        return tooltip;
    }

    
    render () {
        const { territory, mode } = this.props,
        data = this.props.stats.locations[territory] || [],
        loading = this.props.stats.locationLoading;
        
        return <Box title="Top Locations" loadingTooltip={this.renderTooltip()} hasTooltip={true} toolbar={this.renderToolbar()} ><CellHeaderTable 
            resizable={false}
            loading={loading}
            defaultPageSize={10}
            data={data} 
            className={"new-releases-track top-tracks-in-playlist simple-table"} 
            columns={locationsSettings.settingsForTable(mode, territory)}
            noDataText='No results found. Please check your filters.'
        /></Box>
    }
    
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter,
    }
}


export default connect(mapStateToProps)(Location);