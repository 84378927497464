import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import parser from 'url-parse';
import AuthHeader from '../../../helpers/AuthHeader';
import { userActions } from '../../../data/actions/user'
import { userService } from '../../../data/services/user';

import TicketForm from '../../forms/ticket'

class ChatWidget extends React.Component {
    constructor(props){
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    toggleModal(e) {
        var element = $(e.target);
        var modalBtn = element.parents().find('.chat-widget-btn');
        var modalContainer = modalBtn.parent();
        modalContainer.toggleClass('active');
    }

    onSubmit(values){
        this.props.dispatch(userActions.sendTicket(values));
    }
    
    render() {
        const onSubmit = this.props.onSubmit;
        return (
            <div className="chat-widget-container">
                <div className="chat-widget-btn" onClick={this.toggleModal}>
                    <span className="chat-btn-icon">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="open-icon"><path fill="currentColor" d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"></path></svg>
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="close-icon"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                    </span>
                </div>
                <div className="chat-widget-modal">
                    <div className="chat-modal-head">
                        <p>Please fill out the form below to create the issue.</p>
                    </div>
                    <TicketForm onSubmit={this.onSubmit} />
                </div>
            </div>    
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}

export default connect(mapStateToProps)(ChatWidget);