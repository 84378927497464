import React, { Component } from 'react';

const GridItem  = React.forwardRef(({style, className, children, key, closeItem, isLocked, ...props}, ref) => {
    //const gridOptions = props['data-grid'];
    let widgetMode;
    // console.log(props.w);
    switch(props.w) {
        case 1:
        case 2:
            widgetMode = 'collapsed';
        break;
        case 3:
        case 4:
            widgetMode = 'normal';
        break;                
        default:
            widgetMode = 'expanded';
        break;
    }
    
    return <React.Fragment>
      {React.cloneElement(children, { widgetMode })}
      { !isLocked && <a className="close-grid-item-link" onClick={closeItem}><span className="fas fa-times"></span></a> }
      
      {/*React.cloneElement(children[1])*/}
      {/*
      */}
  </React.Fragment>;


    
//    return ( <div style={{ ...style}} className={className} ref={ref}>
//        
//
//        lol
//        {children[1]}
//        {/*
//            {React.cloneElement(children[0], { widgetMode })}
//            {React.cloneElement(children[1])}
//        */}
//      </div>  
//    );
});

export default GridItem;