import React, { Component } from 'react';
import ReactTable from "react-table";
import {Helmet} from 'react-helmet';
import store from '../helpers/store'
import cellHeaderHOC from '../components/common/Datagrid/CellHeaderHOC';

import { connect } from 'react-redux';
import { debounce, find, findIndex, isEqual, cloneDeep } from 'lodash';
import { withRouter } from 'react-router';

import Box from '../components/common/Box';
import { PieChart } from '../components/widgets/charts/types';
import DoughnutChart from '../components/widgets/charts/types/DoughnutChart';
import FieldSelector from '../components/common/Datagrid/FieldsSelector';
import PageFilter from '../components/common/Datagrid/PageFilter';
import EntityFilter from '../components/common/EntityFilter';
import EntityFilterSwitcher from '../components/common/EntityFilterSwitcher';
import EntityFilterButton from '../components/widgets/entityFilter/EntityFilterButton';
import SectionHeader from '../components/common/Datagrid/SectionHeader';
import FieldsSelectorHelper from '../components/common/Datagrid/FieldsSelectorHelper';
import StreamsStats from '../components/common/Stats/Streams';
import TerritoryStats from '../components/common/Stats/Territory';
import CSV from '../helpers/CSVExport';
import CatalogOverview from '../components/widgets/catalog/CatalogOverview';
import LoadingMessage from '../helpers/LoadingMessage';
import DropdownMenu from '../components/common/DropdownMenu'
import ModeSelect from '../components/common/ModeSelect';

import { artistsActions } from '../data/actions/artists';
import { artistsFormatter } from '../data/formatters/artists'
import { artistsSettings } from '../components/pages/artists/Settings'
import { filterActions } from '../data/actions/filter';

var manageSvg = require('!svg-inline-loader!../../public/img/manage.svg');
var pieSvg = require('!svg-inline-loader!../../public/img/pie.svg');
var tableSvg = require('!svg-inline-loader!../../public/img/table.svg');
var worldSvg = require('!svg-inline-loader!../../public/img/world.svg');
var lineSvg = require('!svg-inline-loader!../../public/img/line.svg');
var downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');
const CellHeaderTable = cellHeaderHOC(ReactTable);

class Artists extends Component {
  defaultPageSize = 10;

  constructor(props) {
    super(props);
    const savedFilter = store.get('userEntityFilter');
    this.state = {
      data: [],
      pages: -1,
      page: 0,
      //            pageSize: 10,
      filtered: "",
      filter: {
        // will be set from FieldsSelector
        columns: this.getInitialFilter(),
        filtered: [
          { id: "artist_name", value: "" },
          {id: 'imprints', value: this.getPageFilterInitial(savedFilter, 'imprints') },
          {id: 'exclude_imprints', value: this.getPageFilterInitial(savedFilter, 'exclude_imprints')},
          {id: 'track_artists', value: this.getPageFilterInitial(savedFilter, 'artists')},
          { id: "exclude_track_artists", value: this.getPageFilterInitial(savedFilter, "exclude_artists") },
          {id: 'products', value: this.getPageFilterInitial(savedFilter, 'products')},
          {id: 'exclude_products', value: this.getPageFilterInitial(savedFilter, 'exclude_products')},
          {id: 'projects', value: this.getPageFilterInitial(savedFilter, 'projects')},
          {id: 'exclude_projects', value: this.getPageFilterInitial(savedFilter, 'exclude_projects')},          
          {id: 'tracks', value: this.getPageFilterInitial(savedFilter, 'tracks')},
          {id: 'exclude_tracks', value: this.getPageFilterInitial(savedFilter, 'exclude_tracks')}
        ],
      },
      basic: true,
      defaultSorted: [{ id: "curr_units", desc: true }],
      view: "table",
      sources: {"Main": false, "Stream": false, "Video": false, "Radio": false, "Download": false, "Social Creates": false, "Social Views": false},
      currentlyHighlightedSource: null,
    };
    this.getPageFilter = this.getPageFilter.bind(this);
    this.setView = this.setView.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setFilterSearch = this.setFilterSearch.bind(this);
    this.setFilterSearchDeb = debounce(this.setFilterSearch, 1000);
    this.dispatchLoadData = this.dispatchLoadData.bind(this);
    this.addToArtistsFilter = this.addToArtistsFilter.bind(this);
    this.exportToCsv = this.exportToCsv.bind(this);
    this.expandSource = this.expandSource.bind(this);
    this.changeHighlightedSource = this.changeHighlightedSource.bind(this);
  }

    componentDidMount() {
        //this.dispatchLoadData(this.state);
    }

    getPageSize() {
        return this.props.filter.global ? this.props.filter.global.limit : this.defaultPageSize
    }

    loadData(state, instance) {
        this.setState({
            sorted: state.sorted
        }, ()=>this.dispatchLoadData(state));
    }

    getInitialFilter() {
        return FieldsSelectorHelper.getInitialFilter(artistsSettings.settingsForTable());
    }

    dispatchLoadData(state){
        const { dispatch } = this.props;
        const { basic } = this.state;
        dispatch(artistsActions.getArtists({
            page: state.page,
//            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }, basic));
    }

    getPageFilter(props, entity){
        let ids = [];
        if(props.filter[entity])
            ids = props.filter[entity].map(item=>item.id);
        return ids;
    }

    getPageFilterInitial(savedFilter, entity){
        let favHide = 'favorite';
        if(entity.startsWith('exclude_')) {
            favHide = 'hidden';
            entity = entity.replace('exclude_', '');
        }

        
        let ids = [];
        
        if(savedFilter && Array.isArray(savedFilter.entities)) {
            const entities = savedFilter.entities.filter(item=>(item.entity_type == entity && item.fav_hide == favHide));
            ids = entities.map(item=>item.entity_id);
        }
        return ids;
    }
    
    checkFilterIDs(nextProps, filterKey, valueKey) {
        if(nextProps.filter[filterKey]) {
            const newFilter = this.getPageFilter(nextProps, filterKey);
            return newFilter;
            const existingFilter = find(this.state.filter.filtered, {id:valueKey}).value;
            if(!isEqual(newFilter, existingFilter)){
                // this.setFilterSearch(newFilter, valueKey);
                return newFilter;
            }
        }
        return null;
    }

    componentWillReceiveProps(nextProps){
        const filterKeys = [
            {filter: 'imprints', value: 'imprints'},
            {filter: 'artists', value: 'track_artists'},
            {filter: 'products', value: 'products'},
            {filter: 'projects', value: 'projects'},
            {filter: 'tracks', value: 'tracks'}

        ];
        
        if(nextProps.artists && nextProps.artists.items){
            this.setState({
                data: nextProps.artists.items,
                pages: Math.ceil(nextProps.artists.total / this.getPageSize()),
                loaded: true
            })
        }
        if(nextProps.filter){
            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                    let state = Object.assign({}, this.state);
                    state.filtered = state.filter.filtered;
                    this.dispatchLoadData(state);
                }
                /*
                this.setState({
                    pageSize: nextProps.filter.global.limit
                });
                */
            }
            let newFilterOptions = cloneDeep(this.state.filter.filtered);
            
            for(let filterKey of filterKeys) {
                for(let prefix of ['', 'exclude_']) {
                    let filtered = find(newFilterOptions, option=>option.id == prefix+filterKey.value);
                    let newValue = this.checkFilterIDs(nextProps, prefix+filterKey.filter, prefix+filterKey.value);
                    if(newValue)
                        filtered.value = newValue; 
                }
            }

            if(!isEqual(this.state.filter.filtered, newFilterOptions)) {
                this.setState({
                    ...this.state,
                    filter: {
                        ...this.state.filter,
                        filtered: newFilterOptions
                    }
                }, ()=>{
                    let state = Object.assign({}, this.state);
                    state.filtered = state.filter.filtered;
                    this.dispatchLoadData(state)
                });
            }
        }
    }

    setFilter(filter){
        this.setState({
            filter,
        });
    }


    setFilterSearch(value, id){
        let filtered = this.state.filter.filtered.slice(0),
            valueIndex = findIndex(filtered, {id});

        if(valueIndex!==-1){
            filtered[valueIndex] = {id, value};
        }
        else {
            filtered.push({id, value})
        }

        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered
            }
        }, ()=>{
            let filterState = cloneDeep(this.state);
            filterState.filtered = filterState.filter.filtered;
            this.dispatchLoadData(filterState);
        });
    }

    setSearch(e){
        const value = e.target.value;
        this.setState({
            filtered: value
        })
        this.setFilterSearchDeb(value, 'artist_name')
    }

    setView(view){
        this.setState({
            view
        })
    }

    renderToolbar(views) {
        let toolbar = [];
        const dropdownHeader = <span key="settings" className="manage-columns-title">
            Manage Columns
        </span>

        const options = [
            {icon: tableSvg, value: 'table', label: "Table"},
            {icon: pieSvg, value: 'chart', label: "Donut Chart"},
            {icon: lineSvg, value: 'line', label: "Line Chart"},
            {icon: worldSvg, value: 'map', label: "World Map"},
        ];

        toolbar.push(<div className="ibox-action-wrapper with-manage-columns">
            <div className="ibox-icon-holder mode-select catalog-select">
                <ModeSelect options={options} selected={this.state.view} onChange={this.setView} isSearchable={ false } />
            </div>
            
            <div className="manage-columns-holder">
                <DropdownMenu header={dropdownHeader}>
                    <div key="settings-box">
                        <FieldSelector settings={artistsSettings.settingsForTable(
                                this.state.filter.columns,
                                this.addToArtistsFilter,
                                this.state.sources, 
                                this.expandSource
                            )} current={this.state.filter} onUpdate={this.setFilter} extraClassName="catalog-field-selector" />
                    </div>
                </DropdownMenu>
            </div>
        </div>)
        return toolbar;
    }

    addToArtistsFilter(artist){
        this.props.history.push(`/artists/${artist.id}`);
    }

    exportToCsv(){
        const sorted = this.state.sorted[0];
        const filename = CSV.CSVHeader('artists', sorted.id, this.props.filter.global);
        const data = CSV.expandTrends(this.state.data);
        return CSV.CSVExport(data, {filename}, 'artists');
    }

    expandSource(source, event) {
        if(event) {
            event.preventDefault();
            event.stopPropagation();
        }
        let sources = Object.assign({}, this.state.sources);
        sources[source] = !sources[source];
        let { basic } = this.state;
        let refreshCall = ()=>Promise.resolve();
        if(basic) {
            let state = Object.assign({}, this.state);
            state.filtered = state.filter.filtered;            
            refreshCall = ()=>this.dispatchLoadData(state);
        }
        this.setState({
            sources,
            basic: false
        }, ()=>refreshCall());
    }
    
    changeHighlightedSource(source) {
        if (source != this.state.currentlyHighlightedSource) {
            this.setState({currentlyHighlightedSource: source});
        }
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }

    render() {
        const { loaded } = this.state;
        let tableData = this.state.data,
            ids = [];

        if(tableData.length)
            ids = tableData.filter(item=>item.id).map(item=>item.id);

        return (
            <div>
            {!this.props.artists.loading && <div className="section-filter">
                <div className="page-filter-holder">
                {/*
                    <EntityFilterSwitcher loaded={loaded} entity="imprints" label="Imprints" />
                    <EntityFilterSwitcher loaded={loaded} entity="artists" label="Artists" />
                */}
                <EntityFilterButton />
                </div>
            </div>}
            <div className="">
                <Box title="Artists" toolbar={this.renderToolbar()} exportToCsv={this.renderExportToCsv()} className={`catalogue-section-holder ${this.state.view == 'line' ? 'line-chart' : ''}`}>
                    {/* <SectionHeader entity="artists" label="Artists" searchEntity="Artist" data={this.state.data} filtered={this.state.filtered} view={this.state.view} onChange={this.setSearch}>
                        <div className="manage-columns-holder">
                            <a key="settings" className="dropdown-toggle manage-columns-title" data-toggle="dropdown" href="#">
                                Manage Columns
                                <span className="chart-icon" dangerouslySetInnerHTML={{__html: manageSvg}}></span>
                            </a>
                            <div key="settings-box" className="dropdown-menu">
                                <FieldSelector settings={artistsSettings.settingsForTable()} current={this.state.filter} onUpdate={this.setFilter} />
                            </div>
                        </div>
                    </SectionHeader> */}
                    {this.state.view == 'table' && <div>
                        <CellHeaderTable
                            manual
                            loading={this.props.artists.loading}
                            loadingText={LoadingMessage('catalog')} 
                            noDataText='No results found. Please check your filters.'                        
                            className='catalogue-table-holder catalogue sticky-table'
                            data={tableData}
                            pages={this.state.pages}
                            defaultPageSize={this.getPageSize()}
                            showPageSizeOptions={false}
                            currentlyHighlightedSource={this.state.currentlyHighlightedSource}
                            changeHighlightedSource={this.changeHighlightedSource}
                            columns={artistsSettings.settingsForTable(
                                this.state.filter.columns,
                                this.addToArtistsFilter,
                                this.state.sources, 
                                this.expandSource
                            )}
                            getTheadThProps={(state, rowInfo, column, instance) => {
                                return {
                                    className: state.currentlyHighlightedSource == column.sourceGroup ? 'hovered' : '',
                                    onMouseEnter: (e, handleOriginal) => {                                  
                                        state.changeHighlightedSource(column.sourceGroup);
                                
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (handleOriginal) {
                                            handleOriginal()
                                        }
                                    }
                                }
                            }}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    className: state.currentlyHighlightedSource == column.sourceGroup ? 'hovered' : '',                              
                                    onMouseEnter: (e, handleOriginal) => {                                  
                                        state.changeHighlightedSource(column.sourceGroup);
                                
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (handleOriginal) {
                                            handleOriginal()
                                        }
                                    }
                                }
                            }}                
                            currentlySelectedSource={null}
                            defaultSorted={this.state.defaultSorted}
                            filtered={this.state.filter.filtered}
                            onFetchData={this.loadData}
                            defaultSortDesc={true}
                        />
                    </div>}
                    {this.state.view == 'chart' && <div class="table-chart"><DoughnutChart
                        data={artistsFormatter.formatForPercentageChart(tableData, 'curr_units')}
                        labels={{header: 'Artists', titleLabel: 'Artist', valueLabel: 'streams'}}
                    /></div>}
                    {this.state.view == 'line' && <div class="table-chart"><StreamsStats
                        entity="artists" ids={ids} filtered={this.state.filter.filtered}
                    /></div>}
                    {this.state.view == 'map' && <div class=""><TerritoryStats
                        entity="artists" ids={ids} expanded={true} filtered={this.state.filter.filtered}
                    /></div>}

                </Box>
                <div className="row">
                    <div className="col-xs-4">

                    </div>
                </div>
            </div>
            <Helmet>
                <title>Artists - Catalogue Overview</title>
            </Helmet>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        artists: state.artists,
        filter: state.filter
    }
}
export default withRouter(connect(mapStateToProps)(Artists))
