import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual } from 'lodash';
import ReactTable from "react-table";
import CSV from '../../../helpers/CSVExport';
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { productsActions } from '../../../data/actions/products';
import { projectsActions } from '../../../data/actions/projects';
import { tracksActions } from '../../../data/actions/tracks';
import { LineChart} from '../../widgets/charts/types';
import { splitByThousands } from '../../../helpers/NumberFormatter';

class AlbumArtistStats extends React.Component {
	
	constructor(props) {
		super(props);
		this.exportToCsv = this.exportToCsv.bind(this);        
	}
	
	exportToCsv(){
		const { entity, id }  = this.props,
			{ data } = this.props[entity].artistComparison[id];


		
		const filename = CSV.CSVHeader(entity, id);
		const title = filename + '-comparison-artist';
		return CSV.CSVExport(data, {filename: title}, title);
	}

	renderToolbar(){
		let toolbar = [];
		toolbar.push(<div key="wrapper" className="ibox-action-holder">
		<div className="ibox-actions">
		  <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		</div>
		</div>)
		return toolbar;
	}

	renderExportToCsv() {
		return (
			<a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		)
	}


	componentDidMount() {
		const { id, weeks, territories, vendors, metadata } = this.props,
			releaseDate = metadata.release_date,
			artistID = metadata.primary_artist_id;
						
		this.props.compareAlbumArtist(id, artistID, weeks, releaseDate, territories, vendors)
		
	}
	
	componentWillReceiveProps(nextProps) {
	}
	
	render (){
		const { entity, id, metadata, chartID = '', shadowChartID, weeks }  = this.props,
			chartData = this.props[entity].artistComparison[id];

		const period = weeks <= 8 ? 'Week' : 'Month';
		const title = ( metadata && metadata.name_compare ) ? metadata.name_compare : id;
		
		const formatterCallback = splitByThousands;
		const chartOptions = {
			maintainAspectRatio: false,
			scales: {
				y: {
					ticks: {
						callback: function(value, index, values) {
							return formatterCallback(value);
						}
					}
				},
				x: {
					title: {
						display: true,
						text: `${period}s Since Release`
					},
					ticks: {
						callback: function(value, index, values) {
							return value == 0 ? 'Release' : value;
						}
					}                    
				}
			},
			plugins: {
				tooltip: {
					callbacks: {
						label: function(context) {
							return formatterCallback(context.raw);
						},
						title: function(context) {
							if(Array.isArray(context) && context.length) {
								const {label} = context[0];
								return label == 0 ? `Release ${period}` : `Release ${period} + ${label}`
							}
						}
					}
				}
			}
		};
		
		return <Box title={title} spinnerEnabled={this.props[entity].artistComparisonLoading} exportToCsv={this.renderExportToCsv()}>
		
			{chartData && <div className="row">
				<div className={`col-xs-12`}>
					{chartData.datasets.length > 0 && <div className="chart-block">
						<LineChart data={chartData} id={chartID} options={chartOptions} shadowChart={true} shadowChartWidth={400} shadowChartHeight={300} shadowChartId={shadowChartID} />                          
					</div>}
					{!chartData.datasets.length && <p>No data available</p>}
				</div>    
			</div>}
		</Box>
	}
}

function mapStateToProps(state) {
	return {
		tracks: state.tracks,
		products: state.products,
		projects: state.projects,
		filter: state.filter,
		userData: state.user
	} 
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		compareAlbumArtist: (id, artistID, weeks, releaseDate, territories, vendors) => {
			switch(ownProps.entity) {
				case 'products':
					dispatch(productsActions.compareProductArtist(id, artistID, weeks, releaseDate, territories, vendors));
					break;
				case 'projects':
					dispatch(projectsActions.compareProjectArtist(id, artistID, weeks, releaseDate, territories, vendors));
					break;
					
				default:
					dispatch(tracksActions.compareTrackArtist(id, artistID, weeks, releaseDate, territories, vendors));
					break;

			
			}
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(AlbumArtistStats);
