import { userEntityFiltersConstants } from '../constants/user_entity_filters';

const initialState = {
        loading: false,
        errors: null,
        recentFilters: []
}

export function userEntityFilters(state = initialState, action){

  switch (action.type) {
    case userEntityFiltersConstants.GET_USER_ENTITY_FILTERS_REQUEST:
      return {
          ...state, 
          loading: true, 
          errors: null 
      };
    case userEntityFiltersConstants.GET_USER_ENTITY_FILTERS_SUCCESS:
      return { 
          ...state, 
          loading: false, 
          filters: action.filters ,
          playlistFilters: action.playlistFilters
      };
    case userEntityFiltersConstants.GET_USER_ENTITY_FILTERS_FAILURE:
      return { 
          ...state, 
          loading: false, 
          errors: action.error
      };

	case userEntityFiltersConstants.GET_PLAYLIST_ENTITY_FILTERS_REQUEST:
		return {
			...state, 
			playlistLoading: true, 
		};
	  case userEntityFiltersConstants.GET_PLAYLIST_ENTITY_FILTERS_SUCCESS:
		return { 
			...state, 
			playlistLoading: false, 
			playlistFilters: action.filters 
		};
	  case userEntityFiltersConstants.GET_PLAYLIST_ENTITY_FILTERS_FAILURE:
		return { 
			...state, 
			playlistLoading: false, 
			errors: action.error
		};

      
    case userEntityFiltersConstants.CREATE_USER_ENTITY_FILTER_REQUEST:
    case userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_REQUEST:
    case userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_REQUEST:
        return { 
            ...state, 
            filterLoading: true, 
            errors: null
        };
      case userEntityFiltersConstants.CREATE_USER_ENTITY_FILTER_SUCCESS:
      case userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_SUCCESS:
      case userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_SUCCESS:
        return { 
            ...state, 
            filterLoading: false, 
            errors: null 
        };
      case userEntityFiltersConstants.CREATE_USER_ENTITY_FILTER_FAILURE:
      case userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_FAILURE:
      case userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_FAILURE:
        return { 
            ...state, 
            filterLoading: false, 
            errors: action.error
        };
        
    

        
  /*
    case userEntityFiltersConstants.GET_ALL_USER_ENTITY_FILTERS_REQUEST:
        state.allUserEntityFilters = [];
        return { ...state, loading: true, errors: null };
      case userEntityFiltersConstants.GET_ALL_USER_ENTITY_FILTERS_SUCCESS:
        state.allUserEntityFilters = payload;
        return { ...state, loading: false, errors: null };
      case userEntityFiltersConstants.GET_ALL_USER_ENTITY_FILTERS_FAILURE:
        state.allUserEntityFilters = [];
        return { ...state, loading: false, errors: payload };      

    case userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_REQUEST:
      return { ...state, loading: false, errors: payload, filter: {}}

    case userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_SUCCESS:
      let filters = state.userEntityFilters[userEntity].slice();
      filters = filters.filter( filter => filter.name !== payload.name);
      state.userEntityFilters[userEntity] = filters;
      return { ...state, loading: false, errors: payload, filter: payload};

    case userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_FAILURE:
      return { ...state, loading: false, errors: payload, filter: {}};


*/
      case userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_REQUEST:
          return {
              ...state, 
              recentloading: true,
              recentFilters: [],
              errors: null 
          };
        case userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_SUCCESS:
          return { 
              ...state, 
              recentLoading: false, 
              recentFilters: action.filters 
          };
        case userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_FAILURE:
          return { 
              ...state, 
              recentLoading: false, 
              errors: action.error
          };

          
    default:
      return state
  }
}
