import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../../common/Modal';
import EntityFilter from './EntityFilter';
import store from '../../../helpers/store'
import { filterActions } from '../../../data/actions/filter';

class EntityFilterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.showModal = this.showModal.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
    }
    
    showModal(show) {
        this.setState({
            showModal: show !== undefined ? show: !this.state.showModal
        })
    }
    
    applyFilter(filter) {
        this.showModal(false);
        this.props.dispatch(filterActions.setNewEntityFilter(filter));
    }
    
    resetFilter(){
        store.set('userEntityFilter', null);
        this.props.dispatch(filterActions.setNewEntityFilter())
    }
    
    renderCurrentFilter() {
        const currentFilter = store.get('userEntityFilter') || null;
        if(!currentFilter)
            return null;
        return <span>{currentFilter.caption} <a title="Reset Filter" onClick={()=>this.resetFilter()}>&times;</a></span>;
    }

    render() {
        return <div className="entity-filter-button-holder">
            <Modal show={this.state.showModal} 
                title="Saved Filters" 
                showActionButtons={false}
                handleClose={()=>this.showModal(false)}
                dialogClassName="entity-filter-modal"
            >
                <EntityFilter applyFilter={this.applyFilter} />
            </Modal>
            <button onClick={this.showModal} className="btn default-btn">Saved Filters</button>
            <Link to="/user/entities-filter/new" className="btn default-btn new-filter-btn">New Filter</Link>
            <div className="catalog-current-filter-holder">
                {this.renderCurrentFilter()}
            </div>
        </div>
    }
}


function mapStateToProps(state) {
    return {
        userEntityFilters: state.userEntityFilters
    } 
}
export default connect(mapStateToProps)(EntityFilterModal);