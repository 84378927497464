import React from 'react';
import Spinner from './Spinner'; 
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';

class Box extends React.Component {
    render() {
        const { className, title, mobileTitle, toolbar, spinnerEnabled, children, loadingMessage, hasTooltip, loadingTooltip, showLink, vendorLogoList, exportToCsv, mobileClassName, chartActions, ...restProps } = this.props;
        const message = (spinnerEnabled && loadingMessage) ? <p className="loading-message">{loadingMessage}</p> : null;
        const tooltip = hasTooltip ? <div className="title-tooltip">{loadingTooltip}</div> : null;

        return (
            <div className={`ibox ${className}`}>
                <div className="ibox-title" {...restProps}>
                    <h2 className={`ibox-title__title ${mobileClassName}`}>
                        <span className="title-text">
                            {mobileTitle ? <React.Fragment>
                                <span className="mobile-top-title">{mobileTitle}</span>
                                <span className="desktop-top-title">
                                    {title}
                                    {tooltip}
                                </span>
                            </React.Fragment>
                            : <React.Fragment>
                                {title}
                                {tooltip}
                            </React.Fragment>
                            }
                        </span>
                        {showLink}
                        <div className="toolbar-actions">
                            {vendorLogoList}
                            {exportToCsv}
                            <div className="ibox-tools">
                                {toolbar}
                            </div>
                        </div>
                    </h2> 
                </div>
                <div className="ibox-chart-actions">
                  {chartActions}
                </div>
                <div className="ibox-content"  {...restProps}>
                    {message}
                    <Spinner enabled={spinnerEnabled} />
                    {children}
                </div>
            </div>            
        )
    }
}

export default Box