import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual, cloneDeep } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { productsActions } from '../../../data/actions/products';
import { projectsActions } from '../../../data/actions/projects';
import { tracksActions } from '../../../data/actions/tracks';
import { StackedBarChart } from '../../widgets/charts/types';


class VendorsStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }
    
    exportToCsv(){
        let { entity, id }  = this.props,
            exportData = this.props[entity].vendorsComparison[id].data;
        const title = ( entity == 'tracks' ? 'track' : 'product') + '-comparison-vendors';
        const filename = CSV.CSVHeader(title, id);
        return CSV.CSVExport(exportData, {filename}, title);
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }

    componentDidMount() {
        const { id, weeks, metadata, territories, vendors } = this.props,
            releaseDate = metadata.release_date;
        this.props.compareVendors(id, weeks, releaseDate, territories, vendors)
        
    }
    
    componentWillReceiveProps(nextProps) {
    }

    
    render (){
        let { entity, id, metadata, source, chartID = '', shadowChart = false, weeks }  = this.props,
            chartData = cloneDeep(this.props[entity].vendorsComparison[id]);
        const period = weeks <= 8 ? 'Week' : 'Month';
        
        if(chartData && source) {
            chartData.datasets = chartData.datasets.filter(dataset => dataset.source == source); 
        }
        
        const chartOptions = {
            scales: {
                x: {
                    title: {
                        display: true,
                        text: `${period}s Since Release`
                    },
                    ticks: {
                        callback: function(value, index, values) {
                            return value == 0 ? 'Release' : value;
                        }
                    }                    
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: function(context) {
                            if(Array.isArray(context) && context.length) {
                                const {label} = context[0];
                                return label == 0 ? `Release ${period}` : `Release ${period} + ${label}`
                            }
                        }
                    }
                }
            }            
        }
        
        const title = ( metadata && metadata.name_compare ) ? metadata.name_compare : id;
        return <Box title={title} spinnerEnabled={this.props[entity].vendorsComparisonLoading} exportToCsv={this.renderExportToCsv()}>
        
            {chartData && <div className="row">
                <div className={`col-xs-12`}>
                    {chartData.datasets.length > 0 && <div className="chart-block">
                        <StackedBarChart data={chartData} options={chartOptions} id={chartID} shadowChart={shadowChart} />                          
                    </div>}
                    {!chartData.datasets.length && <p>No data available</p>}
                </div>
            </div>}
            
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        products: state.products,
        projects: state.projects,
        filter: state.filter,
        userData: state.user
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        compareVendors: (id, weeks, releaseDate, territories, vendors) => {
            switch(ownProps.entity) {
                case 'products':
                    dispatch(productsActions.compareProductVendors(id, weeks, releaseDate, territories, vendors));
                    break;
                case 'projects':
                    dispatch(projectsActions.compareProjectVendors(id, weeks, releaseDate, territories, vendors));
                    break;                    
                default:
                    dispatch(tracksActions.compareTrackVendors(id, weeks, releaseDate, territories, vendors));
                    break;

            
            }
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VendorsStats);