import { map, sumBy, find, orderBy, filter, uniq, capitalize, findIndex, sortBy, sum, flatten, cloneDeep } from 'lodash'
import alpha from 'color-alpha';
import vendorsArray from '../../components/widgets/filter/enums/vendors';
import moment from 'moment';
import countriesByAbbreviation from '../../components/common/CountriesByAbbreviation';
import rand from '../../helpers/rand';
import {percentageValue} from '../../helpers/NumberFormatter';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import { colors } from '../../components/widgets/charts/ChartDefaults'; 
import content_types from '../../components/widgets/filter/enums/content_types';
import { formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';

var streamSvg = require('!svg-inline-loader!../../../public/img/icons/stream.svg');
var socialSvg = require('!svg-inline-loader!../../../public/img/icons/social_music.svg');
var videoSvg = require('!svg-inline-loader!../../../public/img/icons/video.svg');
var radioSvg = require('!svg-inline-loader!../../../public/img/icons/radio.svg');
var bookSvg = require('!svg-inline-loader!../../../public/img/icons/audiobook.svg');
var downloadsSvg = require('!svg-inline-loader!../../../public/img/icons/download.svg');

export const statsFormatter = {
    formatTop,
    formatCatalogStats,
    formatVendorStats,
    formatVendorTimeseries,
    formatTopTerritory,
    formatTopTerritoryTimeseries,
    formatTerritory,
    formatTerritoryTimeseries,
    formatDemographics,
    formatDemographicsTimeseries,
    formatDevices,
    formatSources,
    formatSourcesTimeseries,
    formatDevicesTimeseries,
    formatMultiEntitiesStreams,
    formatMultiMetadata,
    extractMinDate,
    formatLocationStats,
    formatTopLocationStats
};

const vendorTypesToIncludeInTitle = ['Video', 'Radio'];

function formatCatalogStats(data){
        let settings = {
            imprints: {icon: "user", route: '/catalog/imprints'},
            artists: {icon: "users", route: '/catalog/artists'},
            products: {icon: "trophy", route: '/catalog/products'},
            tracks: {icon: "music", route: '/catalog/tracks'}
        },
        result = [];
        data = data [0];
        for (let key of Object.keys(settings)){
            result.push({
                "entity": key,
                "icon": settings[key].icon,
                "title": `Total ${capitalize(key)}`,
                "absoluteValue": data[`curr_${key}`],
                "percentageValue": null, //percentageValue(data[`curr_${key}`], data[`prev_${key}`]),
                "route": settings[key].route
            })
        }
        return result;
}

function _getVendorLabel(item) {
    let label = item.vendor; 
    if(vendorTypesToIncludeInTitle.includes(item.content_type) && item.vendor.indexOf(item.content_type)===-1)
        label = `${item.vendor} (${item.content_type})`;
    return label;
}

function formatTop(data, followers){
    let icons = {
        "Stream": streamSvg,
        "Listeners": downloadsSvg,
        "Followers": socialSvg,
        "Playlists": videoSvg,
        "Video": videoSvg,
        "Social Creates": socialSvg,
        "Social Views": socialSvg,
        "Radio": radioSvg,
        "Download": downloadsSvg
    },
    result = [];
    
    let uniqueListeners = 0,
        prevPlaylists = 0,
        currPlaylists = 0, 
        prevFollowers = 0,
        currFollowers = 0;
    
    for (let item of data){
        let key = item.content_type;
        let name = item.content_type_id !== 2 ? key + '' : key;
        
        currPlaylists += item.curr_playlists;
        prevPlaylists += item.prev_playlists;

        switch (item.content_type_id) {
            case 1:
                uniqueListeners = item.listeners || 0;
                name = "Streams";
                break;
            case 3:
                name = "Video Views";
                break;
            case 2:
                name = "Downloads";
                break;
            case 4:
                name = "Radio Plays";
                break;
            case 7:
                name = "Social Creates";
                break;
            case 8:
                name = "Social Views";
                break;
        }
        result.push({
            "entity": key,
            "icon": icons[key],
            "title": name,
            "absoluteValue": item.curr_units,
            "percentageValue": percentageValue(item.curr_units, item.prev_units),
            "route": '',
            vendors: item.vendor_totals,
            curr_units: item.curr_units,
            prev_units: item.prev_units
        })
    };
    result.push({
        "entity": 'Playlists',
        "icon": icons['Playlists'],
        "title": 'Playlist Streams',
        "absoluteValue": currPlaylists,
        "percentageValue": percentageValue(currPlaylists, prevPlaylists),
        "route": '',
        vendors: [],
        curr_units: currPlaylists,
        prev_units: prevPlaylists
    });
    if(uniqueListeners) {
        result.push({
            "entity": 'Listeners',
            "icon": icons['Listeners'],
            "title": 'Listeners',
            "absoluteValue": uniqueListeners,
            "percentageValue": undefined,
            "route": '',
            vendors: [],
            curr_units: uniqueListeners,
            prev_units: undefined
        })        
    }
    if(followers && followers.data.length) {
        for(let row of followers.data){
            //followers += row.spotify_followers;
            if(row.by_date.length) {
                prevFollowers += Number(row.by_date[0].metric);
                currFollowers += Number(row.by_date[row.by_date.length-1].metric);
            }
        }
    }
    if(currFollowers) {
        result.push({
            "entity": 'Followers',
            "icon": icons['Followers'],
            "title": 'Followers',
            "absoluteValue": currFollowers,
            "percentageValue": percentageValue(currFollowers, prevFollowers),
            "route": '',
            vendors: [],
            curr_units: currFollowers,
            prev_units: prevFollowers
        })        
    }

    return sortBy(result, (result) => Object.keys(icons).indexOf(result.entity));;
}

function formatVendorStats(mainData, mode, vendor, contentType){
    const typeOrder = ['Stream', 'Video', 'Radio', 'Download', 'Audiobook', 'Social Creates', 'Social Views'];
    
    
    let vendors,
        labels,
        datasets;
    
    if(!mainData)
        return {};
    
    mainData = sortBy(mainData, (item) => typeOrder.indexOf(item.content_type));
    
    //const origData = Object.assign([], data);
    const totalStreams = sumBy(mainData, 'curr_units');
    
    if(contentType==null)
        contentType = mainData.length ? mainData[0].content_type : '';
    else
        contentType = contentType.value;
        
    
    for(let colorIndex in mainData) {
        let item = mainData[colorIndex];
        item.engaged = item.curr_units ? Math.round((item.active / item.curr_units) * 100) : 0;
        item.share = totalStreams ? Math.round((item.curr_units / totalStreams) * 100) : 0;
        item.playlist_share = item.curr_units ? Math.round((item.curr_playlist_units / item.curr_units) * 100) : 0;
        item.platform = item.vendor;        
        const itemVendor = find(vendorsArray, (vendor)=>vendor.label == item.vendor);
        item.color = itemVendor ? itemVendor.hex[item.content_type] : colors[colorIndex];
        item.total = sumBy(item.stms_by_date, 'curr_units');
        item.units_change = item.curr_units - item.prev_units;
        item.units_diff = percentageValue(item.curr_units, item.prev_units)
    }
    
    let data = cloneDeep(mainData);
    if(contentType)
        data = filter(data, (item)=>item.content_type==contentType);
    
    vendors=data.map(item=>item.vendor);
    
    if(vendor!=='all'){
        data = filter(data, (item)=>item.vendor==vendor);
    }    
        
    datasets = [{ data : data.map(vendor=>vendor.curr_units), label: ` streams`, backgroundColor: data.map(vendor=>vendor.color )}];
    labels = data.map(vendor=>`${vendor.vendor}`); // (${Math.round(vendor.curr_units/total*100)}%)`);

    return {chart:{labels, datasets, vendors}, table: mainData};
}

function formatVendorTimeseries(data, mode, vendor, contentType){
    const typeOrder = ['Stream', 'Video', 'Radio', 'Download', 'Audiobook', 'Social Creates', 'Social Views'];
    
    
    let vendors,
        timeseriesLabels,
        timeseriesDatasets;
    
    if(!data)
        return {};
        
    //
    for(let item of data) {
        item.platform = item.vendor;
        item.stms_by_date = item.totals;
        item.stms_by_date = item.stms_by_date.sort(sortDateRange);
    }    
    //
    
    data = sortBy(data, (item) => typeOrder.indexOf(item.content_type));
    
    if(contentType==null)
        contentType = data.length ? data[0].content_type : '';
    else
        contentType = contentType.value;
        
    
    for(let colorIndex in data) {
        let item = data[colorIndex];
        const itemVendor = find(vendorsArray, (vendor)=>vendor.label == item.vendor);
        item.color = itemVendor ? itemVendor.hex[item.content_type] : colors[colorIndex];
        item.total = sumBy(item.stms_by_date, 'curr_units');
        item.growth = item.stms_by_date.length ? (item.stms_by_date[item.stms_by_date.length-1].curr_units - item.stms_by_date[0].curr_units) : 0;
    }
    
    if(contentType)
        data = filter(data, (item)=>item.content_type==contentType);
    
    vendors=data.map(item=>item.vendor);
    
    if(vendor!=='all'){
        data = filter(data, (item)=>item.vendor==vendor);
    }    
        
    
            const { dates, period } = dateRange(data);
            timeseriesDatasets = data.map(vendor=>{
                let data = [];
                for(let date of dates) {
                    let stms = find(vendor.stms_by_date, {stream_date: date});
                    data.push(stms ? stms.curr_units : null);
                }    
                return {
                    label: _getVendorLabel(vendor),
                    data: data, 
                    borderColor: vendor.color,
                    backgroundColor: vendor.color,
                    pointBorderColor: vendor.color,
                    pointBackgroundColor: vendor.color,
                    fill: false
                }});    

            timeseriesLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    
    
    return {labels: timeseriesLabels, datasets:timeseriesDatasets, vendors, data};
}



function formatTopTerritory(data, mode, limit = 10){
    if(!data)
        return {};
        
    let chart = {},
        world = {},
        timeline = {},
        table;
    
    //const total = sumBy(data, 'curr_units');
    
    table = filter(map(data, (territory=>{
//        const value = sumBy(territory.data, (item)=>item[1]),
//            country = find(countriesByAbbreviation, {abbreviation: territory.name});
//        if(!country)
//            return null;
        
        const code = territory.territory,
            country = find(countriesByAbbreviation, {abbreviation: code});
        
        if(territory.territory == 'ZZ')
            territory.territory_name = 'Unknown';
        
        territory.name = country ? country.country : '-';
        territory.territory_name = territory.name;
        territory.code = code;
        territory.value = territory.curr_units;
        territory.share = territory.total_units ? Math.round(territory.curr_units/territory.total_units*100) : 0;
        territory.perCapita = territory.per_cap_units;
        territory.engaged = territory.curr_units ? Math.round((territory.active / territory.curr_units) * 100) : 0;
        return territory;
    })));
    table = orderBy(table, ['value'], ['desc']);
    
    for (let rank = 0; rank < table.length; rank++) {
        let item = table[rank];
        item.rank = rank + 1; 
    }
    
    for(let territory of table.slice(0, limit)){
        world[territory.code] = territory.value;
    }                
    switch(mode){
        case 'pie':
            chart = {
                labels: table.slice(0, limit).map(territory=>territory.name),
                datasets: [{ data : table.map(territory=>territory.value), label: 'plays'}]
            }
        break;
        /*
        case 'line':
            const { dates, period } = dateRange(data);
            timeline.datasets = table.map(territory=>{
                
                territory.stms_by_date = territory.stms_by_date.sort(sortDateRange);
                let data = [];
                for(let date of dates) {
                    let stms = find(territory.stms_by_date, {stream_date: date});
                    data.push(stms ? stms.units : null);
                }    
                
                return {
                    label: territory.name,
                    data,
                    fill: false
                };
            });
            timeline.labels =  map(dates, (date)=>formatChartLabelItem(date, period));
            
        break;
        */        
    }
        
    
    return {chart, world, table, timeline};
}

function formatTopTerritoryTimeseries(data, limit = 10){
    if(!data)
        return {};
        
    let labels = [],
        datasets = [];
    
    const territories = uniq(map(data, 'territory'));

    const {dates, period} = dateRangeFlat(data);
    
    datasets = territories.map((territory, index)=>{
        
        const country = find(countriesByAbbreviation, {abbreviation: territory}),
            territoryName = country ? country.country : '-';
        
        let streamData = [];
        for(let date of dates) {
            let stream = find(data, {stream_date: date, territory});
            streamData.push(stream ? stream.curr_units : null);
        }    

        return {
            id: territory,
            label: territoryName,
            data: streamData,
            fill: false,
            borderColor: colors[index%colors.length],
            backgroundColor: colors[index%colors.length],
            pointBorderColor: colors[index%colors.length],
            pointBackgroundColor: colors[index%colors.length],                

        }
    });    
    
    labels =  map(dates, (date)=>formatChartLabelItem(date, period));

    
    return { labels, datasets };
}


function formatTerritory(data, limit = 10){
    
    if(!data)
        return {};
        
    let chart = {},
        world = {},
        table,
        total = 0;
    
    table = filter(map(data, (territory=>{

        if(territory.territory == 'ZZ')
            territory.territory_name = 'Unknown';
        
        total += territory.curr_units;
        
        territory.name = territory.territory_name;
        territory.code = territory.territory;
        territory.value = territory.curr_units;
        territory.total_units = territory.total_units;
        territory.engaged = Math.round((territory.curr_active / territory.curr_units) * 100);
        return territory;
    })));
    
    table = orderBy(table, ['value'], ['desc']);
    
    for (let rank = 0; rank < table.length; rank++) {
        let item = table[rank];
        item["rank"] = rank+1;
        item["share"] = Math.round((item["value"]/item["total_units"])*100);
    }
    
    for(let territory of table.slice(0, limit)){
        world[territory.code] = territory.value;
    };
    /*
     * 
     pie

    chart = {
        labels: table.slice(0, limit).map(territory=>territory.name),
        datasets: [{ data : table.map(territory=>territory.value), label: 'plays'}]
    }
    */
    
    let labels = [],
    datasets = [];
    const { dates, period } = dateRange(data);
    datasets = data.map(territory=>{
        territory.stms_by_date = territory.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(territory.stms_by_date, {stream_date: date});
            data.push(stms ? stms.units : null);
        }    
            
        return {
            label: territory.name,
            data,
            fill: false
        }
    });
    
    labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    chart = { labels, datasets };

            
    return {chart, world, table};
}

function formatTerritoryTimeseries(data, mode, limit = 10){
    if(!data)
        return {};
        
    let chart = {},
        world = {},
        timeline = {},
        table;
    
    const total = sumBy(data, 'curr_units');
    
    table = filter(map(data, (territory=>{
//        const value = sumBy(territory.data, (item)=>item[1]),
//            country = find(countriesByAbbreviation, {abbreviation: territory.name});
//        if(!country)
//            return null;
        
        const code = territory.territory,
            country = find(countriesByAbbreviation, {abbreviation: code});
        
        territory.name = country ? country.country : '-';
        territory.code = code;
        territory.value = territory.curr_units;
        territory.share = Math.round(territory.curr_units/territory.total_units*100);
        territory.perCapita = territory.per_cap_units;
        
        return territory;
    })));
    table = orderBy(table, ['value'], ['desc']);
    for(let territory of table.slice(0, limit)){
        world[territory.code] = territory.value;
    }                
    for(let territory of data) {
        territory.stms_by_date = territory.totals;
    }
    
    const { dates, period } = dateRange(data);
    
    timeline.datasets = table.map(territory=>{
        
        territory.stms_by_date = territory.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(territory.stms_by_date, {stream_date: date});
            data.push(stms ? stms.curr_units : null);
        }    
        
        return {
            label: territory.name,
            data,
            fill: false
        };
    });
    timeline.labels =  map(dates, (date)=>formatChartLabelItem(date, period));
        
    
    return timeline;
}


function formatDemographics(data) {
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    let genders = uniq(map(data, 'gender')),
        ages = uniq(map(data, 'age')),
        datasets = {},
        active = {},
        passive = {},
        timelineLabels = [],
        timelineDatasets = [],        
        totalStreams = sumBy(data, 'curr_units'),
        pieDatasets = {},
        tableData = [];
    
    ages.sort();
    genders = sortBy(genders, (gender) => genderOrder.indexOf(gender));

    for(let gender of genders) {
        datasets[`${gender}_active`] = [];
        datasets[`${gender}_passive`] = [];
        active[gender] = [];
        passive[gender] = [];
        pieDatasets[gender] = {active: 0, passive: 0};
    }
    
    for(let entry of data) {
        //datasets[entry.gender][ages.indexOf(entry.age)] = entry.curr_units;
        active[entry.gender][ages.indexOf(entry.age)] = entry.active;
        passive[entry.gender][ages.indexOf(entry.age)] = entry.passive;
        datasets[`${entry.gender}_active`][ages.indexOf(entry.age)] = entry.active; 
        datasets[`${entry.gender}_passive`][ages.indexOf(entry.age)] = entry.passive;
        entry.share = Math.round(entry.curr_units / totalStreams * 100);
    }
    
    datasets = map(datasets, (data, label)=>{
        const [gender, mode] = label.split('_');
        const colors = ["#4D77FF", "#FC66B4", "#FDC14E"];
        const color = colors[genderOrder.indexOf(gender)];
        const barColor = mode == 'passive' ? alpha(color, 0.5): color;
        pieDatasets[gender].active += sum(active[gender]);
        pieDatasets[gender].passive += sum(passive[gender]); 
        
        return {
            data, 
            label: `${gender} ${mode == 'passive' ? '(passive)' : ''}`, 
            stack: gender, 
            active: active[gender], 
            passive: passive[gender],
            backgroundColor: barColor
        };
    });


    let mainDataset = {data: [], label: "plays", backgroundColor: []},
        splitDataset = {data: [], label: "plays" , backgroundColor: []},
        
        pieLabels = [];
    
    const pieGrandTotal = sum(Object.values(pieDatasets).map(dataset=>dataset.active+dataset.passive));
    
    for(let gender of Object.keys(pieDatasets)) {
        const dataset = pieDatasets[gender];
        const colors = ["#4D77FF", "#FC66B4", "#FDC14E"];
        const color = colors[genderOrder.indexOf(gender)];
        const total = Number(dataset.active)+Number(dataset.passive);

        splitDataset.data.push(Number(dataset.active));
        splitDataset.data.push(Number(dataset.passive));
        splitDataset.data.push(0);
        
        splitDataset.backgroundColor.push(color);
        splitDataset.backgroundColor.push(alpha(color, 0.9));
        splitDataset.backgroundColor.push(alpha(color, 0.7));

        mainDataset.data.push(0);
        mainDataset.data.push(0);
        mainDataset.data.push(total);
        
        mainDataset.backgroundColor.push(color);
        mainDataset.backgroundColor.push(color);
        mainDataset.backgroundColor.push(color);
        
        pieLabels.push(`${gender} (active) ${Math.round(dataset.active/total*100)}%`);
        pieLabels.push(`${gender} (passive) ${Math.round(dataset.passive/total*100)}%`);
        pieLabels.push(`${gender} ${Math.round(total/pieGrandTotal*100)}%`);
    }
    
    for(let age of ages) {
        let row = {};
        for(let gender of genderOrder) {
            const cell = find(data, item=>(item.age == age && item.gender == gender));
            row[gender.toLowerCase()] = cell ? cell.curr_units : 0;
        }
        row['age'] = age;
        tableData.push(row);
    }
    
    //console.log(tableData);
    
    /*
    const {dates, period} = dateRange(data);
    timelineDatasets = data.map(entry=>{
        let data = [];
        for(let date of dates) {
            let stms = find(entry.stms_by_date, {stream_date: date});
            data.push(stms ? stms.units : null);
        }    
            
        return {
            label: `${entry.gender} ${entry.age}`,
            data,
            fill: false
        }
    });
    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    */
    timelineLabels = timelineDatasets = [];

    

    
    return {chart: {labels: ages, datasets}, table: tableData, timeline: {labels: timelineLabels, datasets: timelineDatasets}, pie: {labels: pieLabels, datasets: [splitDataset, mainDataset]}};
}

function formatDemographicsTimeseries(data) {
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    let demos = uniq(map(data, 'demographic')),
        datasets = {},
        timelineLabels = [],
        timelineDatasets = [],        
        totalStreams = sumBy(data, 'curr_units');
    
    demos.sort();

    for(let demo of demos) {
        datasets[demo] = [];
    }
        
    const {dates, period} = dateRangeFlat(data);
    datasets = demos.map((demo, index)=>{
        let days = [];
        for(let date of dates) {
            let stream = find(data, {stream_date: date, demographic: demo});
            days.push(stream ? stream.curr_units : null);
        }    
            
        return {
            label: demo,
            data: days,
            fill: false
        }
    });
    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    return {labels: timelineLabels, datasets: datasets};
}


function formatDevices(data) {
    let labels = [],
        dataset = [],
        total = sumBy(data, 'curr_units');
    
    for(let entry of data) {
        entry.share = Math.round((entry.curr_units/total)*10000) / 100;
        
        labels.push(entry.device);
        dataset.push(entry.curr_units);
    }
    return {chart: {labels, datasets: [{ data:dataset, label: "Plays"}]}, table: data};    
}

function formatSources(data) {
    let labels = [],
        dataset = [],
        timelineLabels = [],
        timelineDatasets = [],
        total = 0;
    
    for(let entry of data) {
        labels.push(entry.source);
        dataset.push(entry.curr_units);
        total += entry.curr_units;
    }
    

    data = data.map(entry=>{
        //const days = entry.stms_by_date;
        entry.share = Math.round((entry.curr_units/total)*10000) / 100;
        //entry.growth = percentageValue(days[days.length - 1].units, days[0].units);
        return entry;
    })
    
    /*    
    const {dates, period} = dateRange(data);
    
    timelineDatasets = data.map(source=>{
        source.stms_by_date = source.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(source.stms_by_date, {stream_date: date});
            data.push(stms ? stms.units : null);
        }    

        return {
            label: source.source,
            data,
            fill: false
            }
    });    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    */
    
    timelineLabels = timelineDatasets = [];

    return {chart: {labels, datasets: [{ data:dataset, label: "Plays"}]}, table: data, timeline: {labels: timelineLabels, datasets: timelineDatasets}};    
}

function formatSourcesTimeseries(data) {
    let labels = [],
        dataset = [],
        timelineLabels = [],
        timelineDatasets = [],
        total = 0;
    
    for(let entry of data) {
        labels.push(entry.source);
        dataset.push(entry.curr_units);
        total += entry.curr_units;
        entry.stms_by_date = entry.totals;
    }
    
    data = data.map(entry=>{
        const days = entry.totals;
        entry.share = Math.round((entry.curr_units/total)*100);
        entry.growth = percentageValue(days[days.length - 1].units, days[0].units);
        return entry;
    })
    
    
    const {dates, period} = dateRange(data);
    
    timelineDatasets = data.map(source=>{
        source.stms_by_date = source.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(source.stms_by_date, {stream_date: date});
            data.push(stms ? stms.curr_units : null);
        }    

        return {
            label: source.source,
            data,
            fill: false
            }
    });    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));

    return {labels: timelineLabels, datasets: timelineDatasets};    
}

function formatDevicesTimeseries(data) {
    let labels = [],
        dataset = [],
        timelineLabels = [],
        timelineDatasets = [],
        total = 0;
    
    for(let entry of data) {
        labels.push(entry.device);
        dataset.push(entry.curr_units);
        total += entry.curr_units;
        entry.stms_by_date = entry.totals;
    }
    
    data = data.map(entry=>{
        const days = entry.totals;
        entry.share = Math.round((entry.curr_units/total)*100);
        entry.growth = percentageValue(days[days.length - 1].units, days[0].units);
        return entry;
    })
    
    
    const {dates, period} = dateRange(data);
    
    timelineDatasets = data.map(device=>{
        device.stms_by_date = device.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(device.stms_by_date, {stream_date: date});
            data.push(stms ? stms.curr_units : null);
        }    

        return {
            label: device.device,
            data,
            fill: false
            }
    });    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));

    return {labels: timelineLabels, datasets: timelineDatasets};    
}


function formatMultiMetadata(metadata) {
    let flatMetadata = [];
    // todo: add entity type
    for(let rows of metadata) {
        if(rows && Array.isArray(rows)) {
            for(let row of rows) {
                flatMetadata.push({...row});
            }
        }
    }
    console.log(flatMetadata);
    return flatMetadata;
}

function _formatMultiEntitiesStreams(streams, metadata) {
    let labels = [],
        datasets = [],
        contentTypes = [];
    
    if(streams) {
        const {dates, period} = dateRangeFlat(streams);
        const entities = uniq(map(streams, 'entity_id'));
        contentTypes = uniq(map(streams, 'cont_typ'));
        for(let entityIndex = 0; entityIndex < entities.length; entityIndex++) {
            const entity = entities[entityIndex];
            //let totalEntityDataset = [];
            let totalDataset = [];
            for(let contentType of contentTypes) {
                let data = [];
                for(let dateIndex in dates) {
                    let date = dates[dateIndex];              
                    let stream = find(streams, {stream_date: date, entity_id: entity, cont_typ: contentType});
                    data.push(stream ? stream.curr_units : null);
                    if(!totalDataset[dateIndex])
                        totalDataset[dateIndex] = 0;
                    totalDataset[dateIndex] += stream ? stream.curr_units : 0;
                }
                console.log(contentType);
                const contentTypeLabel = find(content_types, type=>type.value == contentType);
                console.log(contentTypeLabel);
                datasets.push({
                    id: `${entity}_${contentType}`,
                    label: find(metadata, item=>item.id == entity).name + ` (${find(metadata, item=>item.id == entity).entity.slice(0, -1)}) (${contentTypeLabel.label || 'N/A'})`, 
                    data,
                    entity,
                    contentType,
                    fill: false,
//                    borderColor: colors[entityIndex%colors.length],
//                    backgroundColor: colors[entityIndex%colors.length],
//                    pointBorderColor: colors[entityIndex%colors.length],
//                    pointBackgroundColor: colors[entityIndex%colors.length],                
                });
            }
            /*
            datasets.push({
                id: `${entity}_${0}`,
                label: find(metadata, item=>item.id == entity).name + ` (${find(metadata, item=>item.id == entity).entity.slice(0, -1)})`, 
                data: totalDataset,
                entity,
                contentType: 0,
                fill: false,
                borderColor: colors[entityIndex%colors.length],
                backgroundColor: colors[entityIndex%colors.length],
                pointBorderColor: colors[entityIndex%colors.length],
                pointBackgroundColor: colors[entityIndex%colors.length],                
            });
            */
            
            
        }
    
        /*
        datasets = entities.map((entity, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, entity_id: entity});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: entity,
                label: find(metadata, item=>item.id == entity).name + ` (${find(metadata, item=>item.id == entity).entity.slice(0, -1)})`, 
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });
        */    
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets, contentTypes };
}

function formatMultiEntitiesStreams(streams) {
    let labels = [],
        datasets = [],
        contentTypes = [],
        exportDataHeader = [],
        exportData = [];
    
    
    if(streams) {
        const {dates, period} = dateRange(streams);
        
        contentTypes = uniq(map(streams, 'cont_typ'));
        let totalDataset = [];
        for(let contentType of contentTypes) {
            let data = [];
            let stms = find(streams, stream=>stream.cont_typ == contentType).stms_by_date;
            for(let dateIndex in dates) {
                let date = dates[dateIndex];              
                let stream = find(stms, {stream_date: date});
                data.push(stream ? stream.curr_units : null);
                if(!totalDataset[dateIndex])
                    totalDataset[dateIndex] = 0;
                totalDataset[dateIndex] += stream ? stream.curr_units : 0;
            }
            const contentTypeLabel = find(content_types, type=>type.value == contentType);
            exportDataHeader.push(contentTypeLabel ? contentTypeLabel.label : 'N/A');
            datasets.push({
                id: contentType,
                label: contentTypeLabel ? contentTypeLabel.label : 'N/A', 
                data,
                contentType,
                fill: false,
//                    borderColor: colors[entityIndex%colors.length],
//                    backgroundColor: colors[entityIndex%colors.length],
//                    pointBorderColor: colors[entityIndex%colors.length],
//                    pointBackgroundColor: colors[entityIndex%colors.length],                
            });
        }
        /*
        datasets.push({
            id: 0,
            label: 'Total',
            data: totalDataset,
            contentType: 0,
            fill: false,
            borderColor: colors[datasets.length%colors.length],
            backgroundColor: colors[datasets.length%colors.length],
            pointBorderColor: colors[datasets.length%colors.length],
            pointBackgroundColor: colors[datasets.length%colors.length],                
        });
        */
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
        
        for(let labelId in labels) {
            let label = labels[labelId];
            let exportRow = {"Period":label};
            for(let datasetId in datasets) {
                const dataset = datasets[datasetId];
                //exportRow.push(dataset.data[labelId]);
                exportRow[exportDataHeader[datasetId]] = dataset.data[labelId];
            }
            exportData.push(exportRow);
        } 
    }
    return { labels, datasets, contentTypes, exportData };
}


function extractMinDate(entities, currentUser) {
    let entityReleaseDate = null;
    for(let entity of entities) {
        if(entity.releaseDate) {
            if(entityReleaseDate === null || new Date(entityReleaseDate) > new Date(entity.releaseDate))
                entityReleaseDate = entity.releaseDate;
        }
    }
    const userMinDate = currentUser.first_data_date;
    return new Date(userMinDate) > new Date(entityReleaseDate) ? userMinDate : entityReleaseDate;
}

function formatLocationStats(data) {
    const total = sumBy(data, 'streams_total');
    const totalListeners = sumBy(data, 'listeners');
    for(let item of data) {
        item.share = Math.round(item.streams_total / total * 100);
        let country = find(countriesByAbbreviation, {abbreviation: item.territory});
        if(country) 
            item.territory_name = country.country;
        if(item.listeners)
            item.listenersShare = Math.round(item.listeners / totalListeners * 100);
    }
    return data;
}

function formatTopLocationStats(data) {
    const countries = uniq(map(data, 'territory'));
    let result = [];
    for(let country of countries) {
        let cities = data.filter(item=>item.territory == country);
        let total = sumBy(cities, 'streams_total');
        for(let city of cities)
            city.share = Math.round(city.streams_total / total * 100);
        
        const countryName = find(countriesByAbbreviation, {abbreviation: country});
        result.push({[countryName? countryName.country : 'Unknown']: cities});
    }
    return result;
}