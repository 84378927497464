import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual, uniq, map } from 'lodash';
import { Link } from 'react-router-dom';

import { filterActions } from '../../../data/actions/filter';
import Tooltip from '../../common/Tooltip';

class MissingDataWarning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getStats();
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStats(false);
            }                
        }
    }
    
    renderVendor(vendor, logs) {
        let dates = uniq(map(logs.filter(log=>log.vendorTitle == vendor), 'report_date')).sort();
        return  <span className="table-header-tooltip inline-tooltip">
                <Tooltip 
                    position="right-bottom" 
                    message={`Missing date${dates.length>1?'s':''}: ${dates.join(', ')}`} 
                    tooltipClass="toolbar-title-tooltip sources-big-tooltip" 
                    noIcon={true}
                ><span className="tootlip-hover-text">{vendor}</span></Tooltip>
            </span>
    }


    render (){
        const {logs, logsLoading} = this.props.filter;
            
        if(!logsLoading && Array.isArray(logs)){
            if(!logs.length)
                return null;
            
            const vendors = uniq(map(logs, 'vendorTitle')).sort();
            let vendorsString = '';
            if(vendors.length == 1)
                vendorsString = this.renderVendor(vendors[0], logs);
            else
                vendorsString = map(vendors, vendor => this.renderVendor(vendor, logs)).reduce((prev, curr, index, logs) => { const separator = (index == logs.length - 1) ? ' and ' : ' '; return [prev, separator, curr]}); 
                //`${map(vendors.slice(0, vendors.length-1), vendor=>this.renderVendor(vendor, logs)).join(', ')} and ${vendors[vendors.length-1]}` 
            return <div className="missing-data-warning-holder">
                <p className="missing-data-warning">
                    <strong>Note: </strong>
                    Missing or partial data for this selection from <span className="missing-vendor-list">{vendorsString}</span> <Link className="more-info-link" to={'/data-status'}>More Info</Link>
                </p>
            </div>
        }
        return null;            
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps){
    return {
        getStats: ()=>dispatch(filterActions.getDataLogs())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MissingDataWarning)