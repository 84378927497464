import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { get, cloneDeep } from 'lodash';
import NotificationReport from '../../components/pages/notifications/Report';
import { notificationReportsActions } from '../../data/actions/notification_reports';
import { errorActions } from '../../data/actions/error';
import Spinner from '../../components/common/Spinner';
import MailingListsByRecipient from '../../components/pages/mailingLists/MailingListsByRecipient';

export class NotificationSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
                initialized: false
        }
        this.saveSettings = this.saveSettings.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
        this.onSettingsChanged = this.onSettingsChanged.bind(this);
        this.onFrequencyChanged = this.onFrequencyChanged.bind(this);
    }
    
    componentDidMount(){
        const { dispatch } = this.props;
        Promise.all([
             dispatch(notificationReportsActions.getUserNotificationSettings()),
             dispatch(notificationReportsActions.getNotificationReports()),
             dispatch(notificationReportsActions.getFrequencies())
        ]).then(([settings, reports, frequencies])=>{
            this.setState({
                initialized: true,
                settings, 
                reports,
                frequencies
            })
        });
    }
    
    updateSettings(report, key, value) {
        let settings = this.state.settings[report];
        if(!settings) {
            settings = {report_id: report, frequency: 3};
        }
        settings[key] = value;
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                [report]: settings
            }
        }, ()=>{
            console.log(this.state);
        })
    }
    onSettingsChanged(report, settings) {
        this.updateSettings(report, 'settings', settings);
    }
    
    onFrequencyChanged(report, frequency) {
        this.updateSettings(report, 'frequency', frequency);
    }
    
    saveSettings(){
        const { dispatch } = this.props,
            { settings: settingsDictionary } = this.state;
            
        let calls = [];
        for(let settings of Object.values(settingsDictionary)){
            if(!settings.settings)
                continue;
            
            if(!settings.id)
                calls.push(dispatch(notificationReportsActions.createUserNotification(settings)));
            else
                calls.push(dispatch(notificationReportsActions.updateUserNotification(settings)));    
        }
        Promise.all(calls).then(()=>{
            dispatch(notificationReportsActions.getUserNotificationSettings())
            .then(settings=>{
				dispatch(errorActions.setSuccessMessage('Notification Settings Saved'));
                this.setState({settings});
            });
        });
    }
    
    
    render(){
      const { initialized, reports = [], settings: settingsList = {} } = this.state;
      return(
          <div className="homepage">
              <div className="ibox spinner-absolute">
                <div className="NotificationReportHeader">
                  <h3> Set up the emails you will receive for different reports </h3>
                  <button type="submit" onClick={this.saveSettings} className="chat-submit"><i className="fas fa-save"></i></button>
                </div>
                {reports.map(report=>{
                    let settings = settingsList[report.id];
                    return <NotificationReport report={report} settings={settings} onSettingsChanged={(value)=>this.onSettingsChanged(report.id, value)} onFrequencyChanged={(value)=>this.onFrequencyChanged(report.id, value)} />
                })}
                <Spinner enabled={!initialized} />
              </div>
              <div>
                  <MailingListsByRecipient />
              </div>
              <Helmet>
                  <title>Notifications - User Preferences</title>
              </Helmet>
              
          </div>
      )
  }
};


function mapStateToProps( state ) {
  return {
    products: state.products.data,
    reports: state.notification_reports
  };
}

export default connect(mapStateToProps)(NotificationSettings);