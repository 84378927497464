import React from 'react';
import { Link } from 'react-router-dom';
import icons from '../../../helpers/Icons';

class DetailsLink extends React.Component {
    render() {
        const {entity, id, text, noIcon} = this.props;
        //(entity == 'imprints') ? null : 
        return (id ? <Link to={`/${entity}/${id}`} {...this.props}>
            {text}
            {!noIcon ? <span className="small-icon entities-icon" dangerouslySetInnerHTML={{__html: icons.entities[entity]}}></span> : null }
            </Link> : null);
    }
}

export default DetailsLink