import React from 'react';
import {Helmet} from 'react-helmet';
import Progress from '../common/Progress';
import NavigationMain from '../common/NavigationMain';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import ErrorBox from '../common/ErrorBox';
import WhiteLabelCss from '../common/WhiteLabelCss';
import Filter from '../widgets/filter/Filter';
import ChatWidget from '../widgets/chat/ChatWidget';
import ShareRoutes from '../../config/routes/share';
import LoginButton from '../pages/sharedPages/LoginButton';
import { withRouter } from 'react-router';
import { correctHeight, detectBody } from './Helpers';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';
import { filterActions } from '../../data/actions/filter';

import { connect } from 'react-redux';

class Share extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilterOpened: false,
            filterDataCount: null,
        }
    } 
    
    toggleFilterOpened = () => {
        this.setState({
            isFilterOpened: !this.state.isFilterOpened
        });        
    }

    getFilterCount = (filterCount) => {
        this.setState({
            filterDataCount: filterCount
        })
    }    
    
    render() {
        const { loggedIn } = this.props.user;
        const { pathname } = window.location;
        const isHeartbeatsShare = pathname.includes('/reporting/heartbeats') || pathname.includes('/reporting/nosedives');
        
        return (
            <div id="wrapper" className={`${this.props.silo} share-preview-page ${isHeartbeatsShare ? 'share-preview-heartbeats-page':''}`}>
                <ErrorBox />
                <Helmet defaultTitle="Enterlytics" titleTemplate="%s - Enterlytics" />
                {loggedIn && <Filter 
                    isFilterOpened={this.state.isFilterOpened}
                    toggleFilterOpened={this.toggleFilterOpened}
                    callBackFromMain={this.getFilterCount}
                    readonly={true}
                />}

                <LoginButton />
                <div id="page-wrapper" className={`page-wrapper admin-page-wrapper`}>
                        <div className="main-content-wrapper">
                            <ShareRoutes />
                        </div>
                </div>

            </div>

        )
    }

    componentDidMount() {
		
		this.props.dispatch(filterActions.setSharedMode());

        // Run correctHeight function on load and resize window event
        $(window).bind("load resize", function() {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        $('.metismenu a').click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300)
        });
    }
}

function mapStateToProps(state) {
    return { 
        user: state.user
    };
}


export default withRouter(connect(mapStateToProps)(Share))
