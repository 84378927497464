import React from 'react';
import ReactDOM from 'react-dom';
import { merge, map } from 'lodash';

import Optgroup from './Optgroup';
import Option from './Option';
import CountryFlag from '../CountryFlag';
import VendorLogo from '../VendorLogo';

class Multiselect extends React.Component {
    defaultOptions = {
    };
    
    constructor(props){
        super(props);
        this.state = {
            multiple: Array.isArray(props.selected)
        }

        this.isElementSelected = this.isElementSelected.bind(this);
        this.isGroupSelected = this.isGroupSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount(){
    }

    componentDidUpdate(prevProps) {
    }    
    
    getElementProps(element){
        if(element instanceof Object) // key-value hash
            return element;
        else // plain array, label equals value
            return {label: element, value: element}; 
    }

    isElementSelected(element){
        const { selected } = this.props;
        return this.state.multiple ? selected.includes(element) : selected == element
    }
    
    isGroupSelected(elements){
        const { selected } = this.props;
        let found = 0;
        if(selected.length) {
            for(let element of elements) {
                const { value } = this.getElementProps(element);
                if(selected.includes(value))
                    found++;
            }   
        }
        if(found == 0)
            return 'no'
        else if(found == elements.length)
            return 'yes';
        else 
            return 'partial';
    }
    
    selectAll(elements) {
        const { selected, readonly } = this.props;
        
        if(readonly)
            return;
        
        const groupElements = elements.map((e) => e.value);
        const isAllSelected = groupElements.every((e) =>
          selected.includes(e)
        );
        const difference = _.difference(selected, groupElements);
        const unique = _.uniq(selected.concat(groupElements));

        if (isAllSelected) {
            return this.props.handleChange(difference);
        }

        return this.props.handleChange(unique);
    }
    
    handleChange(value){
        let { selected, exclusiveValue = null, readonly = false } = this.props;

        if(readonly)
            return;
        
        if(this.state.multiple){
            if(selected.includes(value)){
                selected.splice(selected.indexOf(value), 1);            
            } else {
                selected.push(value);
            }
            
            if(exclusiveValue!==null) {
                if(exclusiveValue===value) {
                    if(selected.length)
                        selected=[exclusiveValue];
                } else {
                    const exclusiveValueFound = selected.indexOf(exclusiveValue);
                    
                    if(exclusiveValueFound!==-1)
                        selected.splice(exclusiveValueFound, 1);
                }
            }            
        }
        else
            selected = value;

            return this.props.handleChange(selected);            
    }

    renderElements(elements){
        const { isTerritory, isVendor, readonly = false, isDateRange, dateStartValue, dateEndValue } = this.props;

        return elements.map((element)=>{
            const { label, value, startDate, tooltip } = this.getElementProps(element);
            return <Option readonly={readonly} value={value} label={label} selected={this.isElementSelected(value)} handleChange={this.handleChange} type={this.state.multiple ? 'checkbox' : 'radio'} revertChildren={ isDateRange ? true : false } tooltip={tooltip} >
                {isTerritory && <span className="rounded-country-flag"><CountryFlag country={value} /></span>}
                {isVendor &&  <VendorLogo name={value.toLowerCase().replace(' ', '')} modificator="small" />}
                {isDateRange &&  startDate && <div className="preview-date-range" onClick={()=>this.handleChange(value)}>{startDate} - {dateEndValue}</div>}
            </Option>
        }); 
    }
    
    renderGroups(){
        
        let results = [],
            { elements, readonly = false } = this.props;
        // hash of arrays, for nested elements with optgroups
        if(elements instanceof Object && Object.values(elements)[0] instanceof Array) {
            for(let group in elements) {
                let options = this.renderElements(elements[group]);
                results.push(<Optgroup readonly={readonly} label={group} selected={this.isGroupSelected(elements[group])} selectAll={()=>this.selectAll(elements[group])}>{options}</Optgroup>)
            }
            
        }
        else
            results = this.renderElements(elements);

        return results; 
    }

    render() {
      return (
        <div className="form-control" >
            {this.renderGroups()}
        </div>
      );
    }
  }

  export default Multiselect;