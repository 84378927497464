import CSV from '../../../helpers/CSVExport';
import { find } from 'lodash';
import { globalFilterFormatter } from '../../../data/formatters/global_filter';

export default function exportToCsv(page, reduxProps, exportProgress){
    switch(page){
        case 'overview':
            return exportToCsvOverview(reduxProps, exportProgress);
        case 'audience':
            return exportToCsvAudience(reduxProps, exportProgress);
        case 'playlists':
            return exportToCsvPlaylists(reduxProps, exportProgress);
        case 'profile':
            return exportToCsvProfile(reduxProps, exportProgress);
        case 'social':
            return exportToCsvSocial(reduxProps, exportProgress);            

    }
} 

function renderSummary(filter) {
    let summary = globalFilterFormatter.formatFilterSummary(filter, 10);
    console.log(filter);
    let sheet = [{
        option: 'Date range',
        content: `${filter.dateStart} - ${filter.dateEnd}`,
    }, {
        option: 'Limit',
        content: filter.limit
    }];
    for(let row of summary) {
        const [option, content] = row.split(': ');
        sheet.push({option, content});
    }
    return sheet;
}

function exportToCsvOverview(reduxProps, exportProgress){
      const {
        artists,
        products,
        tracks,
        stats
      } = reduxProps;

      const data = [
        { summary: renderSummary(reduxProps.filter.global) },          
        { overview: stats.top },
        { units_timeseries: stats.multiStreams.exportData },
        { territories: stats.territory.table },
        { top_platforms: stats.vendor.table },
        { top_artists: artists.top },
        { top_products: products.top },
        { top_tracks: tracks.top }
      ];
      const filename = CSV.CSVHeader('overview', null, reduxProps.filter.global);
      exportProgress(true);
      const result = CSV.CSVBulkExport(data, filename);
      exportProgress(false);
      return result;
}

function exportToCsvAudience(reduxProps, exportProgress){
    const {
        audience,
        tracks,
        stats
      } = reduxProps;

      let data = [
        { summary: renderSummary(reduxProps.filter.global) },          
        { overview: stats.top },
        { audience_streams_listeners: audience.streams_listeners },
        { audience_repeat_listeners: audience.repeatListeners.table },
        { audience_territories: audience.territory.table },
        { audience_demographics: audience.demographics.table },
        { audience_sources: stats.sourcesStats.table },
        { audience_sources_discovery: stats.sourcesDiscoveryStats.table },
        { audience_devices: stats.devices.table }
      ];

      const filename = CSV.CSVHeader('audience', null, reduxProps.filter.global);
      exportProgress(true);
      const result = CSV.CSVBulkExport(data, filename);
      exportProgress(false);
      return result;

}

function exportToCsvPlaylists(reduxProps, exportProgress){
    const {
      playlists,
      stats
    } = reduxProps;

    const data = [
      { summary: renderSummary(reduxProps.filter.global) },        
      { overview: stats.top },                  
      { top_playlists: playlists.top },
      { territories: stats.territory.table }
    ];
    const filename = CSV.CSVHeader('playlists', null, reduxProps.filter.global);
    exportProgress(true);
    const result = CSV.CSVBulkExport(data, filename);
    exportProgress(false);
    return result;
    
}

function exportToCsvProfile(reduxProps, exportProgress){
    const {
      artists,
      milestones,
      stats
    } = reduxProps;

    const data = [
        { summary: renderSummary(reduxProps.filter.global) },        
        { overview: stats.top },                  
      // { socials: artists.socialItems },
        { milestones: milestones.entities['mixed'].tableItems }
    ];
    const filename = CSV.CSVHeader('profile', null, reduxProps.filter.global);
    exportProgress(true);
    const result = CSV.CSVBulkExport(data, filename);
    exportProgress(false);
    return result;
    
}


function exportToCsvSocial(reduxProps, exportProgress){
    const {
        audience,
        tracks
      } = reduxProps;

      let metrics = [];
      for(let track of tracks.tiktokMetrics.table) {
          let { vendors, ...rest } = track;
          let vendorRow = find(vendors, row=>row.vend_id == 0);
          let tableRow = {...rest, ...vendorRow};
          metrics.push(tableRow);
      }
      let territoriesViews = tracks.tiktokTerritories.result.filter(row => row.contentType == 'views');
      let territoriesCreates = tracks.tiktokTerritories.result.filter(row => row.contentType == 'creates');
          
      let data = [
        { summary: renderSummary(reduxProps.filter.global) }, 
        { social_metrics: metrics },
        { timeseries_views: tracks.tiktokTimeseries.exportData['views']},
        { timeseries_creates: tracks.tiktokTimeseries.exportData['creates']},
        { territories_views: territoriesViews },
        { territories_creates: territoriesCreates }
      ];


      const filename = CSV.CSVHeader('social music', null, reduxProps.filter.global);
      exportProgress(true);
      const result = CSV.CSVBulkExport(data, filename);
      exportProgress(false);
      return result;

}
