import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { find, cloneDeep, get, capitalize, map } from 'lodash';
import store from '../../helpers/store'
import ReactSelect from '../../components/common/ReactSelect';
import SearchWidget from '../../components/pages/home/SearchWidget';
import FilterList from '../../components/widgets/entityFilter/FilterList';
import EntitiesList from '../../components/widgets/entityFilter/EntitiesList';
import SampleCSV from '../../components/widgets/entityFilter/SampleCSV';
import CSVImportModal from '../../components/common/CSVImportModal';
import CSVSampleDownload from '../../components/common/CSVSampleDownload';
import FieldMapper from '../../components/widgets/entityFilter/FieldMapper';
import Spinner from '../../components/common/Spinner';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';
import MailingList from '../../components/pages/mailingLists/MailingList';

import { userEntityFiltersActions } from '../../data/actions/user_entity_filters';
import { searchActions } from '../../data/actions/search';
const allEntityTypes = ['imprints', 'artists', 'products', 'projects', 'tracks'];
const deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');
const editSvg = require('!svg-inline-loader!../../../public/img/icons/edit.svg');

class EntityFilterDetails extends React.Component {

    constructor(props){
        super(props);
        // const currentFilter = store.get('userEntityFilter') || null;
        this.state = {
            currentFilter: null,
            existingFilter: null,
            searchActive: false,
            entityType: null,
            entitySearchResults: null,
            showFieldMapper: false,
            importTarget: null,
            mailingListExpanded: true
        }
        this.setFilterState = this.setFilterState.bind(this);
        this.addNewFilter = this.addNewFilter.bind(this);
        this.addToFilter = this.addToFilter.bind(this);
        this.addAllToFilter = this.addAllToFilter.bind(this);
        this.bulkAddToFilter = this.bulkAddToFilter.bind(this);
        this.dummyBulkAddToFilter = this.dummyBulkAddToFilter.bind(this);
        this.addMappedResultsToFilter = this.addMappedResultsToFilter.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.selectFilterByID = this.selectFilterByID.bind(this);
        this.updateFilterField = this.updateFilterField.bind(this);
        this.removeFromFilter = this.removeFromFilter.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
        this.deleteFilter = this.deleteFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.addRecentFilter = this.addRecentFilter.bind(this);
        this.setSearchActive = this.setSearchActive.bind(this);
        this.goToDetails = this.goToDetails.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.setMailingListExpanded = this.setMailingListExpanded.bind(this);
    }
    
    componentDidMount(){
        const { match, dispatch } = this.props; 
        const { id } = match.params;
        let getFilterData, mode;
        if(id == 'new') {
            getFilterData = Promise.resolve(this.getNewFilter());
            mode = 'new';
        }
        else {
            getFilterData = dispatch(userEntityFiltersActions.getUserEntityFilter(id));
            mode = 'edit';
        }
        getFilterData.then(currentFilter=>{
            this.setState({currentFilter, mode, existingFilter: cloneDeep(currentFilter)});
        })
        
    }
    
    getNewFilter() {
        let entities = []
        if(this.props) {
            entities = get(this.props, 'location.state.defaultEntities', [])
        }
        return {caption: 'New Filter', entities};
    }
    
    addNewFilter() {
        const caption = prompt('New Filter Name')
        if(!caption)
            return;
        
        this.props.dispatch(userEntityFiltersActions.createFilter({caption}))
        .then(newFilter => {
            this.setState({
                ...this.state,
                currentFilter: {
                    ...newFilter,
                    entities: [
                       ...this.state.currentFilter.entities
                    ]
                }
            }, ()=>this.saveFilter())
        });
    }
    
    renderRecentFilters() {
        const { currentFilter, recentFilters } = this.state;
        return <FilterList currentFilter={currentFilter} filters={recentFilters} onSelect={this.selectFilterByID} />
    }
    
    renderPinnedFilters() {
        const { currentFilter } = this.state,
            { filters = [] } = this.props.userEntityFilters;
        const pinnedFilters = filters.filter(filter=>filter.is_pinned);
        return <FilterList currentFilter={currentFilter} filters={pinnedFilters} onSelect={this.selectFilterByID} />        
    }
    
    addRecentFilter(filter) {
        let recentFilters = store.get('recentEntityFilters') || [];
        const newFilter = {id: filter.id, caption: filter.caption};
        if(!find(recentFilters, filter=>filter.id == newFilter.id)) {
            recentFilters.unshift(newFilter);
            store.set('recentEntityFilters', recentFilters);
            return new Promise((resolve) => this.setState({recentFilters}, resolve));
        }
        else 
            return Promise.resolve();
    }
    
    setFilterState(currentFilter, callback) {
        //store.set('userEntityFilter', currentFilter);
        this.setState({
            ...this.state,
            currentFilter,
            searchActive: false
        }, callback);
    }
    
    selectFilter(currentFilter) {
        this.addRecentFilter(currentFilter)
        .then(()=>{
            this.setFilterState(currentFilter);
        });
    }
    
    selectFilterByID(id) {
        const { filters = [] } = this.props.userEntityFilters;
        const currentFilter = find(filters, filter=>filter.id == id);
        if(currentFilter)
            this.selectFilter(currentFilter);
    }
    
    addToFilter(items, include) {
        const { id: filterID, entities } = this.state.currentFilter;
        if(!Array.isArray(items))
            items = [items];
        let newEntities = cloneDeep(entities);
        for(let item of items) {
            const newFilterEntity = {
                user_filter_id: filterID,
                entity_id: item.id,
                entity_type: item.entity,
                entity_title: item.name_raw,
                entity_image: item.image,
                fav_hide: include ? 'favorite' : 'hidden'
            };
            newEntities = newEntities.filter(entity=>(!(entity.entity_id==newFilterEntity.entity_id && entity.entity_type==newFilterEntity.entity_type)));
            if(include !== undefined)
                newEntities.push(newFilterEntity);
        }
        this.setFilterState({
            ...this.state.currentFilter,
            entities: newEntities 
        });
    }
    
    addAllToFilter(items, include) {
        let newItems = [];
        for(let key of Object.keys(items)) {
            if(Array.isArray(items[key])) {
                newItems = newItems.concat(items[key])
            }
        }
        this.addToFilter(newItems, include);
    }
    
    bulkAddToFilter(fields) {
        if(!fields.length)
            return;
        
        let { target, source } = fields[0];
        if(source == 'ISRC')
            source = 'tracks';        
        if(source == 'UPC')
            source = 'products';
        if(source == 'Project')
            source = 'projects';
        
        const searchString = map(fields, 'field').join('\n');
        this.props.dispatch(searchActions.getSearchImport(searchString, [source], false))
        .then(results=>{
            this.setState({
                entitySearchResults: results,
                showFieldMapper: true,
                importTarget: target
            })
        });
    }
    
    dummyBulkAddToFilter() {
        const fields = [{
            'target': 'include',
            'source': 'artists',
            'field': 'Mitski'
        }, {
            'target': 'include',
            'source': 'artists',
            'field': 'Marco'            
        }];
        let { target, source } = fields[0];
        const searchString = map(fields, 'field').join('\n');
        this.props.dispatch(searchActions.getSearchImport(searchString, [source]))
        .then(results=>{
            this.setState({
                entitySearchResults: results,
                showFieldMapper: true,
                importTarget: target
            })
        });
    }
    
    addMappedResultsToFilter(results) {
        const { importTarget } = this.state;
        const filteredResults = results.filter(result=>(result.id !== null));
        this.addToFilter(filteredResults, (importTarget == 'include'));
        this.setState({
            entitySearchResults: null,
            showFieldMapper: false,
            importTarget: null
        })
    }
    
    removeFromFilter(id, type) {
        const { entities } = this.state.currentFilter;
        let newEntities = entities.filter(entity=>(!(entity.entity_id==id && entity.entity_type==type)));
        this.setFilterState({
            ...this.state.currentFilter,
            entities: newEntities 
        });
    }
    
    updateFilterField(field, value) {
        const { id } = this.state.currentFilter;
        this.setFilterState({
            ...this.state.currentFilter,
            [field]:value 
        }, ()=>{
            this.props.dispatch(userEntityFiltersActions.updateUserEntityFilter(id, {[field]:value}))    
        })
        
    }
    
    updateTitle() {
        const caption = prompt('Change Filter Name')
        if (!caption)
            return;

        this.updateFilterField('caption', caption)
    }
    
    saveFilter() {
        const { currentFilter, existingFilter } = this.state;
        const entitiesToAdd = currentFilter.entities.filter(entity=>!entity.id); 
        const entitiesToDelete = existingFilter.entities.filter(existingEntity => !find(currentFilter.entities, entity => entity.id == existingEntity.id));
        this.props.dispatch(userEntityFiltersActions.updateFilterEntities(currentFilter.id, entitiesToAdd, entitiesToDelete))
        //.then(currentFilter=>this.setFilterState(currentFilter));
        .then(this.props.history.push('/user/entities-filter'));
    }
    
    deleteFilter() {
        const { id } = this.state.currentFilter;
        this.props.dispatch(userEntityFiltersActions.destroyFilter(id))
        .then(this.setFilterState(null));
    }
    
    setSearchActive(searchActive) {
        this.setState({searchActive});
    }
    
    goToDetails() {
        const { entities } = this.state.currentFilter;
        let filter = {};
        for(let entity of entities){
            const { fav_hide, entity_type, entity_id } = entity;
            if(fav_hide == 'favorite') {
                if(!filter.hasOwnProperty(entity_type))
                    filter[entity_type] = [];
                filter[entity_type].push(entity_id);
            }
        }
        this.props.history.push(`/filter/${JSON.stringify(filter)}`);
    }
    
    resetFilter(){
        this.setFilterState(null, ()=>{
            this.props.applyFilter();
        })
    }
    
    renderFilterSelect() {
        const { currentFilter } = this.state,
            { loading, filters = [] } = this.props.userEntityFilters,
            filterOptions = filters.map(filter=>({label: filter.caption, value: filter})),
            selectedFilter = currentFilter ? find(filterOptions, option=>option.value.id == currentFilter.id) : null;
            
        return <ReactSelect
            loading
            value={selectedFilter}
            options={filterOptions}
            onChange={({value})=>this.selectFilter(value)}
            isLoading={loading}
            isSearchable={true}
            onFocus={()=>this.setSearchActive(true)}
        />
    }
    
    renderFilterEntities(entities, entityType) {
        if(!entities)
            return null;
        
        const entitiesInFilter = entities.length;
        const entityTypes = entityType ? [entityType] : allEntityTypes;
        return <div className="pinned-filters-holder filter-page">
            {entitiesInFilter == 0 && <p className="select-entity-text">Select entity from the search bar on the left to include or exclude it.</p>}
            {entitiesInFilter > 0 && ['favorite', 'hidden'].map(include=><div className="pinned-filters-item">
                <h4 className="pinned-filter-title inclusions">{include == "favorite" ? "Inclusions" : "Exclusions"}</h4>
                <div className="pinned-entity-list">
                    {entityTypes.map(type=><EntitiesList entities={entities} type={type} fav_hide={include} onDelete={this.removeFromFilter} />)}
                </div>
            </div>)}
        </div>
    }

    renderEntityTypes(selectedEntityType) {
        let options = [{value: null, label: 'All'}];
        for(let entityType of allEntityTypes) {
            options.push({value: entityType, label: capitalize(entityType)})
        }
        return <ul className="entity-filter-tabs">
            {options.map(option=><li key={option.value} className={`entity-filter-tab ${option.value == selectedEntityType ? 'active':''}`} onClick={()=>this.setEntityType(option.value)}>{option.label}</li>)}
        </ul>
    }            
    
    renderMailingList(filter) {
        const {mailingListExpanded} = this.state;
        return <div className="mailing-list-holder filter-notification-settings">
            {/*<p className={`mailing-list-toggle ${mailingListExpanded?'mailing-list-toggle-expanded':''}`} onClick={this.setMailingListExpanded}>Manage Notification Settings</p> */}
            {mailingListExpanded && <MailingList entityFilterId={filter.id} />}
        </div>
    }
            
    setEntityType(entityType) {
        this.setState({entityType});
    }
    
    setMailingListExpanded(mailingListExpanded) {
        this.setState({mailingListExpanded: !this.state.mailingListExpanded});
    }
    
    render() {
        const { currentFilter: filter , searchActive, mode, entityType, showFieldMapper, entitySearchResults }  = this.state;
        const { applyFilter, search: { importLoading } } = this.props;
        const onClickAction = (mode == 'new') ? this.addNewFilter : this.saveFilter;
        const filterCSVFields = [ 
             {key: 'field', required: true, label: 'Source Field'},
             {key: 'source', required: true, label: 'Import By', options: [{label:'ISRC', value:'ISRC'}, {label:'UPC', value:'UPC'}, {label:'Project Code', value:'Project'}, {label:'Imprint Title', value:'imprints'}, {label:'Artist Name', value:'artists'}, {label:'Product Title', value:'products'}, {label:'Project Title', value:'projects'}, {label:'Track Title', value:'tracks'}]},
             {key: 'target', required: true, label: 'Add To', options: [{label:'Inclusions', value:'include'}, {label:'Exclusions', value:'exclude'}]}
             
         ];

        return (
             <div className="user-entity-filter-holder create-filter">
                 {mode == 'edit' ? <h3 className="user-filter-title">Edit Saved Filter</h3> : <h3 className="user-filter-title">Add Saved Filter</h3>}
                 <FieldMapper results={entitySearchResults} show={showFieldMapper} mapResults={this.addMappedResultsToFilter}/>
                 {filter && <div className="user-entity-filter">
                     <div className="search-holder">
                         <p className="text">Search entities to include or exclude from filter</p>
                         <div className="add-filter-search-holder">
                             <SearchWidget searchResult={this.addToFilter} placeholder="Search imprints, artists, products, tracks..." mode="playlist" searchItemMode="include_exclude" batch={true} currentlySelectedItems={filter.entities} addAllResults={this.addAllToFilter} />
                             <Spinner enabled={importLoading} className="spinner spinner-inline"  />
                             <MatchMediaWrapper isMobile={false} breakpoint={639}>
                                <div className="search-holder-btns">
                                    <CSVImportModal fields={filterCSVFields} buttonClass="default-btn default-btn--small milestone-import-btn default-btn--light" modalTitle="Import To Filter" buttonTitle="Choose File" onSubmit={this.bulkAddToFilter} />                         
                                    <CSVSampleDownload fields={SampleCSV} linkClass="button-no-style download-scv" linkTitle="Download Sample" />
                                    {/* <button onClick={this.dummyBulkAddToFilter}>Demo Search</button> */}
                                </div>
                             </MatchMediaWrapper>
                         </div>
                     </div>
                    <div className="current-filter-holder"> 
                      <div className="current-filter-title-holder">
                        <h3 className="current-filter-title">{filter.caption}</h3>
                        {mode == 'edit' && <button className="update-filter-title" onClick={()=>{this.updateTitle()}}>
                          <span dangerouslySetInnerHTML={{__html: editSvg}}/>
                        </button>}
                      </div>
                      <div className="results-holder">
                         {this.renderEntityTypes(entityType)}
                         {this.renderFilterEntities(filter.entities, entityType)}
                         <div className="entity-filter-dropdown-list">
                            {mode == 'edit' && <button onClick={this.saveFilter} className="default-btn default-btn--small update-filter">Update Filter</button>}
                            <button onClick={this.addNewFilter} className="default-btn default-btn--small default-btn--light">Save As New Filter</button>
                            {mode == 'edit' && <div className="entity-filter-action"> 
                                <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder">
                                    <input type="checkbox" id="filter_is_shared" checked={filter.is_shared} onChange={()=>this.updateFilterField('is_shared', !filter.is_shared)} class="input" />
                                    <label htmlFor="filter_is_shared" className="checkbox-label checkbox-switcher">Share</label>
                                </div>
                                <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder">
                                    <input type="checkbox" id="filter_is_pinned" checked={filter.is_pinned} onChange={()=>this.updateFilterField('is_pinned', !filter.is_pinned)} class="input" />
                                    <label htmlFor="filter_is_pinned" className="checkbox-label checkbox-switcher">Pin</label>
                                </div>
                               <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder">
                                   <input type="checkbox" id="filter_is_hidden" checked={filter.is_hidden} onChange={()=>this.updateFilterField('is_hidden', !filter.is_hidden)} class="input" />
                                   <label htmlFor="filter_is_hidden" className="checkbox-label checkbox-switcher">Hide</label>
                               </div> 
                            </div>}
                         </div>
                         {mode == 'edit' && this.renderMailingList(filter)}
                         <div>
                            <button onClick={this.deleteFilter} className="remove-filter-link filter-reset-btn button-no-style">
                            <span className="tiny-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>Delete Filter</button>
                        </div>
                     </div>                    
                 </div>
                 </div>}          
            </div>   
        )
    }
}

function mapStateToProps(state) {
    return {
        userEntityFilters: state.userEntityFilters,
        search: state.search
    } 
}
export default connect(mapStateToProps)(withRouter(EntityFilterDetails));