import { map, concat, orderBy, uniq, cloneDeep } from 'lodash'
import { imprintsFormatter } from './imprints';
import { artistsFormatter } from './artists';
import { productsFormatter } from './products';
import { projectsFormatter } from './projects';
import { tracksFormatter } from './tracks';

const inputSeparator = new RegExp('[,\t]+');
const batchSeparator = '\n';
const patterns = [/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/ig, /[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}/g, /([0-9]){11,16}/g];

export const searchFormatter = {
    formatBatchSearch,
    formatImportSearch,
    formatAutocomplete,
    formatImport,
    addExactMatches,
    formatSearchResults
};

function _formatAutocomplete(data) {
    let {hits, total}= data.hits;
    return hits.map(hit=>{
        switch(hit._type) {
        case 'imprint':
            return imprintsFormatter.formatAutocompleteItem(hit);
        case 'artist':
            return artistsFormatter.formatAutocompleteItem(hit);
        case 'product':
            return productsFormatter.formatAutocompleteItem(hit);
        case 'track':
            return tracksFormatter.formatAutocompleteItem(hit);
        }
    });
}

function formatAutocomplete(origData, entities) {
    let results = {imprints: [], artists: [], projects: [], products: [], tracks: [], total: 0};
    let data;
    if(Array.isArray(origData)) {
        data = cloneDeep(results);
        for(let row of origData) {
            for(let entity of Object.keys(row.result)) {
                if(Array.isArray(row.result[entity])) {
                    data[entity] = [...data[entity], ...row.result[entity]];
                }
            }
        }
    }
    else {
        data = cloneDeep(origData);
    }
    
    for(let entity of entities) {
        if(data[entity]) {
            for(let entry of data[entity]) {
                const origEntry = Object.assign({}, entry);
                let weight = 1;
                switch(entity) {
                    case 'imprints':
                        entry = imprintsFormatter.formatAutocompleteItem(entry);
                        break;
                    case 'artists':
                        entry = artistsFormatter.formatAutocompleteItem(entry);
                        weight = 1.2;
                        break;
                    case 'products':
                        entry = productsFormatter.formatAutocompleteItem(entry);
                        weight = 1.1;
                        break;
                    case 'projects':
                        entry = projectsFormatter.formatAutocompleteItem(entry);
                        weight = 1.1;
                        break;                        
                    case 'tracks':
                        entry = tracksFormatter.formatAutocompleteItem(entry);
                        if(entry.code && entry.code.match(/^\d+$/))
                            continue;                        
                        break;
                }
                entry.rank = weight*origEntry.pg_search_rank;
                results[entity].push(entry);
                results.total ++;
            }
        }
    }
    for(let entity of Object.keys(results)) {
        if(Array.isArray(results[entity]))
            results[entity] = orderBy(results[entity], ['rank'], ['desc']);
    }
    results.totalNotMatched = results.total;
    return results;
}

function extractPatterns(query) {
    let matches = [];
    for(let pattern of patterns) {
        const found = query.match(pattern);
        if(found && found.length) {
            matches = matches.concat(found);
            query = query.replace(pattern, '');
        }
    }
    return {text: query, matches};
                   
}

function addExactMatches(data, query, mapNames) {
    //const queryParts = query.toLowerCase().split(inputSeparator).map(item=>item.trim());
    let { text, matches } = extractPatterns(query);
    let totalNotMatched = 0;
    const queryParts = map(matches, item=>item.trim().toLowerCase());
    const textParts = uniq(map(text.split(batchSeparator), word=>word.trim().toLowerCase()));
    for(let entity of Object.keys(data)) {
        if(Array.isArray(data[entity])) {
            for(let item of data[entity]) {
                let exactMatch = false;
                if(queryParts.includes(item.id))
                    exactMatch = true;
                if(item.code && queryParts.includes(item.code.toLowerCase()))
                    exactMatch = true;
                if(mapNames && textParts.includes(item.name_raw.toLowerCase()))
                    exactMatch = true;
                item.exactMatch = exactMatch;
                if(!exactMatch)
                    totalNotMatched++;
            }
        }
    }
    data.totalNotMatched = totalNotMatched;
    return data;
}

function formatBatchSearch(query) {
    let { text, matches } = extractPatterns(query);
    text = text.split(inputSeparator).map(word=>word.trim());//;
    text = text.filter(word=>word).concat(matches);
    return text.join(batchSeparator);
}

function formatImportSearch(query){
    const queryParts = query.split(batchSeparator).map(row=>row.trim());
    return queryParts.filter(row=>row).join(batchSeparator);
}

function formatImport(searchResults, entities, querySearch) {

    let results = [];
    let importIndex = 0;
    const querySearchArray = querySearch.split(batchSeparator);
    for(let searchResultIndex in searchResults) {
        let {exact_match, result} = searchResults[searchResultIndex];
        let formattedResults = [];
        for(let entity of entities) {
            if(result[entity]) {
                for(let entry of result[entity]) {
                    const origEntry = Object.assign({}, entry);
                    let weight = 1;
                    switch(entity) {
                        case 'imprints':
                            entry = imprintsFormatter.formatAutocompleteItem(entry);
                            break;
                        case 'artists':
                            entry = artistsFormatter.formatAutocompleteItem(entry);
                            weight = 1.2;
                            break;
                        case 'products':
                            entry = productsFormatter.formatAutocompleteItem(entry);
                            weight = 1.1;
                            break;
                        case 'projects':
                            entry = projectsFormatter.formatAutocompleteItem(entry);
                            weight = 1.1;
                            break;                            
                        case 'tracks':
                            entry = tracksFormatter.formatAutocompleteItem(entry);
                            if(entry.code && entry.code.match(/^\d+$/))
                                continue;
                            break;
                    }
                    entry.rank = weight*origEntry.pg_search_rank;
					entry.query = querySearchArray[searchResultIndex];
                    formattedResults.push(entry);
                }
            }
        }
        if(!formattedResults.length)
            exact_match = false;
        
        if(!exact_match) {
            formattedResults.unshift({
                id: null,
                entity: null,
                name: '(skip import)',
                name_raw: '(skip import)',
                rank: 1
            })
        }
        results.push({exactMatch: exact_match, query: querySearchArray[searchResultIndex], result: formattedResults, importIndex, multipleMatches: (exact_match ? (formattedResults.length) : 0)});
        importIndex++;
    }
    return results;
}

function formatSearchResults(results){
    return results.map(result=>{
        const searchArray = result.search_input ? result.search_input.split(batchSeparator) : [];
        result.total = searchArray.length;
        result.title = searchArray.slice(0, 2).join(',') + '...';
        return result;
    })
    
}