import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual, map } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import moment from 'moment';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { tracksActions } from '../../../data/actions/tracks';
import { productsActions } from '../../../data/actions/products';
import { territorySettings } from './Territory/Settings';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import { BarChart } from '../../widgets/charts/types';
import { convertToThousands, splitByThousands } from '../../../helpers/NumberFormatter';
import { callback } from 'chart.js/helpers';
const CellHeaderTable = cellHeaderHOC(ReactTable);
const backgroundColor = [
	{ color: "#4c73bf", key: "curr_units" }, 
	{ color: "#b22d18", key: "skipped_ratio" }, 
	{ color: "#e18243", key: "collection_ratio" }, 
	{ color: "#80aa55", key: "engagement_ratio" }, 
	{ color: "#aeabaa", key: "playlist_ratio"}
]

class TracksInProductStats extends React.Component {
	
	constructor(props) {
		super(props);
		this.exportToCsv = this.exportToCsv.bind(this);        
	}
	
	exportToCsv(){
		const { entity, id }  = this.props,
			tableData = this.props.tracks.productTracksComparison[id].data;
		const title = 'tracks-in-product-comparison';
		const filename = CSV.CSVHeader(title, id);
		return CSV.CSVExport(tableData, {filename}, title);
	}

	renderToolbar(){
		let toolbar = [];
		toolbar.push(<div key="wrapper" className="ibox-action-holder">
		<div className="ibox-actions">
		  <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		</div>
		</div>)
		return toolbar;
	}



	componentDidMount() {
		const { id, weeks, metadata , territories, vendors} = this.props;
		this.props.getOrderedTracks(id).then(({tracks, releaseDate}) => {
			return this.props.compareTracks(id, tracks, weeks, releaseDate, territories, vendors);
		})
		
	}
	
	componentWillReceiveProps(nextProps) {
	}

	renderExportToCsv() {
		return (
			<a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		)
	}

	renderTableTitle(key) {
		switch (key) {
			case "curr_units": 
				return "Total Streams"
			case "skipped_ratio":
				return "Skip Rate %"
			case "collection_ratio":
				return "Collection %"
			case "engagement_ratio":
				return "Engagement Rate %"
			case "playlist_ratio":
				return "Playlist %"
		}
	} 

	stepSize(maxValue) {
		// let stepSize;

		if (maxValue <= 10) {
			return
		}	else if (maxValue > 10 && maxValue <= 100) {
			return Math.round(maxValue / 5);
		} else if (maxValue > 100) {
			return maxValue / 6;
		}
	}
	
	render (){
		const { orderedTracksLoading, orderedTracks } = this.props.products,
			{ productTracksComparisonLoading, productTracksComparison } = this.props.tracks,
			{ id, chartID } = this.props;
		const loading = orderedTracksLoading || productTracksComparisonLoading;
		const dataReady = !loading && productTracksComparison[id] !== undefined;
		let chartData = {};
		let titles = {};
		if(dataReady) {
			const data = productTracksComparison[id];
			for(let datasetName of Object.keys(data.datasets)) {
				const backgroundItem = backgroundColor.find(item => item.key === datasetName);
				const color = backgroundItem ? backgroundItem.color : "#D400D4";
				const maxValue = Math.max(...data.datasets[datasetName])

				chartData[datasetName] = {
					labels: data.labels,
					datasets: [{data: data.datasets[datasetName], label: '', backgroundColor: color }],
					maxValue: maxValue
				}
			}

			titles = data.labels.map((item, index) => {
				return <span key={index}>{`${item} - ${data.titles[index]}`}</span>
			})
		}
		let widgetTitle = '';
		if(this.props.metadata && this.props.metadata.name_compare)
			widgetTitle = this.props.metadata.name_compare; 
		return <Box title={widgetTitle} spinnerEnabled={loading} exportToCsv={this.renderExportToCsv()}>
			<div className='charts-holder'>
				{Object.keys(chartData).map((key, i)=><div id={key} className='chart-item'>
					<h4 className="tacks-in-products-title">{this.renderTableTitle(key)}</h4>
					<div className='chart-block'>
						<BarChart data={chartData[key]} options={{
							indexAxis: 'y',
							plugins: {
								legend: {
									display: false
								},
								tooltip: {
									displayColors: false,
									callbacks: {
										label: function(context) {
											return key == 'curr_units' ? context.formattedValue : (context.formattedValue + "%")
										}
									}
								}
							},
							scales: {
								x: {
									max: chartData[key].maxValue,
									ticks: {
										callback: function formatAxesLabel(value, index, values){
											if (value >= 1000) {
												return convertToThousands(value)
											}
	
											return value
										},
										stepSize: this.stepSize(chartData[key].maxValue)
									}
								},
							},
							onResize: function(chart, size) {
								if (window.matchMedia("(max-width: 1023px)").matches) {
									chart.options.scales.y.ticks.callback = function(value, index, values) {
										return chartData[key].labels[index];
									}
								} else {
									chart.options.scales.y.ticks.callback = function(value, index, values) {
										return i === 0 ? chartData[key].labels[index] : "";
									}
								}

								chart.update();
							}
						}} id={`${chartID}_${key}`} shadowChart={true} shadowChartWidth={400} shadowChartHeight={650}  />
					</div>
				</div>)}
			</div>
			<div className="tacks-in-products-legend">
				{dataReady && titles}
			</div>
		</Box>
	}
}

function mapStateToProps(state) {
	return {
		tracks: state.tracks,
		products: state.products,
		filter: state.filter,
		userData: state.user
	} 
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		
		getOrderedTracks: (id) => {
			return dispatch(productsActions.getOrderedTracks(id));
		},
		compareTracks: (productId, tracks, weeks, releaseDate, territories, vendors) => {
			return dispatch(tracksActions.compareProductTracks(productId, tracks, weeks, releaseDate, territories, vendors));
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(TracksInProductStats);