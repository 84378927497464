import React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import Spinner from '../../common/Spinner';
import { statsActions } from '../../../data/actions/stats';
import { milestonesActions } from '../../../data/actions/milestones';

class DataProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                dataReady: false
        }
    }

    
    componentDidMount() {
        const { filtered } = this.props;
        Promise.all([
         this.props.getEntitiesMetadata(filtered),
         this.props.getEntitiesMilestones(filtered)
         ]).then((data)=>{
             this.setState({dataReady: true})
         });
    }
        
    componentWillReceiveProps(nextProps){
        if(nextProps.filtered && !isEqual(nextProps.filtered, this.props.filtered)){
            const { filtered } = nextProps;
            this.setState({dataReady: false}, ()=>{
                Promise.all([
                 this.props.getEntitiesMetadata(filtered),
                this.props.getEntitiesMilestones(filtered)
                 ]).then((data)=>{                     
                     this.setState({dataReady: true})
                 });
            })
        }
    }
    
    componentWillUnmount() {
        this.props.resetMinDate();
    }
    
    render (){  
//        const { multiMetadata = [], multiMetadataLoading } = this.props.stats;
//        const { items: milestones = [], loading: milestonesLoading } = this.props.milestones.entities.mixed;
        
//        console.log(multiMetadataLoading, milestonesLoading);
        //const dataReady = (multiMetadataLoading === false && milestonesLoading === false);
        const { dataReady } = this.state;
        return dataReady ? <React.Fragment>{this.props.children}</React.Fragment> : <Spinner enabled={true} className="spinner spinner-inline" />
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        milestones: state.milestones,
    } 
}


function mapDispatchToProps(dispatch, ownProps) {
    return {
        getEntitiesMetadata: (filtered) => {
            //const { filtered = [] } = ownProps; 
            return dispatch(statsActions.getMultiEntitiesMetadata(filtered));
        },
        getEntitiesMilestones: (filtered) => {
            //const { filtered = [] } = ownProps;
            return dispatch(milestonesActions.getEntityMilestones('mixed', null, filtered, true));    
        },
        resetMinDate: () => {
            return dispatch(statsActions.resetMinDate());   
        }
        
    } 
}
export default connect(mapStateToProps, mapDispatchToProps)(DataProvider);