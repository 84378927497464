import React, { KeyboardEventHandler } from 'react';
import CreatableSelect from 'react-select/creatable';
import { uniqBy } from 'lodash';

class RecipientsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                inputValue: ''
        }
    }
    
    componentDidMount() {
    }
    
    handleKeyDown = (event) => {
        const { inputValue } = this.state;
        let { value } = this.props;
        if (!inputValue) return;
        switch (event.key) {
          case 'Enter':
          case 'Tab':
              const newItem = this.createOption(inputValue);
              if(newItem) {
                  value = [...value, newItem];
                  this.setValue(value);
              }
			  else {
				  alert("Please add a valid email address.");
			  }
              this.setState({
                  inputValue: ''
              });
              event.preventDefault();
        }
      };
	  
	  handlePaste = (event) => {
		  const inputValues = event.clipboardData.getData('Text');
		  if(!inputValues)
		  	return;
			  
		  let { value } = this.props;
		  let newValues = []

		  for(let inputValue of inputValues.split(',')) {
			const newItem = this.createOption(inputValue);
	  		if(newItem) {
				newValues.push(newItem);
			}
		  }
		  
		  if(newValues.length) {
			  value = [...value, ...newValues];
			  value = uniqBy(value, 'value');
			  this.setValue(value);
		  }
		  this.setState({
		  	inputValue: ''
	  	  });
		event.preventDefault();			  
		  
	  }
      
      createOption = (label) => {
		  label = label.trim();
          const email = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;
          if(!label.match(email)) {
              return false;
          }
          
          return {
              label,
              value: label
          }
      }
      
      setValue = (value) => this.props.setValue(value);
      setInputValue = (inputValue) => this.setState({inputValue});

      
      render(){
          const components = {
              DropdownIndicator: null,
          };
          const { inputValue } = this.state;
          const { value } = this.props;

          return (
			  <p onPaste={this.handlePaste}>
                  <CreatableSelect
                  id="mailing_list_recipients" 
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => this.setValue(newValue)}
                    onInputChange={(newValue) => this.setInputValue(newValue)}
                    onKeyDown={this.handleKeyDown}
                    placeholder="Add email and press enter..."
                    value={value}
                  />
			  </p>
                );

      }
}

export default RecipientsList;