import React, { Component } from 'react';
import ReactTable from "react-table";
import {Helmet} from 'react-helmet';
import moment from 'moment';
import config from '../config/config';
import cellHeaderHOC from '../components/common/Datagrid/CellHeaderHOC';
import Modal from '../components/common/Modal'
import VendorLogo from '../components/common/VendorLogo'
import { connect } from 'react-redux';
import ReactSelect from '../components/common/ReactSelect';
import { withRouter } from 'react-router';
import { isEqual, find, uniqBy } from 'lodash';
import MatchMediaWrapper from '../helpers/MatchMediaWrapper';

import { missingDataSettings } from '../components/widgets/missingData/Settings';
import { filterActions } from '../data/actions/filter';

const CellHeaderTable = cellHeaderHOC(ReactTable);

const getWeekNumber = (date) => {
    const currentDate = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
  
    // Find the Thursday in this week
    currentDate.setDate(currentDate.getDate() + 3 - ((currentDate.getDay() + 6) % 7));
  
    // January 4th is always in week 1
    const week1 = new Date(currentDate.getFullYear(), 0, 4);
  
    // Calculate the difference in weeks between the current date and week 1
    return Math.ceil((((currentDate - week1) / 86400000) + 1) / 7);
  };

class MissingData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showApproveModal: false,
            showRevokeModal: false,            
            currentVendor: null,
            
        }
        this.dispatchLoadData = this.dispatchLoadData.bind(this);
        this.getWeeks = this.getWeeks.bind(this);
        this.setWeek = this.setWeek.bind(this);
    }
    

    componentDidMount() {
        let weeks = this.getWeeks(this.props.filter);
        let selectedWeek = weeks[0];
        this.setState({
            weeks,
            selectedWeek
        }, ()=>{
            this.dispatchLoadData(this.state);
        })
    }
    
    getWeeks(filter){
        const { prevDateStart, dateEnd } = filter.global;
        let start = moment(prevDateStart), end = moment(dateEnd);
        let weeks = [];
        do {
            let week = {
                    end: end.format('YYYY-MM-DD'),
                    start: end.subtract(6, 'days').format('YYYY-MM-DD')
            }
            weeks.push(week);
            end = end.subtract(1, 'days');
        } while ( end >= start);
        return weeks;
    }
    
    setWeek({value}){
        this.setState({selectedWeek: value}, ()=>{
            this.dispatchLoadData(this.state);
        })
    }

    
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                let weeks = this.getWeeks(nextProps.filter);
                let selectedWeek = weeks[0];
                this.setState({
                    weeks,
                    selectedWeek
                }, ()=>{
                    this.dispatchLoadData(this.state);
                });
            }                
        }
    }
    
    dispatchLoadData(state){
        const { dispatch } = this.props;
        this.props.getStats(state);
    }
    
    renderWeeks() {
        const { weeks, selectedWeek } = this.state;
        if(!weeks)
            return;

        const weeksOptions = weeks.map(week=>({label: `#${getWeekNumber(week.start)}: ${week.start} - ${week.end}`, value: week}));
        const weekValue = find(weeksOptions, week=>isEqual(week.value, selectedWeek));
        return <React.Fragment>
            <MatchMediaWrapper isMobile={true} breakpoint={1023}>
                <ReactSelect options={weeksOptions} value={weekValue} onChange={this.setWeek} />
            </MatchMediaWrapper>
            <MatchMediaWrapper isMobile={false} breakpoint={1023}>
                <ReactSelect options={weeksOptions} value={weekValue} onChange={this.setWeek} menuIsOpen={true} className="inline-select" />
            </MatchMediaWrapper>
        </React.Fragment> 
    }

    render() {
        const {fullLogs: logs, dates = [], logsLoading, global, messages} = this.props.filter;
        const tableRows = (logs && logs.length) ? uniqBy(logs, 'vendorGroup').length : 10;
        
        console.log(tableRows);
        
        return <div className="statistics-main-holder">
            <h2 className="audience">
                <span>Data Status</span>
            </h2>        
            <div className="">
                <div className="data-table-legend">
                    <ul className="legend-list">
                        <li className="legend-item">
                            <div className="legend-mark average"></div>
                            <span className="text">Completed</span>
                        </li>
                        <li className="legend-item">
                            <div className="legend-mark no-data"></div>
                            <span className="text">Undelivered</span>
                        </li>
                        <li className="legend-item">
                            <div className="legend-mark below-average"></div>
                            <span className="text">Pending Collection</span>
                        </li>
                    </ul>
                </div>
                <div className="data-admin-table-holder">
                    <div className="weeks-select">
                        {this.renderWeeks()}
                    </div>
                    <ReactTable
                        loading={logsLoading}
						manual
						pageSize={tableRows}
                        className='catalogue-table-holder data-admin-table simple-table'
                        data={logs}
                        showPageSizeOptions={false}
                        columns={missingDataSettings.settingsForTable(dates, messages)}
                        showPagination={false}
                        pivotBy={['vendorGroup']}
                    />
                </div>
            </div>
            <Helmet>
                <title>Data Status</title>
            </Helmet>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        vendors: state.vendors,
        filter: state.filter
    }
}

function mapDispatchToProps(dispatch, ownProps){
    return {
        getStats: ({selectedWeek})=>dispatch(filterActions.getDataLogs(true, selectedWeek.start, selectedWeek.end))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MissingData))
