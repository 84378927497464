import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import VendorLogo from '../../common/VendorLogo';
import Tooltip from '../../common/Tooltip';

export const missingDataSettings = {
    settingsForTable
};
function normalizeCollector(collector) {
	switch(collector) {
		case 'amazon-downloads':
		return 'Downloads';
		case 'amazon—prime':
		return 'Prime Music';
		case 'amazon-unlimited':
		return 'Unlimited';
		case 'amazon-freetier':
		return 'Free Tier';
		case 'apple_music':
		return 'Streams';
		case 'apple_audience':
		return 'Audience';
		case 'apple_containers':
		return 'Products';
		case 'apple_saves':
		return 'Saves';
		case 'apple_sub30':
		return 'Sub 30 Streams';
		case 'facebook-consumption':
		return 'Social Views';
		case 'facebook-production':
		return 'Social Creates';
		case 'spotify_ccpa':
		return 'Streams';
		case 'spotify_saves':
		return 'Saves';
		case 'spotify_sub30_ccpa':
		return 'Sub 30 Streams';
		case 'tiktok':
		return 'Social Views & Creates';
		case 'tiktok_saves':
		return 'Saves';
		case 'youtube':
		return 'Video Views';
		case 'youtube_playlists':
		return 'Playlist Streams';
		default:
		return collector.split(/[-_]/).map(capitalize).join(' ');
	}
	
}




function settingsForTable(dates=[], messages = {}){
    const ratioThreshold = 0.3;
    
    let columns = [
        {
            id: 'vendorGroup',
            className: 'logo-sell',
            headerClassName: 'logo-sell no-sort',
            Header: '',
            width: 20,
            accessor: 'vendorGroup',
            // aggregate: (values, rows) => {
            //     return values[0];
            // },
            // Aggregated: row => {
            //     console.log('row');
            //     console.log(row);
            //       return <VendorLogo name={row.row.vendorGroup} modificator="small" /> ;
            //   }
            Pivot: ({ isExpanded, ...rest }) => (
                 <div>
                   {isExpanded ? (
                     <span>&#x25BC;</span>
                   ) : (
                     <span>&#x25B6;</span>
                   )}
                 </div>
               ),
        },
            
          {
              id: 'vendor',
              className: 'logo-sell',
              headerClassName: 'logo-sell no-sort',
              Header: 'Vendor',
              accessor: 'vendor',
              width: 180,
              sortable: false,
              Cell: props => {
                 return <span className="vendor-name-left">{normalizeCollector(props.value)}</span> ;
              },

              Aggregated: row => {
                  return <VendorLogo name={row.row.vendorGroup} modificator="small" /> ;
              }

            },
          /*
       {
           id: 'silo',
           Header: 'Silo Code',
           accessor: 'silo',
        //    width: 60,
       }
       */
    ];
    
    for(let date of dates) {
        columns.push({
            id: date,
            Header: date,
            accessor: date,
            className: 'data-admin-cell',
            sortable: false,
            Cell: props => {
                const status = props.value;
                let cellClassName = 'average';
                let tooltip = '';
                if( !status )
                    cellClassName = 'no-data';
                else if( status != 750 ) {
                    cellClassName = 'below-average';
                    tooltip = messages[status];
                }
                
                if(tooltip){
                    return <span title={tooltip} className={`${cellClassName}`}>
                    </span>
                }
                else {
                    return  <span className={cellClassName}></span>;
                }
                
            },
            Aggregated: row => {
                let { column, subRows } = row;
                let day = column.Header;
                let aggregatedStatus = 750;
                for(let subRow of subRows) {
                    const status = subRow[day];
                    if(!status || status != 750)
                        aggregatedStatus = status;
                }
                
                let cellClassName = 'average';
                let tooltip = '';
                if( !aggregatedStatus )
                    cellClassName = 'no-data';
                else if( aggregatedStatus != 750 ) {
                    cellClassName = 'below-average';
                    tooltip = messages[aggregatedStatus];
                }

                
                if(tooltip){
                    return <span title={tooltip} className={`${cellClassName}`}>
                    </span>
                }
                else {
                    return  <span className={cellClassName}></span>;
                }
            }
            
        });
    }
    return columns;
}