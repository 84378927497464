import React from 'react';
import { capitalize } from 'lodash';
import ReactTooltip from 'react-tooltip';

class FilterContents extends React.Component {

	constructor(props){
		super(props);
	}

	render() {
		let { filter } = this.props;
		let summary = {};
		let entities = {};
		for(let entity of filter.entities) {
			
			if(!summary.hasOwnProperty(entity.entity_type))
				summary[entity.entity_type] = 0;
			summary[entity.entity_type]++;
			
			if(!entities.hasOwnProperty(entity.entity_type))
				entities[entity.entity_type] = [];
			entities[entity.entity_type].push(entity.entity_title)
		}

		return <ul className="filter-summary">
				{Object.keys(summary).map(entityType=><li key={entityType} data-tip data-for={`${filter.id}${entityType}Tooltip`}>
					{capitalize(entityType)}: {summary[entityType]}
					<ReactTooltip id={`${filter.id}${entityType}Tooltip`} effect="solid" className="favorite-tooltip" place="bottom">
						{entities[entityType].map(entity=><p key={entity}>{entity}</p>)}
					</ReactTooltip>
				</li>)}
			</ul>;
	}
}

export default FilterContents;