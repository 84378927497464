import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { get, cloneDeep, find } from 'lodash';

import { notificationReportsActions } from '../../data/actions/notification_reports';
import { productsActions } from '../../data/actions/products';
import NotificationReport from './NotificationReport';
import Spinner from '../../components/common/Spinner';

const PERMIT_FIELDS = ['id', 'report_id', 'status', 'frequency', 'settings'];
const SEPARATOR = ',';
const KEYS_OF_SETTINGS = {1: 'release_weeks', 2: 'threshold', 3: 'avg_streams', 5: 'threshold'}; //['release_weeks', 'threshold', 'avg_streams', 'threshold']; 
const HIDDEN_REPORTS = ["Track Activity"];
export class NotificationSettings extends React.Component {
  state = {
    reports: [],
    submitedData: {release_weeks: {}, threshold: {}, avg_streams: {}},
    productsList: [],
    showDropdown: false,
    inputValue: ''
  }

  componentDidMount(){
    const {
      getNotificationReports,
      getUserNotificationSettings,
      getFrequencies,
    } = this.props;

    getUserNotificationSettings();
    getNotificationReports();
    getFrequencies();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      createUserNotification,
      updateUserNotification,
      getUserNotificationSettings,
      getNotificationReports
    } = this.props;

    let calls = [];
    Object.keys(this.state.submitedData).forEach( notification => {
        const notificationData = this.state.submitedData[notification];
        if(!notificationData.report_id)
            return;
        
        if(this.state.submitedData[notification].id !== undefined) 
            calls.push(updateUserNotification(notificationData));
        else
            calls.push(createUserNotification(notificationData));
    });
    Promise.all(calls).then(()=>getUserNotificationSettings());
  }

  formatSetting = setting => {
    let formatedSetting = Object.assign({}, setting);
    Object.keys(formatedSetting).forEach( key => {
      if(!PERMIT_FIELDS.includes(key)){
        delete formatedSetting[key];
      }
    })
    return formatedSetting;
  }

  assignNewSettingValue = (setting, entityValue, entityName, settingName=null) => {
    let updatedSetting = Object.assign({}, setting);
    if(settingName){
      updatedSetting[entityName][settingName] = parseInt(entityValue);
    } else {
      updatedSetting[entityName] = parseInt(entityValue);
    }
    return updatedSetting;
  }

  handleChangeSetting = (e, setting, settingName, entityName) => {
    let submittedDataKey = e.target.name;
    if(submittedDataKey == 'minimum_streams')
      submittedDataKey = 'threshold';
      
    let currentSetting = cloneDeep(setting);
    currentSetting = this.formatSetting(currentSetting);
    
    const prevState = cloneDeep(this.state);
    const submittedValue = get(prevState, `submitedData.${submittedDataKey}`);
    if(submittedValue && submittedValue.hasOwnProperty('settings')) {
      currentSetting.settings = submittedValue.settings;
      currentSetting.frequency = submittedValue.frequency;
    }
        
    
    currentSetting = this.assignNewSettingValue(currentSetting, e.target.value, entityName, settingName);
    
    const submitedData = Object.assign(this.state.submitedData, { [submittedDataKey]: currentSetting });
    this.setState({
      submitedData
    });
  }
  
  getDefaultSetting = (reportId) => {
      const { data: reports } = this.props.reports;
      const settings = [2,5].includes(reportId) ? {threshold: 50, minimum_streams: 1000} : {[KEYS_OF_SETTINGS[reportId]]: null};
      //const report = reports[index];
      return {
          report_id: reportId,
          frequency: 3,
          settings,
          status: false
      };
  }

  findSetting = (report, i) => {
    let { settings = [] } = this.props.reports;
    /*
    const savedSetting = settings.find(setting => {
      let key = Object.keys(setting.settings).reverse()[0];
      if(key=='minimum_streams')
          key = 'threshold';
      return  key === KEYS_OF_SETTINGS[i]
    })
    */
    const savedSetting = find(settings, setting=>setting.report_id == report.id);
    console.log(savedSetting);
    return savedSetting || this.getDefaultSetting(report.id);
  }

  onClickItem = (e, item, entity, setting, reportName) => {
    this.setState({
      showDropdown: false,
      inputValue: ''
    });

    const entityName = `${entity}List`;
    const currentCollection = this.state[entityName].length !== 0 ? this.state[entityName] : [];
    const dublicateItem = currentCollection.some( entity => entity.id === item.id);

    if(dublicateItem){
      return
    }

    let currentSetting = Object.assign({}, setting);
    const prevState = cloneDeep(this.state);
    const submittedValue = get(prevState, `submitedData.avg_streams`);
    if(submittedValue && submittedValue.hasOwnProperty('settings')) {
      currentSetting.settings = submittedValue.settings;
      currentSetting.frequency = submittedValue.frequency;
    }

    currentSetting = this.formatSetting(currentSetting);
    if(currentSetting.settings[entity])
      currentSetting.settings[entity] = currentSetting.settings[entity].split(SEPARATOR);
    else
      currentSetting.settings[entity] = [];
            
    currentSetting.settings[entity].push(item.barcode);
    currentSetting.settings[entity] = currentSetting.settings[entity].join(SEPARATOR);

    const submitedData = Object.assign(this.state.submitedData, { [`${reportName}`]: currentSetting });

    const { updateUserNotification, getUserNotificationSettings, getProducts } = this.props;

    updateUserNotification(submitedData[reportName], getProducts(currentSetting.settings[entity]));
  }

  handleCloseSearch = (e) => {
    this.setState({
      showDropdown: false,
      inputValue: ''
    })
  }

  removeItem = (e, item, entity, setting, reportName) => {
    this.setState({showDropdown: false});

    let currentSetting = Object.assign({}, setting);
    currentSetting = this.formatSetting(currentSetting);

    let settingEntity = currentSetting.settings[entity];
    settingEntity = settingEntity
    .split(SEPARATOR)
    .filter(barcode => barcode !== item.product.barcode)
    .join(SEPARATOR);

    currentSetting.settings[entity] = settingEntity;

    const submitedData = Object.assign(this.state.submitedData, { [`${reportName}`]: currentSetting });

    const { updateUserNotification, getProducts, getUserNotificationSettings } = this.props;
    updateUserNotification(submitedData[reportName], getProducts(currentSetting.settings[entity]));
  }
  
  deleteReport = (id) => {
      const { deleteUserNotification, getUserNotificationSettings } = this.props;
      deleteUserNotification(id, getUserNotificationSettings);
  }

  onChangeEntity = value => {
    this.setState( {
      showDropdown: true,
      inputValue: value
    });
  }

  render(){
    const { reports } = this.props;
    const filteredReports = reports.data.filter( report => !HIDDEN_REPORTS.includes(report.title));
    return(
      <div className="homepage">
        <form onSubmit={this.handleSubmit}>
          <div className="ibox spinner-absolute">
            <div className="NotificationReportHeader">
              <h3> Set up the emails you will receive for different reports </h3>
              <button type="submit" className="chat-submit"><i className="fas fa-save"></i></button>
            </div>
            <Spinner enabled={reports.loading} />
            {!(reports.loading) && filteredReports.map( (report, index) => {
                console.log(report);
                return <NotificationReport
                          inputValue={this.state.inputValue}
                          onChangeSetting={this.handleChangeSetting}
                          onClickItem={this.onClickItem}
                          onChangeEntity={this.onChangeEntity}
                          onRemoveItem={this.removeItem}
                          onDeleteReport={this.deleteReport}
                          key={index}
                          frequencies={reports.frequencies}
                          setting={this.findSetting(report, index)}
                          title={report.title}
                          reportID={report.id}
                          description={report.description}
                          showDropdown={this.state.showDropdown}
                          products={this.props.products}
                          handleCloseSearch={this.handleCloseSearch}
                        />
              })
            }
          </div>
          <Helmet>
              <title>Notifications - User Preferences</title>
          </Helmet>
          
        </form>
      </div>
    )
  }
};

function mapDispatchToProps(dispatch){
  return {
    getNotificationReports: () => dispatch(notificationReportsActions.getNotificationReports()),
    getProducts: barcodes => dispatch(productsActions.getProductsByBarcode(barcodes)),
    getUserNotificationSettings: () => dispatch(notificationReportsActions.getUserNotificationSettings()),
    getFrequencies: () => dispatch(notificationReportsActions.getFrequencies()),
    createUserNotification: (notification, cb) => dispatch(notificationReportsActions.createUserNotification(notification, cb)),
    updateUserNotification: (notification, cb) => dispatch(notificationReportsActions.updateUserNotification(notification, cb)),
    deleteUserNotification: (id, cb) => dispatch(notificationReportsActions.deleteUserNotification(id, cb))
  };
}

function mapStateToProps( state ) {
  return {
    products: state.products.data,
    reports: state.notification_reports
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
