import TagManager from 'react-gtm-module';
import config from '../../config/config';

function setUpGTM() {

    if(!config.gtmId)
        return ;
    console.log(`GTM ID = ${config.gtmId}`);
    const tagManagerArgs = {
        gtmId: config.gtmId
    }
         
    TagManager.initialize(tagManagerArgs);
}


setUpGTM();