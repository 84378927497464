import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pick, isEqual, minBy, sortBy } from 'lodash';
import SelectFieldFilter from '../components/common/SelectFieldFilter';
import SelectHeartBeatsDates from '../components/common/SelectHeartBeatsDates';
import PlaylistAnalysisLink from '../components/common/Datagrid/PlaylistAnalysisLink';
import DetailsLink from "../components/common/Datagrid/DetailsLink";
import AddMilestoneButton from '../components/pages/audience/AddMilestoneButton';
import vendors from '../components/widgets/filter/enums/vendors';
import SharePage from '../components/pages/sharedPages/SharePage';
import CountryFlag from '../components/common/CountryFlag';

import { heartbeatsActions } from '../data/actions/heartbeats';
import { notificationReportsActions } from '../data/actions/notification_reports';
import { getDatesFromFridayToThursday, previousFriday } from '../data/helpers/heartbeats';
import { heartbeatsConstants } from '../data/constants/heartbeats';
import moment from 'moment';
import CSV from '../helpers/CSVExport';
import { pdf as PDFRenderer } from '@react-pdf/renderer';
import PDFDocument from '../components/pages/heartbeats/PDF';
import CopyToClipboard from '../components/common/CopyToClipboard';

import {splitByThousands} from '../helpers/NumberFormatter';
import cellHeaderHOC from '../components/common/Datagrid/CellHeaderHOC';

const CellHeaderTable = cellHeaderHOC(ReactTable);

const downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');

const formDefaults = {
    newRelease: "false",
    minStreams: 1000,
    minTiktokCreates: 0,
    percentage: 50,
    vendor: 0
}

class Heartbeats extends Component {
  constructor(props) {
    super(props);
    this.tableScrollTop = 0;
  }
  
  
  
  getInitialValue = (key) => {
      const { match: { params } } = this.props;
      if(params[key] !== undefined)
          return ['newRelease', 'week'].includes(key)  ? params[key] : Number(params[key]);
      else
          return formDefaults[key];
  } 
  
  state = {

    newRelease: this.getInitialValue('newRelease'),
    minStreams: this.getInitialValue('minStreams'),
    percentage: this.getInitialValue('percentage'),
    minTiktokCreates: this.getInitialValue('minTiktokCreates'),
    vendor: this.getInitialValue('vendor'),
    imprint: '',
    artist: '',
    percentChangeOptions: [10, 20, 40, 60, 80, 100, 200, 300],
    minStreamOptions: [1000, 5000, 10000, 50000, 100000, 500000, 1000000, 5000000, 10000000 ],
    minTiktokCreatesOptions: [0, 500, 1000, 5000, 10000, 50000, 100000, 500000 ],
    week: null,
    data: [],
    pageSize: 20,
    filtered: [
           {id: 'new_release', value: this.getInitialValue('newRelease')}, 
           {id: 'period_2_units', value: this.getInitialValue('minStreams')},
           {id: 'tiktok_creations', value: this.getInitialValue('minTiktokCreates')},
           {id: 'percent_change', value: this.getInitialValue('percentage')},
           {id: 'vend_id', value: this.getInitialValue('vendor')}
    ],
    page: 0,
    sorted: [{id: 'percent_change', desc: true}],
    defaultSorted: [{id: 'percent_change', desc: true }],
  }

  componentDidMount(){
    // this.dispatchLoadData();
    this.props.getUserNotificationSettings();
    this.props.getWeeks().then(weeks=>{
        let week = this.getInitialValue('week');
        if(!week)
            week = weeks[0].startDate; 
        this.setState({
            week
        }, ()=>this.dispatchLoadData());
    })
    //this.findPercentage();
    
  }

  componentDidUpdate(prevProps){
    if(this.props.heartbeats.data !== prevProps.heartbeats.data){
      this.filterByNewRelease();
    }
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;
    if(accessor != 'new_release')
        value = Number(value);

    if (filtered.length > 0) {
      filtered.forEach((filter, i) => {
        if (filter.id === accessor) {
          if (value === "" ) filtered.splice(i, 1);
          else filter.value = value;
          insertNewFilter = 0;
        }
      });
    }
    let nextState = Object.assign({}, this.state); 

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    if(accessor === 'new_release'){
      nextState.newRelease = value;
    }
    if(accessor === 'percent_change'){
      nextState.percentage = value;
    }
    if(accessor === 'period_2_units'){
        nextState.minStreams = value;
    }
    if(accessor === 'tiktok_creations'){
        nextState.minTiktokCreates = value;
    }
    
    if(accessor === 'vend_id'){
        nextState.vendor = value;
    }
    
    if(accessor === 'imprint'){
        nextState.imprint = value;
    }
    if(accessor === 'artist_name'){
        nextState.artist = value;
    }
    nextState.filtered = filtered;
    this.setState(nextState) //, ()=>this.dispatchLoadData());
  };
  
  loadData = (params) => {
      params = pick(params, ['page', 'pageSize', 'filtered', 'sorted']);
      const currentState = pick(this.state, ['page', 'pageSize', 'filtered', 'sorted']);
      if(isEqual(params, currentState))
          return;

      const { page, pageSize, filtered, sorted } = params;
      this.setState({
          page,
          pageSize,
          filtered,
          sorted,
      }, ()=>{
          this.dispatchLoadData()
      });
  }
  
  pushRoute = () => {
      const {
          newRelease,
          minStreams,
          percentage,
          minTiktokCreates,
          vendor,
          week
      } = this.state;
      this.props.history.push(`/reporting/heartbeats/${week}/${minStreams}/${percentage}/${minTiktokCreates}/${newRelease}/${vendor}`);
      this.dispatchLoadData();
  }

  dispatchLoadData = () => {
    const { page, pageSize, filtered, sorted } = this.state;
    let params = {};
    params.page = page;
    params.pageSize = pageSize;
    params.filtered = filtered;
    params.sorted = sorted;
    params.period_date = this.state.week;
    this.props.loadData(params);
  }

  getDataByWeek = (e) => {
    this.setState({ data: [], newRelease: "false", week: e.target.value}) //, ()=>this.dispatchLoadData());
    
  }

  toggleNewRelease = event => {
    this.setState({ newRelease: event.target.checked });
    if(event.target.checked){
      this.sortNewRelease();
    } else {
      this.filterByNewRelease();
    }
  }

  getFileName = () => {
    const { heartbeatsWeeks } = this.props.heartbeats; // getDatesFromFridayToThursday();
    const currentDates = heartbeatsWeeks.filter( date => date.startDate === this.state.week )[0];
    const fromDate = moment(new Date(currentDates.endDate)).format('YYYYMMDD');
    const toDate = moment(new Date(currentDates.startDate)).format('YYYYMMDD');
    return `heartbeats_${fromDate}-${toDate}`;
  }

  exportToCsv = data => {
    const filename = this.getFileName();
    return CSV.CSVExport(data, {filename}, 'heartbeats');
  }

  filterByNewRelease = () => {
      return true;
    const data = this.props.heartbeats.data.filter(currentValue => !currentValue.new_release );
    this.setState({ data });
  }

  sortNewRelease = () => {
    let data = this.props.heartbeats.data.slice();
    data.sort((currentValue, nextValue) => (currentValue.new_release === nextValue.new_release) ? 0 : currentValue.new_release ? -1 : 1);
    this.setState({ data });
  }

  findPercentage = () => {
    if(this.props.settings.length !== 0){
      const percentage = this.props.settings
            .find( setting => setting.settings.threshold !== undefined)
            .settings
            .threshold;
      this.setState({
        percentage
      })
    }
  }

  getUnitsFromAccesor = unitsAccessor => {
    const separator = '|';
    const units = unitsAccessor.split(separator)[1];
    return Number(units);
  }
  
  getPdfData = () => {
      const reportTitle = 'Heartbeats';
      const data = this.state.data.length !== 0 ? this.state.data : this.props.heartbeats.data;
      const tables = {
          state: this.state,
          data
      };
      return { reportTitle, tables };
  }
  
  exportToPdf = () => {
      const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
      data.toBlob()
      .then(blob=>{
          const fileURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = "heartbeats.pdf";
          link.click();
      });
  }
  

  render() {
    const { sharedMode } = this.props;      
    const { heartbeatsWeeks: dates, heartbeatsWeeksLoading } = this.props.heartbeats; // getDatesFromFridayToThursday();
    const data = this.state.data.length !== 0 ? this.state.data : this.props.heartbeats.data;
    const pages = this.props.heartbeats.pagination ? this.props.heartbeats.pagination.total_page : -1;
    const tiktokMetricsDisabled = this.state.vendor && this.state.vendor != 17;
    
    const {
        newRelease,
        minStreams,
        percentage,
        minTiktokCreates,
        vendor,
        week
    } = this.state;
    
    return (
      <div className="homepage">
        <div className="column ibox-content section-header">
          <h2 className="ibox-title__title title-with-padding">
            <span className="title-text">Heartbeats</span>
            <div>
              {!sharedMode && <SharePage module="reporting" entity="heartbeats" id={week} params={`${minStreams}/${percentage}/${minTiktokCreates}/${newRelease}/${vendor}`} exportToCsv={() => this.exportToCsv(data)} exportToPdf={() => this.exportToPdf(data)} />}              
              {sharedMode && <a key="download" onClick={() => this.exportToCsv(data)} title="Export CSV" className="download-link download-link-shared-heartbeats" dangerouslySetInnerHTML={{__html: downloadSvg}} />}
            </div>
          </h2>
            <div className="heartbeats-holder">
                <div className="heartbeats-item heartbeats-item--big">
                    <div className="inline-filter-title"><i className="fa fa-calendar-week calendar-button"></i>Week</div>
                    <SelectHeartBeatsDates
                      dates={dates}
                      week={this.state.week}
                      getDataByWeek={this.getDataByWeek}
                      loading={heartbeatsWeeksLoading}
                      disabled={sharedMode}
                    />
                </div>
                <div className="heartbeats-item heartbeats-item--small">
                    <div className="inline-filter-title">Week Units</div>
                    <SelectFieldFilter
                      onChange={this.onFilteredChangeCustom}
                      value={this.state.minStreams}
                      options={this.state.minStreamOptions}
                      symbol={heartbeatsConstants.PERCENT_SYMBOL}
                      fieldName="min_streams"
                      accessor="period_2_units"
                      disabled={sharedMode}
                    />
                </div>
                
                <div className="heartbeats-item heartbeats-item--small">
                    <div className="inline-filter-title">TikTok Creates</div>
                    {/*
                    <SelectFieldFilter
                        onChange={this.onFilteredChangeCustom}
                        value={this.state.minTiktokCreates}
                        options={this.state.minTiktokCreatesOptions}
                        symbol={heartbeatsConstants.PERCENT_SYMBOL}
                        fieldName="min_tiktok_creations"
                        accessor="tiktok_creations" 
                    />
                    */}
                    <div className="heartbeats-item-inner--with-symbol">
                      <input type="number" disabled={(sharedMode || tiktokMetricsDisabled)} className="form-control percent-change-input" value={this.state.minTiktokCreates} min={0} step={10}
                      onChange={(e)=>{this.onFilteredChangeCustom(e.target.value, 'tiktok_creations')}} /> 
                      <span className="symbol-sign">#</span>                     
                    </div>
                </div>
                                
                <div className="heartbeats-item heartbeats-item--small">
                    <div className="inline-filter-title">Percent Change</div>
                    {/*
                    <SelectFieldFilter
                      onChange={this.onFilteredChangeCustom}
                      value={this.state.percentage}
                      options={this.state.percentChangeOptions}
                      symbol={heartbeatsConstants.PERCENT_SYMBOL}
                      fieldName="percent_change"
                      accessor="percent_change" 
                    />
                    */}
                    <div className="heartbeats-item-inner--with-symbol">
                      <input type="number" className="form-control percent-change-input" value={this.state.percentage} min={0} step={10}
                      onChange={(e)=>{this.onFilteredChangeCustom(e.target.value, 'percent_change')}} disabled={sharedMode} /> 
                      <span className="symbol-sign">%</span>
                    </div>
                </div>
                <div className="heartbeats-item heartbeats-item--small">
                    <div className="inline-filter-title">Release Type</div>
                    <select
                        value={this.state.newRelease}
                        disabled={sharedMode}
                        onChange={event => {
                            this.onFilteredChangeCustom(event.target.value, 'new_release');
                        }}
                        >
                        <option value="">All</option>
                        <option value="true">New Release</option>
                        <option value="false">Catalog</option>
                    </select>
                </div>
                <div className="heartbeats-item heartbeats-item--small">
                    <div className="inline-filter-title">Platform</div>
                    <select
                        value={this.state.vendor}
                        onChange={event => {
                            this.onFilteredChangeCustom(event.target.value, 'vend_id');
                        }}
                    disabled={sharedMode}
                        >
                        <option value={0}>All</option>
                        {sortBy(vendors, 'label').map(vendor=><option value={vendor.vend_id}>{vendor.label}</option>)}
                    </select>
                </div>
                {!sharedMode && <button className="default-btn default-btn--small heartbeats-apply-button" onClick={this.pushRoute}>Apply</button>}
            </div>
        </div>
        <div>
            <CellHeaderTable
            filtered={this.state.filtered}
            resizable={false}
            loading={(this.props.heartbeats.loading || heartbeatsWeeksLoading)}
            className="heartbeat-table sticky-table"
            columns={[
              {
                  id: 'actions',
                  className: 'imprint-name logo frozen',
                  headerClassName: 'imprint-name logo frozen',
                  width: 80,
                  Cell: props => {
                    return (
                      sharedMode ? null : <div className="heartbeat-icons-holder">
                        <PlaylistAnalysisLink entity="tracks" id={props.original.track_id} />
                        <DetailsLink
                          title="Track Details"
                          entity="tracks"
                          id={props.original.track_id}
                        ></DetailsLink>
                        <AddMilestoneButton entity="tracks" id={props.original.track_id} buttonTitle="+" />
                      </div>
                    );
                  }
              },
              {
                  id: 'artist_name',
                  className: 'imprint-name artist frozen',
                  headerClassName: 'imprint-name artist frozen no-sort',
                  Header: 'Track',
                  accessor: 'artist_name',
                  //filterable: true,
                  // width: 220,
                  sortable: false,
                  Cell: ({original: props}) => {
                        return <div className="logo-container">
                            <Link to={`/artists/${props.artist_id}`} className="logo-link">
                                <div className="logo" style={{backgroundImage: `url(${props.artist_image})`}} />
                            </Link>
                            <div>    
                                <span><Link to={`/tracks/${props.track_id}`}>{props.title_and_version}</Link></span>                
                                <span>By: {props.artist_name}</span>
                                <span className="isrc-holder with-copy"><CopyToClipboard value={props.isrc}>{props.isrc}</CopyToClipboard></span>
                            </div>
                        </div>
                  },
              },
              /*
              {
                id: 'title_and_version',
                className: 'imprint-name song frozen',
                headerClassName: 'imprint-name song frozen',
                Header: 'Track',
                accessor: 'title_and_version',
                // width: 355,
                Cell: props => {
                  return (
                    <span>
                      {props.value}
                    </span>
                  );
                }
              },
              {
                id: 'product_title',
                className: 'imprint-name',
                headerClassName: 'imprint-name',
                Header: 'Product',
                //accessor: heartbeat => { return JSON.parse(heartbeat.associated_releases)[0].product_title },
                accessor: 'product_title',
                sortable: true,
                // width: 350,
                Cell: props => {
                  return (
                    <span>
                      {props.value}
                    </span>
                  );
                }
              },
              {
                  id: 'imprint',
                  className: 'imprint-name',
                  headerClassName: 'imprint-name',
                  Header: 'Imprint',
                  accessor: 'imprint',
                  //filterable: true,
//                  filterMethod: (filter, row) => {
//                      return String(row['imprint']).match(new RegExp(filter.value, 'i'))},
//                  Filter: ({ filter, onChange }) => {
//                      return (
//                        <div className="inline-filter-holder">
//                          <input
//                            onChange={(e)=>this.onFilteredChangeCustom(e.target.value, 'imprint')}
//                            value={this.state.imprint}
//                          />
//                        </div>
//                      )
//                    }
                  
                  
                  // width: 220,
              },              
              */
              {
                id: 'period_1_units',
                className: 'imprint-name',
                headerClassName: 'imprint-name rt-resizable-header',
                Header: 'Previous Week Units',
                sortMethod: (a, b) => {
                  const unitsCurrent = this.getUnitsFromAccesor(a);
                  const unitsNext = this.getUnitsFromAccesor(b);
                  return unitsCurrent - unitsNext;
                },
                accessor: 'period_1_units',
                Cell: props => { return `${splitByThousands(props.original.period_1_units)}` }, //Wk${props.original.period_1_week} | 
                // width: 160
              },
              {
                id: 'period_2_units',
                className: 'imprint-name',
                Header: 'Current Week Units',
                headerClassName: 'imprint-name inline-filter rt-resizable-header',
                sortMethod: (a, b) => {
                  const unitsCurrent = this.getUnitsFromAccesor(a);
                  const unitsNext = this.getUnitsFromAccesor(b);
                  return unitsCurrent - unitsNext;
                },
//                filterable: true,
                accessor: heartbeat => { return `${splitByThousands(heartbeat.period_2_units)}` }, // `Wk${heartbeat.period_2_week} | 
//                filterMethod: (filter, row) => {
//                    return row._original.period_2_units >= parseInt(filter.value);
//                },
                
                // width: 160,
//                Filter: ({ filter, onChange }) => {
//                    return (
//                      <div className="inline-filter-holder">
//                        <div className="inline-filter-title">Current Week | Units</div>
//                        <SelectFieldFilter
//                          onChange={this.onFilteredChangeCustom}
//                          value={this.state.minStreams}
//                          options={this.state.minStreamOptions}
//                          symbol={heartbeatsConstants.PERCENT_SYMBOL}
//                          fieldName="min_streams"
//                          accessor="previousWeek_units_select" 
//                        />
//                      </div>
//                    )
//                  }
                
              },
              {
                  id: 'percent_change',
                  className: 'imprint-name',
                  headerClassName: 'filter-cell inline-filter rt-resizable-header',
                  Header: 'Change (%)',
                  accessor: 'percent_change',
                  //filterable: true,
                  // width: 125,
                  //filterMethod: (filter, row) => {
                    //return row[filter.id] >= parseInt(filter.value);
                  //},
                  Cell: ({value}) => {
                    return splitByThousands(value) + heartbeatsConstants.PERCENT_SYMBOL;
                  },
//                  Filter: ({ filter, onChange }) => {
//                    return (
//                      <div className="inline-filter-holder">
//                        <div className="inline-filter-title">Percent change</div>
//                        <SelectFieldFilter
//                          onChange={this.onFilteredChangeCustom}
//                          value={this.state.percentage}
//                          options={this.state.percentChangeOptions}
//                          symbol={heartbeatsConstants.PERCENT_SYMBOL}
//                          fieldName="percent_change"
//                          accessor="percent_change" 
//                        />
//                      </div>
//                    )
//                  }
                },
              
              {
                id: 'first_release_date',
                className: 'imprint-name',
                headerClassName: 'imprint-name rt-resizable-header',
                Header: 'Release date',
                accessor: heartbeat => {
                  let releaseDate = heartbeat.first_release_date; //JSON.parse(heartbeat.associated_releases)[0].release_date;
                  if(!releaseDate)
                      releaseDate = minBy(heartbeat.associated_releases, 'release_date'); 
                  
                  return moment(releaseDate).format('YYYY-MM-DD')
                //const date = new Date(releaseDate);
                  //return date.toLocaleString('default', { day: '2-digit', month: 'short', year: 'numeric' });
                },
                filterable: false,
                // width: 120,
              },
              {
                  id: 'biggest_vendor',
                  className: 'imprint-name',
                  headerClassName: 'imprint-name rt-resizable-header',
                  Header: props => <div className="top-table-header-inner grid-header-cell">
                      Biggest Mover 
                      <div className="table-header-tooltip">
                        <span data-tip data-for="biggest-mover-tip" className="notificationReportInfoIcon toolbar-title-tooltip-icon"> 
                            <i className="fas fa-question-circle" /> 
                        </span>
                        <ReactTooltip effect="solid" multiline={true} place="bottom" className="toolbar-title-tooltip" id="biggest-mover-tip">
                            % of total consumption
                        </ReactTooltip>
                      </div>
                  </div>,

                  accessor: 'biggest_mover',
                  Cell: ({original}) => {
                    return `${original.biggest_mover} (${original.biggest_mover_share}${heartbeatsConstants.PERCENT_SYMBOL})`;
                  },
                },  
              {
                  id: 'biggest_territory',
                  className: 'imprint-name',
                  headerClassName: 'imprint-name rt-resizable-header',
                  Header: props => <div className="top-table-header-inner grid-header-cell">
                      Biggest Territory 
                      <div className="table-header-tooltip">
                        <span data-tip data-for="biggest-mover-territory-tip" className="notificationReportInfoIcon toolbar-title-tooltip-icon"> 
                            <i className="fas fa-question-circle" /> 
                        </span>
                        <ReactTooltip effect="solid" multiline={true} place="bottom" className="toolbar-title-tooltip" id="biggest-mover-territory-tip">
                            Top territory for the biggest mover
                        </ReactTooltip>
                      </div>
                  </div>,
                  
                  accessor: 'biggest_mover_territory',
                  Cell: ({original}) => {
                    const value = original.biggest_mover_territory;
                    return value != 'N/A' ? <span className="biggest-mover-territory"> 
                      <CountryFlag country={value} title={original.biggest_mover_territory_name} /> <strong>{original.biggest_mover_territory_name}</strong>
                    </span> : 'N/A';
                  },
                  
//                  Filter: ({ filter, onChange }) => {
//                    return (
//                      <div className="inline-filter-holder">
//                        <div className="inline-filter-title">Percent change</div>
//                        <SelectFieldFilter
//                          onChange={this.onFilteredChangeCustom}
//                          value={this.state.percentage}
//                          options={this.state.percentChangeOptions}
//                          symbol={heartbeatsConstants.PERCENT_SYMBOL}
//                          fieldName="percent_change"
//                          accessor="percent_change" 
//                        />
//                      </div>
//                    )
//                  }
                },
              
              /*
              {
                id: 'isrc',
                className: 'imprint-name',
                headerClassName: 'imprint-name',
                Header: 'ISRC',
                accessor: 'isrc',
                // width: 115,
              },
              */
              
              {
                id: 'new_release',
                className: 'with-tooltip',
                headerClassName: 'inline-filter rt-resizable-header',
                Header: props => <div className="top-table-header-inner grid-header-cell">
                  New Release 
                  <div className="table-header-tooltip">
                    <span data-tip data-for="new-release-tip" className="notificationReportInfoIcon toolbar-title-tooltip-icon"> 
                        <i className="fas fa-question-circle" /> 
                    </span>
                    <ReactTooltip effect="solid" multiline={true} place="bottom" className="toolbar-title-tooltip" id="new-release-tip">
                      New Releases include products released in the past 6 weeks
                    </ReactTooltip>
                  </div>
                </div>,
                accessor: 'new_release',
                Cell: ({value}) => {
                  return (
                    value ? heartbeatsConstants.CHECK_MARK_SYMBOL : heartbeatsConstants.CROSS_SYMBOL
                  )
                },
//                filterable: true,
//                filterMethod: (filter, row) => {
//                    const value = Number(filter.value);
//                    if(value === 0)
//                        return true;
//                    
//                    return (value === 1 ? true : false ) === row._original.new_release;
//                },                
//                Filter: ({ filter, onChange }) => {                
//                    return <div className="inline-filter-holder filter-select">
//                    <div className="inline-filter-title">New Release</div>
//                        <select
//                        value={this.state.newRelease}
//                        onChange={event => {
//                            this.onFilteredChangeCustom(event.target.value, 'new_release');
//                        }}
//                        >
//                        <option value={0}>All</option>
//                        <option value={1}>New Release</option>
//                        <option value={2}>Catalog</option>
//                        })}
//                        </select>
//                    </div>
//                }
              },
              {
                  id: 'tiktok_2_views',
                  className: 'imprint-name',
                  headerClassName: 'imprint-name rt-resizable-header',
                  Header: 'TikTok Views',
                  accessor: 'tiktok_2_views',
                  Cell: ({value}) => {
                      return splitByThousands(value ? Number(value) : 0) // ? value + heartbeatsConstants.PERCENT_SYMBOL : 'N/A';
                  },                  
                  /*
                  Cell: ({original}) => {
                      const { tiktok_2_views, period_2_units } = original;
                      return (tiktok_2_views && period_2_units) ? Math.round(tiktok_2_views / period_2_units * 100) + heartbeatsConstants.PERCENT_SYMBOL : 0
                  },
                  */
               },
               {
                   id: 'tiktok_2_creates',
                   className: 'imprint-name',
                   headerClassName: 'imprint-name rt-resizable-header',
                   Header: 'TikTok Creates',
                   accessor: 'tiktok_2_creates',
                   Cell: ({value}) => {
                     return splitByThousands(value ? Number(value) : 0) // ? value + heartbeatsConstants.PERCENT_SYMBOL : 'N/A';
                   },
               },              
              
              
            ]}
            manual
            pages={pages}
            page={this.state.page}
            data={data}
            defaultPageSize={this.state.pageSize}
            defaultSorted={this.state.defaultSorted}
            onFetchData={this.loadData}
            defaultSortDesc={true}
            noDataText='No results found. Please check your filters.'
          />
        </div>
      <Helmet>
          <title>Heartbeats - Reports</title>
      </Helmet>

      </div>
    );
  }
}

function mapDispatchToProps(dispatch){
  return {
    getUserNotificationSettings: () => dispatch(notificationReportsActions.getUserNotificationSettings()),
    getWeeks: () => dispatch(heartbeatsActions.getHeartbeatsWeeks()),
    loadData: params => dispatch(heartbeatsActions.getPagedHeartbeats(params))
  };
}

function mapStateToProps(state) {
  return {
    playlists: state.playlists,
    filter: state.filter,
    heartbeats: state.heartbeats,
    settings: state.notification_reports.settings
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Heartbeats))