import React from 'react';

class NewReleasesReport extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
        }
        this.setValue = this.setValue.bind(this);
    }
    
    componentDidMount() {
        const { settings } = this.props;
        this.setState({
            ...this.state,
            ...settings
        })
    }
    
    setValue(e, key) {
        const { value } = e.target;
        const { onValueChanged } = this.props;
        this.setState({
            ...this.state,
            [key]: value
        }, ()=>{
            onValueChanged(this.state)
        })
    }
    
    render (){
        const { release_weeks } = this.state;
        return <div>
		<label for="">
			Weeks
            <input type="number" className="form-control" value={release_weeks} onChange={(e)=>this.setValue(e, 'release_weeks')} /> 
		</label>
        </div>
    }
}
export default NewReleasesReport;