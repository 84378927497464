import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux';
import ReactTable from "react-table";
import store from '../../../helpers/store'

import ReactSelect from '../../common/ReactSelect';
import ModeSelect from '../../common/ModeSelect';
import { isEqual, map, find, get } from 'lodash';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import Tooltip from '../../common/Tooltip';

import { territorySettings } from './Territory/Settings';
import { statsActions } from '../../../data/actions/stats';
import { convertToThousands } from '../../../helpers/NumberFormatter';

import CSV from '../../../helpers/CSVExport';
import WorldMap from '../../common/WorldMap';
import PieChart from '../../widgets/charts/types/PieChart';
import LineChart from '../../widgets/charts/types/LineChart';
import Box from '../../common/Box';
import ExportModal from '../../common/ExportModal';
import LoadingMessage from '../../../helpers/LoadingMessage';
import DateGroupSelector from '../../pages/result/DateGroupSelector';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';

var worldSvg = require('!svg-inline-loader!../../../../public/img/world.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var mapSvg = require('!svg-inline-loader!../../../../public/img/map.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var xlsxSvg = require('!svg-inline-loader!../../../../public/img/xlsx.svg');

const CellHeaderTable = cellHeaderHOC(ReactTable);

class TerritoriesWidget extends Component {
    topQuantity = 10;
    options = [
        {label: 'Most Per Capita', field: 'per_cap_units', dir: 'DESC'},
        {label: 'Most Units', field: 'curr_units', dir: 'DESC'},
        {label: 'Least Units', field: 'curr_units', dir: 'ASC'},
        {label: 'Units Spike', field: 'units_diff', dir: 'DESC'},
        /*
        {label: 'Most Listeners', field: 'curr_listeners', dir: 'DESC'},
        {label: 'Least Listeners', field: 'curr_listeners', dir: 'ASC'},
        {label: 'Listeners Spike', field: 'listeners_diff', dir: 'DESC'}
        */
    ];
    
    limits = [10, 25, 50, 100, 500];
    
    constructor(props) {
        super(props);
        
        const territoryLimit = store.get('territoryLimit') || 10;
        
        this.state = {
            mode: 'map', 
            sort: this.options[1],
            dateGroup: null,
            limit: territoryLimit
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setDateGroup = this.setDateGroup.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToXls = this.exportToXls.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setLimit = this.setLimit.bind(this);        
    }
    
    setChartMode(mode){
        this.setState({mode}, ()=>{
            this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit});
            if(mode == 'line')
                this.getTimeseries();

            const { modeChange } = this.props;
            if(typeof modeChange == 'function')
                modeChange(mode);
            
        });
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }
    
    setSort(sort){
        this.setState({sort}, ()=>{
            this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit}, false);
        })
    }
    
    setLimit(limit){
        this.setState({limit: limit.value}, ()=>{
            this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit}, false);
            store.set('territoryLimit', limit.value);
        })
    }
    
    setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>this.getTimeseries());
    }        
    
    exportToCsv(){
        this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: 100000}, false).then(data=>{
            const filename = CSV.CSVHeader('top_territories', this.state.sort.field, this.props.filter.global); 
            return CSV.CSVExport(data.table, {filename}, 'territories');
            
        })
    }
    
    exportToXls(){
        this.getTimeseries().then(timeseries => {
            const filename = CSV.CSVHeader('top_territories', this.state.sort.field, this.props.filter.global);
            let data = this.props.stats.topTerritoryCache;
            data = CSV.expandTimelineFlat(data, timeseries, 'territory');
            return CSV.XLSExport(data, {filename}, 'territories');
            
        });
    }

        
    renderToolbar(modes){
        let toolbar = [];
        
        const limitOptions = this.limits.map(limit => ({
            label: limit == 500 ? 'All' : limit,
            value: limit        
        })),
        limitValue = find(limitOptions, {value: this.state.limit});
        
        // for (let mode in modes) {
        //     toolbar.push(<a key={mode}><i alt={mode} className={`fa fa-${modes[mode]} ${this.state.mode == mode ? 'active' : ''}`} onClick={()=>this.setChartMode({mode})}></i></a>);                
        // }

        /*
            <a key='pie' title="Pie Chart" className={`chart-icon ${this.state.mode == 'pie' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('pie')}
                dangerouslySetInnerHTML={{__html: pieSvg}}>
            </a>

         */
        const options = [
            // {icon: cardSvg, value: 'cards', label: "Overview"},
            {icon: mapSvg, value: 'map', label: "Map"},
            {icon: lineSvg, value: 'line', label: "Line Chart"},
            {icon: tableSvg, value: 'table', label: "Table"},
        ];
        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
            <div className="ibox-icon-holder mode-select">
                <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
                
            </div>
            <div className="limit-holder">
                <span className="select-label">Limit</span>
                <ReactSelect value={limitValue} options={limitOptions} onChange={this.setLimit} className="single-select select-limit" isSearchable={ false }/>
            </div>
            <div className="select-holder">
                <span className="select-label">Sort by</span>
                <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select sort-select" isSearchable={ false }/>
            </div>
        </div>)

        return toolbar;
    }
    
    componentDidMount() {
        this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit}, false);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getTerritories({mode: this.state.mode, sort: this.state.sort, limit: this.state.limit}, false);
            }                
        }
    }
    
    getTimeseries(){
        const { dateGroup } = this.state;
        const ids = this.props.stats.topTerritory ? map(this.props.stats.topTerritory.table, 'code') : [];
        return this.props.getTimeseries(ids, this.state.limit, false, dateGroup);
    }

    renderCountry(country) {
        if(this.state.sort.field == 'per_cap_units')
            return `${country.name} (${Math.round(country.perCapita*100)/100})`;
        else
            return country.name;
    }

    renderTooltip() {
        let tooltip = [];
        tooltip.push(
            <div className="table-header-tooltip">
                <Tooltip position="bottom" message={`This section is independent from the Main Filter Limit in order to give you an accurate territory market share no matter how many results you’re looking at. By default you can see the top 10 but you can toggle this in the Limit drop down menu on the right. You can still select a specific Vendor in the Main Filter.`} tooltipClass="toolbar-title-tooltip"/>
            </div>
        )

        return tooltip;
    }

    renderExportToCsv() {
        return <ExportModal exportTotals={this.exportToCsv} exportBreakdown={this.exportToXls} loading={this.props.stats.topTimeseriesLoading} />
    }
    
    
    renderChartActions(){
        const { mode } = this.state;
        return <React.Fragment>      
            {mode == 'line' && <DateGroupSelector dateGroupChange={this.setDateGroup}/>}
        </React.Fragment>
    }


    
    
    render() {
        const { widgetMode, milestones } = this.props;
        
        let modes = {
            'map': 'globe',
            'pie': 'chart-pie',
            'line': 'chart-line',
            },
            threshold = 1000000,
            topCount = 0,
            topSum = 0,
            countriesOverThreshold = 0,
            countries;        
        if(this.props.stats.topTerritory){
            countries = this.props.stats.topTerritory.table;
            for(let country of countries){
                let streams = Number(country.value);
                if(topCount<this.topQuantity) {
                    topCount++;
                    topSum += streams;
                } 
                if(streams<threshold)
                    continue;
                countriesOverThreshold++;
            }
        }

        const { mode } = this.state;
        const mapHolderClass = mode === 'map' ? 'world-map-container' : 'map-table-container'
        let milestonesItems = get(milestones, 'entities.mixed.tableItems', [])

        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }  

        return (
            <Box title="Territories" hasTooltip loadingTooltip={this.renderTooltip()} chartActions={this.renderChartActions()}  toolbar={this.renderToolbar(modes)} loadingMessage={LoadingMessage('overview')} spinnerEnabled={this.props.stats.topTerritoryLoading || this.props.stats.topTimeseriesLoading} exportToCsv={this.renderExportToCsv()} className="territories-widget">
                {this.props.stats.topTerritory && <div className="territory-map-row">
                    <div className={`territory-details-map`}>
                        <div className={`chart-block chart-block--full custom-scroll ${mapHolderClass}`}>
                            {mode == 'map' && <WorldMap data={this.props.stats.topTerritory.world} label="streams" /> }
                            {/*mode == 'pie' && <PieChart data={this.props.stats.topTerritory.chart} /> */}
                            {mode == 'line' && <React.Fragment>
                                {/* <LineChart data={this.props.stats.topTerritoryTimeseries} />  */}
                                <LineChartWithAnnotations milestones={milestonesItems} data={this.props.stats.topTerritoryTimeseries} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} period={this.state.period} shadowChartProps={shadowChartProps} />
                            </React.Fragment>}
                            {mode=='table' && <div className="territory-table territory-table--single custom-scroll"><CellHeaderTable
                                className="territory-table-responsive top-territory-table simple-table"
                                data={this.props.stats.topTerritory.table}
                                columns={territorySettings.settingsForTable()}
                                defaultPageSize={10}
                                showPagination={this.props.stats.topTerritory.table.length > 10}
                                noDataText='No results found. Please check your filters.'
                            /></div>}
                        
                        </div>
                    </div>
                    
                    {widgetMode=='expanded' && mode == 'map' && <div className="territory-details-column">
                        <div className="tooltip-holder">
                          <h3 className="content-title lowercase">{countriesOverThreshold} countries</h3>
                        </div>
                        <h4 className="content-subtitle lowercase">With over {convertToThousands(threshold)} streams</h4>
                        {(widgetMode == 'normal' || widgetMode == 'expanded') && <div>
                            <div className="data-title">
                                <div>
                                    Streams by Top {this.topQuantity} Countries
                                </div>
                                <div className="lowercase">
                                    {/*actual count*/}
                                    {convertToThousands(topSum)}                                
                                </div>                                
                            </div>
                            <div className="data-block"> 
                                {countries.slice(0, this.topQuantity).map(country=>{return <div key={country.code} className="data-block__item">
                                    <div className="name">
                                        {this.renderCountry(country)}
                                    </div>
                                    <div className="value">
                                        {convertToThousands(country.value)}                                
                                    </div>                                
                                </div>})}
                            </div>
                        </div>}
                    </div>}
                </div> }
            </Box>
        )
    }

}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter,
        milestones: state.milestones
    } 
}

function mapDispatchToProps(dispatch){
    return {
        getTerritories: (params, cache=true)=>dispatch(statsActions.getTopTerritoryStats(params, cache)),
        getTimeseries: (params, limit, cache=true, dateGroup)=>dispatch(statsActions.getTopTerritoryTimeseries(params, limit, cache, dateGroup)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TerritoriesWidget)